import { useEffect, useRef } from "react";

import { TreeData } from "@atlaskit/tree";
import { useSelector, useDispatch } from "react-redux";
import { StateType } from "../reducer";
import { updateTreeData, } from "../actions";

import { debounce } from "throttle-debounce";

import { mutateTree } from "../sideMenu/treeUtil";
import { useSlateState } from "../SlateProvider";

/**
 * Allow to change the title on the tree menu
 */
export const usePageTitle = () => {
  const dispatch = useDispatch();
  const treeData = useSelector((state: StateType) => state.noteTaking.treeData);
  const pageId = useSelector((state: StateType) => state.noteTaking.pageId);

  const { editor } = useSlateState();

  const pageIdRef = useRef("");

  useEffect(() => {
    pageIdRef.current = pageId;
  }, [pageId]);

  const refThrottleFn = useRef<any>(null);

  // To debounce, debounce you need to pass all the values or will use a store value (closure) 
  const throttleHighlightAid = debounce(
    30,
    false,
    (titleWhenCall: string, pageIdWhenCall: string, treeData:TreeData) => {
      if (treeData && editor.newTitle !== null) {
        let newTreeData = mutateTree(treeData, pageIdWhenCall, {
          data: {
            title: titleWhenCall  ? titleWhenCall : "Untitled Page:",
          },
        });

        dispatch(updateTreeData(newTreeData));
        editor.newTitleChange = false;
      }
    }
  );

  useEffect(() => {
    if (treeData && editor.newTitleChange !== undefined) {
      let titleWhenCall = editor.newTitle;
      let pageIdWhenCall = pageIdRef.current;

      if (!refThrottleFn.current) {
        refThrottleFn.current = throttleHighlightAid;
      }
      refThrottleFn.current(titleWhenCall, pageIdWhenCall, treeData);
    }

    return () => {};
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editor.newTitle]);

  return null;
};
