import { combineReducers } from "redux";

import { noteTaking, NoteTakingStateType } from "@ebbin/note-taking-app";
import { app, AppStateType } from "@ebbin/common";
import {
  authentication,
  AuthenticationStateType,
} from "@ebbin/components";

const rootReducer = combineReducers({
  app,
  authentication,
  noteTaking,
});

export default rootReducer;

export type RootState = typeof rootReducer;

export type StateType = {
  app: AppStateType;
  authentication: AuthenticationStateType;
  noteTaking: NoteTakingStateType;
};
