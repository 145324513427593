import { Editor, Transforms, Range, Point } from "slate";
import { ReactEditor } from "slate-react";

import { LINK } from "./contants";

import isUrl from "is-url";

export const withLinks = <T extends ReactEditor>(editor: T) => {
  const { insertData, isInline } = editor;

  editor.isInline = (element) => {
    return element.type === LINK ? true : isInline(element);
  };

  editor.insertData = (data) => {
    const text = data.getData("text/plain");

    if (text && isUrl(text)) {
      
      const link = {
        type: LINK,
        url: text,
        children: [{ text: text }],
      };
      Transforms.insertNodes(editor, link);
    } else {
      insertData(data);
    }
  };

  return editor;
};

export const onKeyDownLink = (
  e: React.KeyboardEvent<HTMLDivElement>,
  editor: Editor
) => {
  if (
    e.key === "Enter" &&
    editor.selection &&
    Range.isCollapsed(editor.selection as Range)
  ) {
    let above = Editor.above(editor)!;
    if (above) {
      let [node, path] = above;
      
      if (node.type === LINK) {
        e.preventDefault();

        let end = Editor.end(editor, path);
        let start = Editor.start(editor, path);
        if (
          !Point.equals(end, editor.selection.anchor) &&
          !Point.equals(start, editor.selection.anchor)
        ) {
          window.open(node.url as string, "_blank");
          return;
        }
      }
    }
  }
};
