import { LocalLastOpenNotebookid } from "@ebbin/common";

import { AppThunk } from "../../../index";

import { SET_INITIAL_STATE, getNotebooks, openNotebook } from "..";

export let localDb: PouchDB.Database<{}>;

/** Initialize the Notetaking app state after the state is set by the reducer. */
export const initializeNoteTakingState = (
  _localDb: PouchDB.Database<{}>
): AppThunk => async (dispatch) => {
  // initiateNoteTakingState

  // Here we introduce a side effect we receive the localDb by props, and then we export the variable to be used in other actions
  localDb = _localDb;
  // Get all Notebooks
  let notebooks = await getNotebooks();

  if (notebooks?.length === 0) {
    return;
  }
  if (notebooks) {
    dispatch({
      type: SET_INITIAL_STATE,
      data: { notebooks: notebooks },
    });
  }

  /** if _local/lastOpenNotebookid do not exist we save "", and  call openNotebook with Id = "" */
  let notebookId = "";
  try {
    let doc = await localDb.get<LocalLastOpenNotebookid>(
      "_local/lastOpenNotebookid"
    );
    notebookId = doc.notebookId;
  } catch (error) {
    if (error.name === "not_found") {
      await localDb.put({
        _id: "_local/lastOpenNotebookid",
        notebookId: "",
      });
    } else {
      console.log("error:", error);
    }
  }
  dispatch(openNotebook(notebookId));
  return;
};
