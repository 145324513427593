import { TreeSnapshot, TreeActions, Page, PageRow, getId } from "@ebbin/common";

import { moveItemOnTree, TreeData, mutateTree } from "@atlaskit/tree";

import { addItem, removeItem } from "../../sideMenu/treeUtil";

import { localDb } from "..";

/** Create TreeData from tree actions store on the Database
 * - After every 50 actions, creates a tree snapshot with the first 25 actions, after creating the snapshots these 25 actions are removed.
 */
export const createTreeDatafromDB = async (notebookId: string) => {
  let treeData: TreeData | undefined;
  let treeCd: string;
  treeCd = "";
  try {
    let treeSnapshot = await localDb.get<TreeSnapshot>(`ts_${notebookId}`);
    treeData = treeSnapshot.treeData;
    treeCd = treeSnapshot.cd;
  } catch (error) {
    console.log("Get treeSnapshot - error :>> ", error);
  }

  let actions: PouchDB.Core.AllDocsResponse<TreeActions> | undefined;
  try {
    actions = await localDb.allDocs<TreeActions>({
      startkey: `ta_${notebookId}`,
      endkey: `ta_${notebookId}\uffff`,
      include_docs: true,
      conflicts: true,
    });
  } catch (error) {
    console.log("allDocs actions - error :>> ", error);
  }
  if (actions && treeData) {
    let actionRows = actions.rows.map((row) => row.doc);

    const compare = (
      a:
        | PouchDB.Core.ExistingDocument<TreeActions & PouchDB.Core.AllDocsMeta>
        | undefined,
      b:
        | PouchDB.Core.ExistingDocument<TreeActions & PouchDB.Core.AllDocsMeta>
        | undefined
    ) => {
      if (a!.cd.toLowerCase() < b!.cd.toLowerCase()) {
        return -1;
      }
      if (a!.cd.toLowerCase() > b!.cd.toLowerCase()) {
        return 1;
      }
      return 0;
    };
    actionRows.sort(compare);

    // Create snapshot of the first 25 actions, when there are 50 or more actions
    if (actionRows.length >= 50) {
      let snapshotActions = actionRows.slice(0, 25);

      let treeDataSnapshot = await applyTreeActions(
        treeData,
        treeCd,
        snapshotActions
      );

      let treeSnapshot = await localDb.get<TreeSnapshot>(`ts_${notebookId}`);
      try {
        //Note: the date of the snapshot must be the data on the last action
        await localDb.put({
          ...treeSnapshot,
          treeData: treeDataSnapshot,
          cd: snapshotActions[snapshotActions.length - 1]?.cd,
        });
      } catch (error) {
        console.log("error saving snapshot", error);
      }
      try {
        for (const snapshotAction of snapshotActions) {
          if (snapshotAction) {
            await localDb.remove(snapshotAction);
          }
        }
      } catch (error) {
        console.log("error snapshot - deleting actions", error);
      }
      actionRows = actionRows.slice(25, actionRows.length);
      treeData = treeDataSnapshot;
    }

    treeData = await applyTreeActions(treeData!, treeCd, actionRows);
    treeData = await addOrphanPages(treeData!, notebookId);

    return treeData;
  }
};

/** Apply Tree Actions to TreeData object. Possible actions
 * - AddTreeNode
 * - RemoveTreeNode
 * - MoveTreeNode
 * - SetTreeNodeData
 */
const applyTreeActions = async (
  treeData: TreeData,
  treeCd: string,
  actions: (
    | PouchDB.Core.ExistingDocument<TreeActions & PouchDB.Core.AllDocsMeta>
    | undefined
  )[]
) => {
  for (const action of actions) {
    if (action) {
      if (treeCd < action.cd) {
        if (action.action === "AddTreeNode") {
          treeData = addItem(
            treeData,
            action.data.parentId!,
            action.data.pageId!
          );
        }
        if (action.action === "RemoveTreeNode") {
          try {
            // check if the item exist on tree before removing the item
            // if false then remove the action from DB
            if (treeData.items[action.data.pageId!]) {
              let [newTreeData] = removeItem(treeData, action.data.pageId!);
              treeData = newTreeData;
            } else {
              await localDb.remove(action);
            }
          } catch (error) {
            console.log("applyTreeActions - RemoveTreeNode - error", error);
          }
        }
        if (action.action === "MoveTreeNode") {
          try {
            treeData = moveItemOnTree(
              treeData,
              action.data.source!,
              action.data.destination!
            );
          } catch (error) {
            await localDb.remove(action);
            console.log("applyTreeActions - MoveTreeNode - error", error);
          }
        }
        if (action.action === "SetTreeNodeData") {
          try {
            treeData = mutateTree(treeData, action.data.pageId!, {
              data: { title: action.data.title },
            });
            //todo what happend when error
          } catch (error) {
            await localDb.remove(action);
            console.log("applyTreeActions - SetTreeNodeData - error", error);
          }
        }
      } else {
        // remove actions that are previous the snapshot
        await localDb.remove(action);
      }
    }
  }
  return treeData;
};

const addOrphanPages = async (treeData: TreeData, notebookId: string) => {
  let orphanPages: PageRow[];
  orphanPages = [];
  try {
    let pages = await localDb.allDocs<Page>({
      startkey: `p_${notebookId}`,
      endkey: `p_${notebookId}\uffff`,
    });
    orphanPages = pages.rows.filter(
      (page) => !treeData.items.hasOwnProperty(page.id)
    );
  } catch (error) {
    console.log("addOrphanPages - error", error);
  }

  if (orphanPages.length !== 0) {
    for (const orphanPage of orphanPages) {
      console.log("orphanPage", orphanPage);
      treeData = addItem(treeData, "root", orphanPage.id);

      try {
        let treeAction = {
          _id: `ta_${notebookId}_${getId()}`,
          action: "AddTreeNode",
          data: { parentId: "root", pageId: orphanPage.id },
          cd: new Date().toJSON(),
        };
        await localDb.put(treeAction);
      } catch (error) {
        console.log("addOrphanPages - creating action - error", error);
      }
    }
  }

  return treeData;
};
