export let pouchdb:
  | typeof import("./web")
  | null;

export const initializePouchDb = async (app: EbbinApps) => {
  switch (app) {
    case EbbinApps.WEB:
      pouchdb = await import("./web");
      break;
    default:
      break;
  }
};

export enum EbbinApps {
  ELECTRON_STANDALONE = "electron_standalone",
  ELECTRON = "electron",
  WEB = "web",
}

export * from "./sync";
