import { Editor, Transforms, Range } from "slate";
import { ReactEditor } from "slate-react";

import { IMAGE, WithPageIdEditor } from ".";

import { onload2promise, getId } from "@ebbin/common";
import { storage } from "@ebbin/storage";

export const withImage = <T extends ReactEditor>(editor: T) => {
  const e = editor as T & WithPageIdEditor;
  const { insertData, isVoid } = e;

  e.isVoid = (element) => {
    return element.type === IMAGE ? true : isVoid(element);
  };

  e.insertData = (data: DataTransfer) => {
    const { files } = data;

    if (files && files.length > 0) {
      for (const file of files) {
        const [mime] = file.type.split("/");

        if (mime === IMAGE) {
          let notebookId = e.pageId.substring(2, 13);
          let imageId = getId();
          let extension = file.type.split("/")[1];
          let s3 = `${notebookId}/${e.pageId}/${imageId}.${extension}`;

          (async function saveImageToStorage() {
            try {
              // todo: we should await for the image to be uploaded this should be manage on the ImageElement this is the reason we have a skeleton
              await storage!.saveBlobToStorage(s3, file);

              const dataUrl = await readBlobToDataUrlAsync(file);

              let img = new Image();
              let imgpromise = onload2promise(img);
              img.src = dataUrl as string;
              await imgpromise;
              let width = img.naturalWidth || img.width;
              let height = img.naturalHeight || img.height;

              insertImage(e, s3, width, height);
            } catch (error) {
              console.log("error", error);
            }
          })();
        }
      }
    } else {
      insertData(data);
    }
  };

  return e;
};

const readBlobToDataUrlAsync = (blob: Blob) => {
  return new Promise((resolve, reject) => {
    let reader = new FileReader();

    reader.onloadend = () => {
      resolve(reader.result);
    };

    reader.onerror = reject;

    reader.readAsDataURL(blob);
  });
};

export const insertImage = (
  editor: Editor,
  s3: string,
  width: number,
  height: number
) => {
  let aboveEntry = Editor.above(editor);
  if (aboveEntry) {
    let [above] = aboveEntry;
    if (Editor.isEmpty(editor, above)) {
      // Image over an image
      Transforms.setNodes(editor, {
        type: IMAGE,
        s3: s3,
        width: width,
        height: height,
      });
    } else if (above.indent !== undefined) {
      Transforms.insertNodes(editor, {
        type: IMAGE,
        children: [{ text: "" }],
        indent: above.indent,
        s3: s3,
        width: width,
        height: height,
      });
    } else {
      Transforms.insertNodes(editor, {
        type: IMAGE,
        children: [{ text: "" }],
        s3: s3,
        width: width,
        height: height,
      });
    }
  }
};

export const onKeyDownImage = (
  e: React.KeyboardEvent<HTMLDivElement>,
  editor: Editor
) => {
  if (editor.selection && Range.isCollapsed(editor.selection as Range)) {
    if (e.key === "Enter") {
      let [node, path] = Editor.parent(editor, editor.selection);
      if (node.type === IMAGE) {
        e.preventDefault();
        Transforms.insertNodes(
          editor,
          {
            children: [{ text: "" }],
            indent: node.indent,
            list: node.list,
            indexOL: node.indexOL,
          },
          { at: [path[0] + 1] }
        );
        Transforms.move(editor);
        return;
      }
    }

    if (e.key === "Delete") {
      let [node] = Editor.parent(editor, editor.selection);
      let isEmpty = Editor.isEmpty(editor, node);
      if (isEmpty) {
        e.preventDefault();
        Transforms.removeNodes(editor, { at: editor.selection });
        if (node.indent) {
          Transforms.move(editor);
          Transforms.setNodes(
            editor,
            { indent: node.indent, list: node.list, indexOL: node.indexOL },
            { at: editor.selection }
          );
        }
        return;
      }
    }
  }
};
