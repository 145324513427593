import { Editor } from "slate";

export interface WithHasChangeEditor extends Editor {
  hasChange: boolean;
}

export const withHasChange = <T extends Editor>(editor: T) => {
  const e = editor as T & WithHasChangeEditor;

  const { normalizeNode } = e;

  e.normalizeNode = (entry) => {
    if (!e.hasChange) {
      e.hasChange = true;
    }
    return normalizeNode(entry);
  };

  return e;
};
