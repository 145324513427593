import { Node, Range } from "slate";

import { Page } from "@ebbin/common";

import { localDb } from "..";

/** Save page to database */
export const udpatePage = async (
  page: Page,
  children: Node[],
  selection: Range | null
) => {
  try {
    if (!selection) {
      selection = {
        anchor: { path: [0, 0], offset: 0 },
        focus: { path: [0, 0], offset: 0 },
      };
    }

    let newPage = {
      ...page,
      children: children,
      selection: selection,
      md: new Date().toJSON(),
    };
    let response = await localDb.put(newPage, { force: true });
    return response;
  } catch (error) {
    console.log("savePage2 Error: - ERROR", error);
  }
};
