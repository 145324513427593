import React, { useState } from "react";
import { useTree, createCtx } from "@ebbin/components";
import { TreeData, ItemId } from "./types";

type SideMenuContextType = {
  selected: string;
  setSelected: (value: string) => void;
  tree: TreeData;
  setTree: (value: TreeData | ((prevState: TreeData) => TreeData)) => void;
  addLeafToTree: (parentId: ItemId, childId: string) => TreeData;
  removeLeafFromTree: (id: ItemId) => (TreeData | string[])[];
  notebookMenuOpen: boolean;
  setNotebookMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export const [useSideMenuState, CtxProvider] = createCtx<SideMenuContextType>();

type Props = {
  children: React.ReactNode;
};

export const SideMenuProvider = ({ children }: Props) => {
  const [notebookMenuOpen, setNotebookMenuOpen] = useState(false);
  const {
    selected,
    setSelected,
    tree,
    setTree,
    addLeafToTree,
    removeLeafFromTree,
  } = useTree();
  return (
    <CtxProvider
      value={{
        selected: selected,
        setSelected: setSelected,
        tree: tree,
        setTree: setTree,
        addLeafToTree,
        removeLeafFromTree,
        notebookMenuOpen,
        setNotebookMenuOpen,
      }}
    >
      {children}
    </CtxProvider>
  );
};
