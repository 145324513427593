import { NotebookDB } from "@ebbin/common";

import { AppThunk } from "../../../index";

import {
  OPEN_NOTEBOOK,
  getNotebooks,
  mergeTreeDatawithLocalCollapsedIds,
  setLocalLastOpenNotebookId,
  readCollapsedIds,
  updateCollapsedIdsLocalDb,
  createTreeDatafromDB,
  updateLastOpenPage,
  readLastOpenPage,
  localDb,
} from "..";

/** Set the proper valid redux state, when opening a book.
 * - if there was a open notebook, this will save this notebook first
 * - if notebook Id = "" it will open the first notebook on notebooks (state)
 * - save the notebookId to _local/lastOpenNotebookid
 */
export const openNotebook = (notebookId: string): AppThunk => async (
  dispatch,
  getState
) => {
  //openNotebook
  let currentNotebookId = getState().noteTaking.notebookId;
  let currentCollapsedIds = getState().noteTaking.collapsedIds;
  let currentPageId = getState().noteTaking.pageId;
  // If there was a open notebook save the last page that was open local
  if (currentNotebookId) {
    try {
      updateLastOpenPage(currentNotebookId, currentPageId);

      await updateCollapsedIdsLocalDb(currentNotebookId, currentCollapsedIds!);
    } catch (error) {
      console.log("openNotebook - ERROR 1:", error);
    }
  }

  //  if notebook Id = "" it will open the first notebook on notebooks
  if (!notebookId) {
    let notebooks = await getNotebooks();
    if (notebooks && notebooks[0]) {
      let treeData = await createTreeDatafromDB(notebooks[0]._id);
      let collapsedIds = await readCollapsedIds(notebooks[0]._id);

      let newTreeData = await mergeTreeDatawithLocalCollapsedIds(
        collapsedIds,
        treeData!
      );

      let pageId = await readLastOpenPage(notebooks[0]._id);

      dispatch({
        type: OPEN_NOTEBOOK,
        data: {
          notebookId: notebooks[0]._id,
          pageId: pageId,
          treeData: newTreeData,
        },
      });
      setLocalLastOpenNotebookId(notebooks[0]._id);
      return;
    }
  }
  // Open notebook and save the open notebook to _local/lastOpenNotebookid
  try {
    let notebook = await localDb.get<NotebookDB>(notebookId);

    setLocalLastOpenNotebookId(notebook._id);
    let treeData = await createTreeDatafromDB(notebook._id);
    let collapsedIds = await readCollapsedIds(notebook._id);
    let newTreeData = await mergeTreeDatawithLocalCollapsedIds(
      collapsedIds,
      treeData!
    );
    let pageId = await readLastOpenPage(notebook._id);

    return dispatch({
      type: OPEN_NOTEBOOK,
      data: {
        notebookId: notebook._id,
        pageId: pageId,
        treeData: newTreeData,
      },
    });
  } catch (error) {
    console.log("openNotebook - ERROR 2:", error);
  }
};
