import { TreeItemData } from "../../sideMenu/types";

import { getId } from "@ebbin/common";

import { localDb } from "..";

export const setNodeDataAction = async (pageId: string, data: TreeItemData) => {
  let notebookId = pageId.substr(2, 11);

  try {
    let treeAction = {
      _id: `ta_${notebookId}_${getId()}`,
      action: "SetTreeNodeData",
      data: { pageId: pageId, title: data.title, conflict: data.conflict },
      cd: new Date().toJSON(),
    };
    await localDb.put(treeAction);
  } catch (error) {
    console.log("moveNodeAction - ERROR", error);
  }
};
