import React from "react";

import { useSlate } from "slate-react";

import { ToggleButtonStyled } from "@ebbin/components";

type Props = {
  value: string;
  children: React.ReactNode;
};

export const RedoButton = ({ value, children }: Props) => {
  const editor = useSlate();

  return (
    <ToggleButtonStyled
      value={value}
      onMouseDown={(event) => {
        event.preventDefault();
        editor.redo();
      }}
      selected={false}
      disabled={editor.history.redos.length === 0}
      style={{
        filter: editor.history.redos.length === 0 ? "brightness(4)" : "none",
      }}
    >
      {children}
    </ToggleButtonStyled>
  );
};
