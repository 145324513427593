import React, { useState, useMemo, useRef, useCallback } from "react";
import { createCtx } from "@ebbin/components";
import { createEditor, Node, Text, Range } from "slate";
import { withHistory } from "slate-history";
import { withReact } from "slate-react";
import { Page } from "@ebbin/common";
import {
  withBlock,
  withList,
  withInline,
  withLinks,
  withPaste,
  withImage,
  withHasChange,
  withPageTitle,
  CustomEditor,
} from "@ebbin/editor";

type SlateContext = {
  value: Node[];
  setValue: React.Dispatch<React.SetStateAction<Node[]>>;
  editor: CustomEditor;
  slateHistorydRef: React.MutableRefObject<Record<string, any>>;
  pageRevIndex: number;
  setPageRevIndex: React.Dispatch<React.SetStateAction<number>>;
  pageRevsRef: React.MutableRefObject<Page[]>;
  isConflict: boolean;
  setIsConflict: React.Dispatch<React.SetStateAction<boolean>>;
  questionHighlight: QuestionHighlight | null;
  setQuestionHighlight: React.Dispatch<
    React.SetStateAction<QuestionHighlight | null>
  >;
  decorate: ([node, path]: any) => Range[];
};

export const [useSlateState, SlateStateCtxProvider] = createCtx<SlateContext>();

const initialValue: Node[] = [
  {
    children: [{ text: "" }],
  },
];

type QuestionHighlight = {
  question: Range[];
  answer: Range | undefined;
};

type Props = {
  children: React.ReactNode;
};

export const SlateProvider = ({ children }: Props) => {
  const [pageRevIndex, setPageRevIndex] = useState(0);
  const [value, setValue] = useState(initialValue);
  const slateHistorydRef = useRef<Record<string, any>>({});
  const [isConflict, setIsConflict] = useState(false);
  const pageRevsRef = useRef<Page[]>([]);
  const [
    questionHighlight,
    setQuestionHighlight,
  ] = useState<QuestionHighlight | null>(null);

  const decorate = useCallback(
    ([node, path]) => {
      const ranges: Range[] = [];

      if (questionHighlight && Text.isText(node)) {
        for (const questionRange of questionHighlight.question) {
          if (Range.includes(questionRange, path)) {
            const { text } = node;
            let offset = 0;

            ranges.push({
              anchor: { path, offset: offset },
              focus: { path, offset: offset + text.length },
              mark: "#fffbb5",
            });
          }
        }

        if (questionHighlight.answer) {
          if (Range.includes(questionHighlight.answer, path)) {
            const { text } = node;
            let offset = 0;

            ranges.push({
              anchor: { path, offset: offset },
              focus: { path, offset: offset + text.length },
              mark: "#d6f0ff",
            });
          }
        }
      }

      return ranges;
    },
    [questionHighlight]
  );

  const editor = useMemo(
    () =>
      withPageTitle(
        withHasChange(
          withLinks(
            withPaste(
              withInline(
                withImage(
                  withList(withBlock(withHistory(withReact(createEditor()))))
                )
              )
            )
          )
        )
      ),
    []
  );

  return (
    <SlateStateCtxProvider
      value={{
        value: value,
        setValue: setValue,
        editor: editor as CustomEditor,
        slateHistorydRef: slateHistorydRef,
        pageRevIndex: pageRevIndex,
        setPageRevIndex: setPageRevIndex,
        pageRevsRef: pageRevsRef,
        isConflict: isConflict,
        setIsConflict: setIsConflict,
        questionHighlight: questionHighlight,
        setQuestionHighlight: setQuestionHighlight,
        decorate: decorate,
      }}
    >
      {children}
    </SlateStateCtxProvider>
  );
};
