import React from "react";

import { makeStyles } from "@material-ui/core/styles";

import IconButton from "@material-ui/core/IconButton";

const useStyles = makeStyles((theme) => ({
  standaloneToggleButton: {
    border: "none",
    borderRadius: "0px",
  },
  arrowIcon: {
    borderRadius: 0,
    "&:focus": {
      background: `${theme.palette.action.selected}`,
      transition: `background-color 150ms ${theme.transitions.easing.easeInOut} 0ms`,
    },
  },
}));

type IconButtonType = React.ComponentProps<typeof IconButton>;
export const IconButtonStyled = (props: IconButtonType) => {
  const classes = useStyles();
  return (
    <IconButton
      classes={{ root: classes.arrowIcon }}
      className={classes.standaloneToggleButton}
      {...props}
    >
      {props.children}
    </IconButton>
  );
};
