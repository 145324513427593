import { jsx } from "slate-hyperscript";
import { ELEMENT_TAGS, CustomEditor, copyQuestionTag, IMAGE } from "..";
import { getId } from "@ebbin/common";
import {
  textPropsGenerator,
  linkNodeGenerator,
  listNodeGenerator,
  listCalculator,
  parentCalculator,
  isInvalidInlineNode,
  genericNodeGenerator,
} from "./util";

export const deserializeSlate = (
  el: any,
  editor: CustomEditor,
  h = 0,
  textProps = {},
  indent = 0,
  listType?: string
): any => {
  if (el.nodeType === 3) {
    // console.log(el.nodeType, "  ".repeat(h), el.nodeName, el.textContent);
    //todo: this must be consider when we add support for soft breaks
    let text = el.textContent.replace(/[\r\n]+/g, "");
    if (Object.keys(textProps).length === 0) {
      return text;
    } else {
      return { text: el.textContent, ...textProps };
    }
  } else if (el.nodeType !== 1) {
    return null;
  } else if (el.nodeName === "BR") {
    console.log("BR");
    return "\n";
  }

  // console.log(el.nodeType, "  ".repeat(h), el.nodeName);

  let parent = parentCalculator(el);

  [listType, indent] = listCalculator(el, indent, listType);

  let ebbinType = el.getAttribute("data-ebbin-type");

  if (ebbinType === "pinTag") {
    return { type: "pinTag", icon: el.innerText, children: [{ text: "" }] };
  }

  if (ebbinType === "questionTag") {
    let originalQuestionId = el.getAttribute("data-ebbin-id");
    let id = `q_${editor.pageId}_${getId()}`;

    copyQuestionTag(originalQuestionId, id);

    return {
      type: "questionTag",
      id: id,
      children: [{ text: "" }],
    };
  }

  if (isInvalidInlineNode(el)) {
    return { text: "" };
  }

  textProps = textPropsGenerator(el, textProps);

  h = h + 1;

  let children = Array.from(parent.childNodes)
    .map((n) => deserializeSlate(n, editor, h, textProps, indent, listType))
    .flat();

  if (el.nodeName === "BODY") {
    if (children[0] && children[0] === "\n") {
      children[0] = null;
    }

    if (
      children[children.length - 1] &&
      children[children.length - 1] === "\n\n"
    ) {
      children[children.length - 1] = null;
    }

    let nodes = jsx("fragment", {}, children);
    return nodes;
  }

  if (ebbinType) {
    if (children.length === 0) {
      children = [{ text: "" }];
    }

    if (ebbinType === "list-div") {
      return jsx("element", {}, children);
    }
    const attrs = { list: listType, indent: indent };
    if (ebbinType === "list-ul" || ebbinType === "list-ol") {
      if (children.length === 1 && children[0].type === IMAGE) {
        children[0].list = listType;
        children[0].indent = indent;
        return children;
      }
      return jsx("element", attrs, children);
    }
  }

  if (el.nodeName === "A") {
    return linkNodeGenerator(el, children);
  }

  if (el.nodeName === "LI") {
    // LI do not need to be use process if we are coping from ebbin
    if (el.parentNode.getAttribute("data-ebbin-type")) {
      return children;
    }
    return listNodeGenerator(children, listType!, indent);
  }

  if (ELEMENT_TAGS[el.nodeName]) {
    return genericNodeGenerator(el, children);
  }

  return children;
};
