import { createMuiTheme } from "@material-ui/core/styles";
import orange from "@material-ui/core/colors/orange";
import teal from "@material-ui/core/colors/teal";
import grey from "@material-ui/core/colors/grey";

declare module "@material-ui/core/styles/createMuiTheme" {
  interface Theme {
    sidebar: {
      width: React.CSSProperties["width"];
    };
    drawerHeader: {
      display: React.CSSProperties["display"];
      alignItems: React.CSSProperties["alignItems"];
      justifyContent: React.CSSProperties["justifyContent"];
    };
  }
  interface ThemeOptions {
    sidebar: {
      width: React.CSSProperties["width"];
    };
    drawerHeader: {
      display: React.CSSProperties["display"];
      alignItems: React.CSSProperties["alignItems"];
      justifyContent: React.CSSProperties["justifyContent"];
    };
  }
}

const defaultTheme = createMuiTheme();

// https://material-ui.com/customization/default-theme/
export const theme = createMuiTheme({
  sidebar: {
    width: 320,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: defaultTheme.spacing(0, 1),
    ...defaultTheme.mixins.toolbar,
  },
  palette: {
    primary: {
      light: orange[300],
      main: "#fbb040",
      dark: orange[700],
    },
    secondary: {
      light: teal[300],
      main: teal[500],
      dark: teal[700],
    },
    background: {
      paper: grey[50], //white
      default: grey[400],
    },
    action: {
      hover: "rgba(0, 0, 0, 0.04)", // "rgba(77, 208, 225, 0.4)"
    },
  },
  shape: {
    borderRadius: 0,
  },
});
