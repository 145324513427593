import React from "react";
import { Action } from "redux";
import { ThunkAction } from "redux-thunk";

import { NoteTakingApp } from "./noteTaking/NoteTakingApp";
import { StateType } from "./noteTaking/reducer";



type Props = {
  localDb: PouchDB.Database<{}>;
};
/**
 * Contains the whole NoteTakingApp
 */
export const NoteTakingApplication = ({ localDb }: Props) => {
  return <NoteTakingApp localDb={localDb} />;
};

export * from "./noteTaking/reducer";
export * from "./noteTaking/actions";

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  StateType,
  unknown,
  Action<string>
>;
