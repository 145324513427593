import { jsx } from "slate-hyperscript";
import { ELEMENT_TAGS } from "..";

import {
  textPropsGenerator,
  linkNodeGenerator,
  listNodeGenerator,
  listCalculator,
  parentCalculator,
  isInvalidInlineNode,
  genericNodeGenerator,
} from "./util";

export const deserializeOneNote = (
  el: any,
  h = 0,
  textProps = {},
  indent = 0,
  listType?: string
): any => {
  if (el.nodeType === 3) {
    // console.log(el.nodeType, "  ".repeat(h), el.nodeName, el.textContent);
    let text = el.textContent.replace(/[\r\n]+/g, " ");
    text = text.replace(/  +/g, " ");

    if (Object.keys(textProps).length === 0) {
      return text;
    } else {
      return { text: text, ...textProps };
    }
  } else if (el.nodeType !== 1) {
    return null;
  } else if (el.nodeName === "BR") {
    return "\n";
  }
  // console.log(el.nodeType, "  ".repeat(h), el.nodeName);

  let parent = parentCalculator(el);

  [listType, indent] = listCalculator(el, indent, listType);

  if (isInvalidInlineNode(el)) {
    return { text: "" };
  }

  textProps = textPropsGenerator(el, textProps);

  // h is use to calculate the hierarchy, this is use with two console.log
  // to understand complex parsing operations.
  h = h + 1;
  let children = Array.from(parent.childNodes)
    .map((n) => deserializeOneNote(n, h, textProps, indent, listType))
    .flat();

  if (el.nodeName === "BODY") {
    // In OneNote all text is inside a P, we filter all other text nodes and null
    children = children.filter((child) => child && child.children);
    let nodes = jsx("fragment", {}, children);
    return nodes;
  }

  if (el.nodeName === "A") {
    return linkNodeGenerator(el, children);
  }

  if (el.nodeName === "LI") {
    return listNodeGenerator(children, listType!, indent);
  }

  if (ELEMENT_TAGS[el.nodeName]) {
    return genericNodeGenerator(el, children);
  }
  return children;
};

export const isOneNoteTextFragment = (el: any): boolean => {
  let children = Array.from(el.childNodes) as any[];

  if (children[1].nodeType === 8) {
    return false;
  }
  return true;
};
