import { AppThunk } from "../../../index";

import { RENAME_NOTEBOOK, getNotebooks, localDb } from "..";


/** Rename a Notebook */
export const renameNotebook = (
  notebookId: string,
  newName: string
): AppThunk => async (dispatch) => {
  // renameNotebook
  try {
    let notebook = await localDb.get(notebookId);
    await localDb.put({
      ...notebook,
      name: newName,
      md: new Date().toJSON(),
    });

    let notebooks = await getNotebooks();
    if (notebooks) {
      dispatch({
        type: RENAME_NOTEBOOK,
        data: { notebooks: notebooks },
      });
    }
  } catch (error) {
    console.log("renameNotebook - Error", error);
  }
};
