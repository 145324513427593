import { LocalLastOpenNotebookid } from "@ebbin/common";

import { localDb } from "..";

/** Set the notebook id value in _local/lastOpenNotebookid */
export const setLocalLastOpenNotebookId = async (notebookId: string) => {
  try {
    let doc = await localDb.get<LocalLastOpenNotebookid>(
      "_local/lastOpenNotebookid"
    );
    await localDb.put({
      _id: "_local/lastOpenNotebookid",
      notebookId: notebookId,
      _rev: doc._rev,
    });
    return;
  } catch (error) {
    if (error.name === "not_found") {
      await localDb.put({
        _id: "_local/lastOpenNotebookid",
        notebookId: "",
      });
    } else {
      console.log("setLocalLastOpenNotebookid - ERROR", error);
    }
  }
};
