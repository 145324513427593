import React from "react";

import { Slate } from "slate-react";

import { useSlateState } from "./SlateProvider";
import { useBeforeUnload } from "./hooks/useBeforeUnload";
import { usePageTitle } from "./hooks/usePageTitle";
import { useSaveLoadPage } from "./hooks/useSaveLoadPage";
import { useHistory } from "./hooks/useHistory";

type Props = {
  children: React.ReactNode;
};
/** Control the value of the editor, history, title, save and load documents
 * - State is control by the hooks.
 *    - useBeforeUnload
 *    - usePageTitle
 *    - useSaveLoadPage
 *    - useHistory
 */
export const SlateRoot = ({ children }: Props) => {
  useBeforeUnload();
  usePageTitle();
  useSaveLoadPage();
  useHistory();

  const { value, setValue, editor } = useSlateState();

  return (
    <Slate
      editor={editor}
      value={value}
      onChange={(newValue) => {
        // it was added to fix bug #52
        if (newValue.length === 0) {
          newValue = [
            {
              children: [{ text: "" }],
            },
          ];
        }
        setValue(newValue);
      }}
    >
      {children}
    </Slate>
  );
};
