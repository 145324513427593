import { Editor, Transforms, Range, Point, Path, NodeEntry, Node } from "slate";
import { ReactEditor } from "slate-react";

/**
 * ! Selection BUG:
 * In slate the anchor is able to move, but the anchor shoud be static
 * and the focus should move only.
 *
 * We are tryng to block the use from selecting inline element in the middle
 * and be able to insert a break. We should be able to do this onChange, but
 * do not work for the anchor, for some reason move the focus as well.abs
 *
 * As workarout we have place the function on onMouseUp and insertBreak
 *
 */

export const withInline = <T extends ReactEditor>(editor: T) => {
  const { normalizeNode, insertBreak } = editor;

  editor.normalizeNode = (entry: NodeEntry) => {
    const [node, path] = entry;

    if (Editor.isInline(editor, node) && Editor.isEmpty(editor, node)) {
      console.log("To Delete");
      Transforms.removeNodes(editor, { at: path });
    }

    normalizeNode(entry);
  };

  editor.insertBreak = () => {
    selectInline(editor);
    insertBreak();
  };

  return editor;
};

export const onMouseUpInline = (
  e: React.MouseEvent<HTMLDivElement, MouseEvent>,
  editor: Editor
) => {
  selectInline(editor);
};

const selectInline = (editor: Editor) => {
  if (editor.selection && !Range.isCollapsed(editor.selection as Range)) {
    let anchor = editor.selection.anchor;
    let [parentAnchor, pathAnchor] = Editor.parent(editor, anchor);

    if (Editor.isInline(editor, parentAnchor)) {
      let start = Editor.start(editor, pathAnchor);
      let range = Editor.range(editor, start, editor.selection.focus);
      Transforms.select(editor, range);
    }

    let focus = editor.selection.focus;
    let [parentFocus, pathFocus] = Editor.parent(editor, focus);

    if (Editor.isInline(editor, parentFocus)) {
      let end = Editor.end(editor, pathFocus);
      let range = Editor.range(editor, editor.selection.anchor, end);
      Transforms.select(editor, range);
    }
    return;
  }
};

export const onKeyDownInline = (
  e: React.KeyboardEvent<HTMLDivElement>,
  editor: Editor
) => {
  if (e.key === "Enter") {
    if (editor.selection && Range.isCollapsed(editor.selection as Range)) {
      let above = Editor.above(editor);
      if (above) {
        let [node, path] = above;
        if (Editor.isInline(editor, node)) {
          // Case 1: Prevent breaking inlines
          e.preventDefault();
          let end = Editor.end(editor, path);
          // Case 2: Add breaking when selection is last point on the inline
          if (Point.equals(end, editor.selection.anchor)) {
            let [, parentPath] = Editor.parent(editor, path);
            let parentEnd = Editor.end(editor, parentPath);

            let after = Editor.after(editor, end);
            if (after) {
              if (Point.equals(after, parentEnd)) {
                insertNewLineUnder(editor, parentPath);
                return;
              } else {
                let range = Editor.range(editor, after, parentEnd);
                moveRestOfLineUnder(editor, parentPath, range);
                return;
              }
            }
          }
        } else {
          // Case 3: Add breaking when selection is first point before the inline
          let before = Editor.before(editor, editor.selection);

          if (before) {
            let [nodeBefore] = Editor.parent(editor, before);

            if (Editor.isInline(editor, nodeBefore)) {
              e.preventDefault();

              let [parentNode, parentPath] = Editor.parent(
                editor,
                editor.selection
              );

              let parentEnd = Editor.end(editor, parentPath);

              if (Point.equals(editor.selection.anchor, parentEnd)) {
                // Add nodeProps to solve bug #37
                let { children, ...nodeProps } = parentNode;
                insertNewLineUnder(editor, parentPath, nodeProps);
                return;
              } else {
                let range = Editor.range(
                  editor,
                  editor.selection.anchor,
                  parentEnd
                );
                moveRestOfLineUnder(editor, parentPath, range);
                return;
              }
            }
          }
        }
      }
    }
  }
};

const insertNewLineUnder = (
  editor: Editor,
  pathUnder: Path,
  props: Partial<Node>
) => {
  Transforms.insertNodes(
    editor,
    { ...props, children: [{ text: "" }] },
    { at: Path.next(pathUnder) }
  );
  Transforms.select(editor, Editor.start(editor, Path.next(pathUnder)));
};

const moveRestOfLineUnder = (editor: Editor, pathUnder: Path, range: Range) => {
  let fragment = Editor.fragment(editor, range);
  // We need to clone because the frangment use Refs
  let clonedFragment = JSON.parse(JSON.stringify(fragment));
  Transforms.insertNodes(
    editor,
    { children: clonedFragment[0].children },
    { at: Path.next(pathUnder) }
  );
  Transforms.delete(editor, { at: range });
  Transforms.select(editor, Editor.start(editor, Path.next(pathUnder)));
};

export const isInline = (editor: Editor) => {
  if (editor.selection) {
    let parent = Editor.parent(editor, editor.selection);
    let isInline = Editor.isInline(editor, parent[0]);
    if (isInline) {
      return true;
    }
  }
  return false;
};
