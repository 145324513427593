import React from "react";

import { useDispatch } from "react-redux";
import { OPEN_PAGE, addPage } from "../actions";

import { TreeItem, ItemId } from "./types";
import { makeStyles } from "@material-ui/core/styles";
import { ListItemSecondaryAction } from "@material-ui/core";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import IconButton from "@material-ui/core/IconButton";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

import { ToggleButtonStyled } from "@ebbin/components";
import { PageTreeDotsButton } from "./PageTreeDotsButton";
import { useSideMenuState } from "./SideMenuState";
import { PlusIcon } from "@ebbin/components";

const useStyles = makeStyles((theme) => {
  return {
    icons: {
      width: "20px",
      height: "20px",
    },
    menuButton: {
      borderRadius: 0,
    },
    listItemRoot: {
      "&.MuiListItem-root": {
        width: "100%",
        wordBreak: "break-word",
      },
    },
    listItemText: {
      "&.MuiListItemText-root": {
        paddingRight: "75px",
      },
      "& .MuiTypography-body1": {
        fontSize: "14px",
      },
    },
    listItemSecondaryAction: {
      "&.MuiListItemSecondaryAction-root": {
        right: "0px",
      },
    },
  };
});

type Props = {
  item: TreeItem;
  onExpand: (itemId: ItemId) => void;
  onCollapse: (itemId: ItemId) => void;
};
export const PageTreeItem = ({ item, onCollapse, onExpand }: Props) => {
  const classes = useStyles();

  const { selected, setSelected } = useSideMenuState();
  const dispatch = useDispatch();
  const handleExpandColapse = () => {
    item.isExpanded ? onCollapse(item.id) : onExpand(item.id);
  };

  return (
    <ListItem
      selected={selected === item.id}
      button
      onClick={() => {
        setSelected(item.id as string);
        dispatch({
          type: OPEN_PAGE,
          data: { pageId: item.id as string },
        });
      }}
      ContainerComponent="div"
      style={{ padding: "5px 0px 5px 0px" }}
      classes={{ root: classes.listItemRoot }}
    >
      <ListItemIcon style={{ minWidth: "35px" }}>
        <IconButton
          edge="end"
          aria-label="comments"
          size="small"
          disableRipple={true}
          className={classes.menuButton}
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();
            handleExpandColapse();
          }}
        >
          {item.children && item.children.length > 0 ? (
            item.isExpanded ? (
              <ExpandLess />
            ) : (
              <ExpandMore />
            )
          ) : null}
        </IconButton>
      </ListItemIcon>
      <ListItemText
        primary={item.data!.title}
        style={{ fontSize: "10px" }}
        classes={{ root: classes.listItemText }}
      />
      <ListItemSecondaryAction
        classes={{ root: classes.listItemSecondaryAction }}
      >
        <PageTreeDotsButton id={item.id as string} />
        <ToggleButtonStyled
          disabled={false}
          value="add"
          onClick={(e) => {
            e.preventDefault();
            dispatch(addPage(item.id as string));
          }}
        >
          <PlusIcon alt="" className={classes.icons} />
        </ToggleButtonStyled>
      </ListItemSecondaryAction>
    </ListItem>
  );
};
