import { jsx } from "slate-hyperscript";
import { ELEMENT_TAGS } from "..";

import {
  textPropsGenerator,
  linkNodeGenerator,
  listNodeGenerator,
  listCalculator,
  parentCalculator,
  isInvalidInlineNode,
  genericNodeGenerator,
} from "./util";

export const deserializeWeb = (
  el: any,
  h = 0,
  textProps = {},
  indent = 0,
  listType?: string
): any => {
  if (el.nodeType === 3) {
    // console.log(el.nodeType, "  ".repeat(h), el.nodeName, el.textContent);

    if (Object.keys(textProps).length === 0) {
      return el.textContent;
    } else {
      return { text: el.textContent, ...textProps };
    }
  } else if (el.nodeType !== 1) {
    return null;
  } else if (el.nodeName === "BR") {
    return "\n";
  }

  // console.log(el.nodeType, "  ".repeat(h), el.nodeName);

  let parent = parentCalculator(el);

  [listType, indent] = listCalculator(el, indent, listType);

  if (isInvalidInlineNode(el)) {
    return { text: "" };
  }

  textProps = textPropsGenerator(el, textProps);

  h = h + 1;

  let children = Array.from(parent.childNodes)
    .map((n) => deserializeWeb(n, h, textProps, indent, listType))
    .flat();

  if (el.nodeName === "BODY") {
    if (children[0] && children[0] === "\n") {
      children[0] = "";
    }

    if(children[children.length - 1] && children[children.length - 1] === "\n\n"){
      children[children.length - 1] = ""
    }

    let nodes = jsx("fragment", {}, children);
    return nodes;
  }

  if (el.nodeName === "A") {
    return linkNodeGenerator(el, children);
  }

  if (el.nodeName === "LI") {
    return listNodeGenerator(children, listType!, indent);
  }

  if (ELEMENT_TAGS[el.nodeName]) {
    return genericNodeGenerator(el, children);
  }

  return children;
};
