import React from "react";
import { SideMenuProvider } from "./SideMenuState";
import { SideMenu } from "./SideMenu";


export const SideMenuRoot = () => {

  return (
    <SideMenuProvider>
      <SideMenu />
    </SideMenuProvider>
  );
};
