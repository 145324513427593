import React from "react";
import { RenderElementProps } from "slate-react";
import { ListType } from "../contants";
import { ListDiv } from "./ListDiv";
import { ListUnordered } from "./ListUnordered";
import { ListOrdered } from "./ListOrdered";
/**
 * Create a Div that is indentable
 */
export const List = ({ attributes, children, element }: RenderElementProps) => {
  switch (element.list) {
    case ListType.DIV_LIST:
      return (
        <ListDiv attributes={attributes} element={element}>
          {children}
        </ListDiv>
      );
    case ListType.UL_LIST:
      return (
        <ListUnordered attributes={attributes} element={element}>
          {children}
        </ListUnordered>
      );
    case ListType.OL_LIST:
      return (
        <ListOrdered attributes={attributes} element={element}>
          {children}
        </ListOrdered>
      );

    default:
      return (
        <ListDiv attributes={attributes} element={element}>
          {children}
        </ListDiv>
      );
  }
};
