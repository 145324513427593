import { useState } from "react";

import { ItemId, removeItem, createTree, addItem } from "../components/tree";

/**
 * Manage the Tree data on the side menu
 */
export const useTree = () => {
  const [tree, setTree] = useState(createTree());
  const [selected, setSelected] = useState("");

  const addLeafToTree = (parentId: ItemId, childId: string) => {
    let newTree = addItem(tree, parentId, childId!);
    setTree(newTree);

    return newTree;
  };

  const removeLeafFromTree = (id: ItemId) => {
    let [newTree, deletedPageIds] = removeItem(tree, id);
    setTree(newTree);

    return [newTree, deletedPageIds];
  };

  return {
    selected,
    setSelected,
    tree,
    setTree,
    addLeafToTree,
    removeLeafFromTree,
  };
};
