import { Editor } from "slate";
import { IMAGE, QUESTION_TAG } from "@ebbin/editor";
import { Question } from "@ebbin/common";
import { updatePageMd, localDb } from "../actions";

import { storage } from "@ebbin/storage";

/**
 *  Manage the Page assets. Delete assets that are not in use anymore. Images and Questions
 */
export const useAssetManagement = () => {
  /**
   *  Manage the S3 bucket assets. Delete assets that are not in use anymore.
   */
  const cleanAssets = async (editor: any) => {
    // check do and undo
    let historyAssets: string[] = [];
    let historyQuestions: string[] = [];

    editor.history.undos.forEach((arr: any[]) => {
      arr.forEach((ope: any) => {
        if (ope.type === "remove_node" && ope.node.type === IMAGE) {
          historyAssets.push(ope.node.s3);
        }
        if (ope.type === "remove_node" && ope.node.type === QUESTION_TAG) {
          historyQuestions.push(ope.node.id);
        }
      });
    });

    editor.history.redos.forEach((arr: any[]) => {
      arr.forEach((ope: any) => {
        if (ope.type === "insert_node" && ope.node.type === IMAGE) {
          historyAssets.push(ope.node.s3);
        }
        if (ope.type === "insert_node" && ope.node.type === QUESTION_TAG) {
          historyQuestions.push(ope.node.id);
        }
      });
    });

    let pageAssets: string[] = [];
    let pageQuetions: string[] = [];
    let nodes = Editor.nodes(editor, {
      at: [],
      match: (n) => n.type === IMAGE || n.type === QUESTION_TAG,
    });
    for (let n of nodes) {
      let [node] = n;

      if (node.type === IMAGE) {
        pageAssets.push(node.s3 as string);
      }
      if (node.type === QUESTION_TAG) {
        pageQuetions.push(node.id as string);
      }
    }

    let assets = historyAssets.concat(pageAssets);
    let questions = historyQuestions.concat(pageQuetions);

    let { pageId } = editor;

    let documentAssetsUpdated = false;
    if (assets.length !== 0) {
      documentAssetsUpdated = await removeStorageAssets(pageId, assets);
    }
    if (questions.length !== 0) {
      documentAssetsUpdated = await removeDbQuestions(pageId, questions);
    }
    if (documentAssetsUpdated) {
      updatePageMd(pageId);
    }
  };

  return cleanAssets;
};

const removeStorageAssets = async (pageId: string, assets: any[]) => {
  try {
    let updateDocument = false;
    let notebookId = pageId.substring(2, 13);
    let s3 = `${notebookId}/${pageId}/`;

    let s3Assets = await storage!.list(s3);

    s3Assets.forEach(async (s3asset: any) => {
      if (!assets.includes(s3asset.key)) {
        updateDocument = true;
        await storage!.remove(s3asset.key);
      }
    });

    return updateDocument;
  } catch (error) {
    console.log("error - removeS3Assets :>> ", error);
    return false;
  }
};

const removeDbQuestions = async (pageId: string, questions: string[]) => {
  let questionsPrefix = `q_${pageId}`;
  try {
    let updateDocument = false;
    let dbQuestions = await localDb.allDocs<Question>({
      startkey: `${questionsPrefix}`,
      endkey: `${questionsPrefix}\uffff`,
    });

    dbQuestions.rows.forEach(async (question) => {
      if (!questions.includes(question.id)) {
        updateDocument = true;
        await localDb.remove(question.id, question.value.rev);
      }
    });

    return updateDocument;
  } catch (error) {
    console.log("removeQuestions :>> ", error);
    return false;
  }
};
