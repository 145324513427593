import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import ToggleButton from "@material-ui/lab/ToggleButton";

const useStyles = makeStyles((theme) => ({
  standaloneToggleButton: {
    border: "none",
    borderRadius: "0px",
  },
  arrowIcon: {
    borderRadius: 0,
    "&:focus": {
      background: `${theme.palette.action.selected}`,
      transition: `background-color 150ms ${theme.transitions.easing.easeInOut} 0ms`,
    },
  },
}));


type ToggleButtonType = React.ComponentProps<typeof ToggleButton>
export const ToggleButtonStyled = (props:ToggleButtonType) => {
  const classes = useStyles();
  return (
    <ToggleButton
      {...props}
      disableFocusRipple={true}
      classes={{ root: classes.arrowIcon }}
      size="small"
      className={classes.standaloneToggleButton}
    >
      {props.children}
    </ToggleButton>
  );
};


