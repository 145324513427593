import React, { useCallback } from "react";

import { useSelector } from "react-redux";
import { StateType } from "./reducer";

import { Editable } from "slate-react";

import { Element } from "../editor";

import {
  Leaf,
  OnKeyDownMark,
  onKeyDownList,
  onKeyDownImage,
  onKeyDownInline,
  onMouseUpInline,
  onKeyDownLink,
} from "@ebbin/editor";

import { makeStyles } from "@material-ui/core/styles";

import { LayoutContent } from "@ebbin/components";
import { useSlateState } from "./SlateProvider";
const useStyles = makeStyles(() => {
  return {
    editor: {
      fontSize: "16px",
      lineHeight: "26px",
      height: "100%",
    },
  };
});

/** Contain the Slate Editable component, and LayoutContent */
export const EditorArea = () => {
  const { editor, decorate, questionHighlight } = useSlateState();
  const classes = useStyles();

  const pageId = useSelector((state: StateType) => state.noteTaking.pageId);

  const renderElement = useCallback((props) => <Element {...props} />, []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const renderLeaf = useCallback((props) => <Leaf {...props} />, [
    questionHighlight,
  ]);



  return (
    <>
      {pageId === "" ? null : (
        <LayoutContent>
          <Editable
            decorate={decorate}
            className={classes.editor}
            onMouseUp={(event) => {
              onMouseUpInline(event, editor);
            }}
            renderElement={renderElement}
            renderLeaf={renderLeaf}
            onKeyDown={(event) => {
              OnKeyDownMark(event, editor);
              onKeyDownList(event, editor);
              onKeyDownImage(event, editor);
              onKeyDownLink(event, editor);
              onKeyDownInline(event, editor);
            }}
          />
        </LayoutContent>
      )}
    </>
  );
};


