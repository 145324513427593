export const SET_INITIAL_STATE = "SET_INITIAL_STATE";
export const ADD_NOTEBOOK = "ADD_NOTEBOOK";
export const OPEN_NOTEBOOK = "OPEN_NOTEBOOK";
export const DELETE_NOTEBOOK = "DELETE_NOTEBOOK";
export const RENAME_NOTEBOOK = "RENAME_NOTEBOOK";
export const ADD_PAGE = "ADD_PAGE";
export const OPEN_PAGE = "OPEN_PAGE";
export const DELETE_PAGE = "DELETE_PAGE";
export const UPDATE_TREEDATA = "UPDATE_TREEDATA";
export const UPDATE_TITLE = "UPDATE_TITLE";
export const ADD_COLLAPSED_ID = "ADD_COLLAPSED_ID"
export const REMOVE_COLLAPSED_ID = "REMOVE_COLLAPSED_ID"
export const CONFLICT_CURRENT_PAGE = "CONFLICT_CURRENT_PAGE"
export const RELOAD_CURRENT_PAGE = "RELOAD_CURRENT_PAGE"
export const PULL_PAGES = "PULL_PAGES"