import { Range, Node } from "slate";
import {
  TreeData,
  TreeSourcePosition,
  TreeDestinationPosition,
  ItemId,
} from "@atlaskit/tree";

export type NotebookDB = {
  name: string;
  cd: string;
  md: string;
  _id: string;
  _rev: string;
};

export type NotebookRow = {
  doc?:
    | PouchDB.Core.ExistingDocument<NotebookDB & PouchDB.Core.AllDocsMeta>
    | undefined;
  id: string;
  key: string;
  value: {
    rev: string;
    deleted?: boolean | undefined;
  };
};

export type TreeSnapshot = {
  cd: string;
  snapTime: string;
  treeData: TreeData;
  _id: string;
  _rev: string;
};

export type TreeActions = {
  action: string;
  data: {
    parentId?: ItemId;
    pageId?: string;
    source?: TreeSourcePosition;
    destination?: TreeDestinationPosition;
    title?: string;
  };
  cd: string;
};

export type Page = {
  children: Node[];
  selection: Range;
  cd: string;
  md: string;
  _id: string;
  _rev: string;
};

export type PageRow = {
  doc?:
    | PouchDB.Core.ExistingDocument<Page & PouchDB.Core.AllDocsMeta>
    | undefined;
  id: string;
  key: string;
  value: {
    rev: string;
    deleted?: boolean | undefined;
  };
};

export type Question = {
  _id: string;
  _rev: string;
  cd: string;
  md: string;
  rd: string;
  rn: number;
  score: number[];
  difficulty: number;
  enabled: boolean;
};

export type LocalLastOpenNotebookid = {
  notebookId: string;
};

export type LocalCollapseId = {
  collapsedIds: [];
};

export type LocalLastOpenPageid = {
  pageId: string;
};

export enum DbMode {
  LOCAL = "local",
  Sync = "sync",
  NONE = "none",
}
