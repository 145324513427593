import React from "react";

import { applyMiddleware, createStore, Action } from "redux";
import { Provider } from "react-redux";
import thunk, { ThunkAction } from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import rootReducer, { StateType } from "./rootReducer";

import { ThemeProvider } from "@material-ui/core/styles";
import { theme } from "@ebbin/common";

import { AppSetup } from "./AppSetup";

import "./index.css";


const middleware = [thunk];
const store = createStore(
  rootReducer,
  {},
  composeWithDevTools(applyMiddleware(...middleware))
);

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  StateType,
  unknown,
  Action<string>
>;

const App = () => {
  (window as any)["__react-beautiful-dnd-disable-dev-warnings"] = true;
  return (
    <>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <AppSetup />
        </ThemeProvider>
      </Provider>
    </>
  );
};

export default App;
