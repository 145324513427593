import { useEffect, useRef } from "react";

import { useSelector } from "react-redux";
import { StateType } from "../reducer";
import { setNodeDataAction, udpatePage } from "../actions";
import { Editor, Transforms } from "slate";
import { HistoryEditor } from "slate-history";

import { useSlateState } from "../SlateProvider";
import { useLoadPage } from "./useLoadPage";

/**
 * Save and load (use loadPage function) page on the editor and also save the history.
 * - Also auto have every x seconds after a change on the page.
 */
export const useSaveLoadPage = () => {
  const pageId = useSelector((state: StateType) => state.noteTaking.pageId);
  const loadPage = useLoadPage();
  const { editor, slateHistorydRef, pageRevsRef, setValue } = useSlateState();

  const previousPageIdRef = useRef("");

  const refTimeout = useRef<NodeJS.Timeout>(null!);

  // Save page, history, create the tree node action for title change.
  const savePage = () => {
    editor.hasChange = false;

    slateHistorydRef.current = addSlateHistory(
      editor,
      previousPageIdRef.current,
      slateHistorydRef.current
    );

    if (editor.newTitle) {
      let { newTitle } = editor;
      setNodeDataAction(previousPageIdRef.current, {
        title: newTitle,
      });
      editor.newTitle = null;
    }

    let children = JSON.parse(JSON.stringify(editor.children));
    let selection = JSON.parse(JSON.stringify(editor.selection));
    let page = JSON.parse(JSON.stringify(pageRevsRef.current[0]));
    (async function savePage() {
      let response;

      do {
        response = await udpatePage(page, children, selection);
        if (response && response.ok === false) {
          await new Promise((resolve) => setTimeout(resolve, 500));
        }
      } while (response && response.ok === false);
      if (pageRevsRef.current[0]._rev === page._rev) {
        pageRevsRef.current[0]._rev = response?.rev!;
      }
    })();
  };

  useEffect(() => {
    clearTimeout(refTimeout.current);

    if (previousPageIdRef.current && editor.hasChange) {
      savePage();
    }

    if (pageId) {
      loadPage(pageId);
    } else if (pageId === "") {
      HistoryEditor.withoutSaving(editor, () => {
        Transforms.select(editor, {
          anchor: { path: [0, 0], offset: 0 },
          focus: { path: [0, 0], offset: 0 },
        });
        setValue([{ children: [{ text: "" }] }, { children: [{ text: "" }] }]);
      });
    }

    // editor.pageId is use on image.ts to create the s3 asset location
    editor.pageId = pageId;

    previousPageIdRef.current = pageId;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageId]);

  // This the function that control autosave feature.
  useEffect(() => {
    if (editor.hasChange) {
      refTimeout.current = setTimeout(() => {
        if (editor.hasChange) {
          savePage();
        }
      }, 30000);
    }

    return () => {
      clearTimeout(refTimeout.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editor.hasChange]);

  return null;
};

/** Add slate history to slate history object, return the object */
const addSlateHistory = (
  editor: Editor,
  pageId: string,
  slateHistory: Record<string, any> | undefined
) => {
  if (!slateHistory) {
    slateHistory = {};
  }
  slateHistory[`${pageId}`] = editor.history;

  return slateHistory;
};
