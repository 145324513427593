import React from "react";
import { RenderLeafProps } from "slate-react";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => {
  return {
    code: {
      fontFamily: "monospace",
      fontSize: "12px",
    },
    color: {
      color: "green",
    },
  };
});

export const Leaf = ({ attributes, children, leaf }: RenderLeafProps) => {
  const classes = useStyles();
  if (leaf.bold) {
    children = <strong>{children}</strong>;
  }

  if (leaf.code) {
    children = <code className={classes.code}>{children}</code>;
  }

  if (leaf.italic) {
    children = <em>{children}</em>;
  }

  if (leaf.underline) {
    children = <u>{children}</u>;
  }

  if (leaf.strikethrough) {
    children = <del>{children}</del>;
  }

  if (leaf.mark) {
    children = (
      <mark
        style={{
          backgroundColor: `${leaf.mark}`,
          color: (leaf.color as string) ? (leaf.color as string) : "black",
        }}
      >
        {children}
      </mark>
    );
  }

  return (
    <span
      style={{ color: (leaf.color as string) ? (leaf.color as string) : "" }}
      {...attributes}
    >
      {children}
    </span>
  );
};
