import { Editor, Transforms, Range } from "slate";
import { ReactEditor } from "slate-react";

export interface WithPageTitleEditor extends Editor {
  newTitle: string | null;
  newTitleChange: boolean;
}

export const withPageTitle = <T extends ReactEditor>(editor: T) => {
  const e = editor as T & WithPageTitleEditor;

  const { normalizeNode, insertBreak } = e;

  e.normalizeNode = (entry) => {
    if (entry[1].length !== 0 && entry[1][0] === 0) {
      e.newTitle = Editor.string(e, [0]);
      e.newTitleChange = true;
    }
    normalizeNode(entry);
  };

  e.insertBreak = () => {
    if (e.selection && Range.isCollapsed(e.selection)) {
      let path = e.selection.anchor.path;
      if (path[0] === 0) {
        Transforms.insertNodes(e, { children: [{ text: "" }] }, { at: [1] });
        Transforms.move(e, { distance: 2, unit: "line" });
        return;
      }
    }
    if (e.selection && !Range.isCollapsed(e.selection)) {
      let path = e.selection.anchor.path;
      if (path[0] === 0) {
        return;
      }
    }
    insertBreak();
  };

  return e;
};
