import { useEffect } from "react";

import { useSelector } from "react-redux";
import { StateType } from "../reducer";

import { useSlateState } from "../SlateProvider";

/**
 * Manage editor history state
 * - When a page update is pull remove the page from history
 */
export const useHistory = () => {
  const pullPages = useSelector(
    (state: StateType) => state.noteTaking.pullPages
  );

  const { slateHistorydRef } = useSlateState();

  useEffect(() => {
    for (const pullPage of pullPages) {
      if (slateHistorydRef.current && slateHistorydRef.current[pullPage]) {
        slateHistorydRef.current[pullPage] = { undos: [], redos: [] };
      }
    }

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pullPages]);

  return null;
};
