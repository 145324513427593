import React from "react";

import { useDispatch } from "react-redux";
import { SET_IS_LOG_IN } from "@ebbin/components";
import { SET_DB_STATE } from "@ebbin/common";

import { NoteTakingApplication } from "@ebbin/note-taking-app";

import { LayoutRoot, LayoutHeader, LayoutMoreMenu } from "@ebbin/components";

import { signOut } from "@ebbin/components";


type Props = {
  localDb: PouchDB.Database<{}>;
};

/**
 * Main Ebbin App Component
 */
export const Ebbin = ({ localDb }: Props) => {
  const dispatch = useDispatch();

  const handleOnLogOut = async () => {
    console.log("signOut");
    try {
      localDb.destroy();
      signOut();
      dispatch({ type: SET_DB_STATE, data: { dbState: "initial" } });
      dispatch({ type: SET_IS_LOG_IN, data: { isLogIn: false } });
    } catch (error) {
      console.log("handleOnLogOut - error :>> ", error);
    }
  };
  return (
    <LayoutRoot>
      <LayoutHeader moreMenu={<LayoutMoreMenu onLogOut={handleOnLogOut} />} />
      <NoteTakingApplication localDb={localDb} />;
    </LayoutRoot>
  );
};
