import React from "react";
import PasteIconSvg from "./toolbar/ebbin-icons_paste.svg";
import UndoIconSvg from "./toolbar/ebbin-icons_undo.svg";
import RedoIconSvg from "./toolbar/ebbin-icons_redo.svg";
import BoldIconSvg from "./toolbar/ebbin-icons_bold.svg";
import ItalicIconSvg from "./toolbar/ebbin-icons_italic.svg";
import UnderlineIconSvg from "./toolbar/ebbin-icons_underline.svg";
import CodeIconSvg from "./toolbar/ebbin-icons_code.svg";
import StrikethroughIconSvg from "./toolbar/ebbin-icons_strikethrough.svg";
import ListBulletedIconSvg from "./toolbar/ebbin-icons_bulletpoints.svg";
import ListNumberingIconSvg from "./toolbar/ebbin-icons_numbering.svg";

import EbbinLogoIconSvg from "./toolbar/ebbin-icons_ebbinlogo.svg";
import DailyReviewIconSvg from "./toolbar/ebbin-icons_daily review.svg";
import StudyViewIconSvg from "./toolbar/ebbin-icons_study-view.svg";
import ExamIconSvg from "./toolbar/ebbin-icons_exams.svg";
import CustomReviewIconSvg from "./toolbar/ebbin-icons_custom review.svg";
import PlusIconSvg from "./toolbar/plus.svg";
import NotebookIconSvg from "./toolbar/ebbin-icons_notebook.svg";
import HighligherIconSvg from "./toolbar/ebbin-icons_highlight.svg";
import ColorIconSvg from "./toolbar/ebbin-icons_fontcolor.svg";

type IconProps = {
  props: React.DetailedHTMLProps<
    React.ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
  >;
};

export const PasteIcon = (props: any) => {
  return <img src={PasteIconSvg} alt={props.alt ? props.alt : ""} {...props} />;
};

export const UndoIcon = (props: any) => {
  return <img src={UndoIconSvg} alt={props.alt ? props.alt : ""} {...props} />;
};

export const RedoIcon = (props: any) => {
  return <img src={RedoIconSvg} alt={props.alt ? props.alt : ""} {...props} />;
};

export const BoldIcon = (props: any) => {
  return <img src={BoldIconSvg} alt={props.alt ? props.alt : ""} {...props} />;
};

export const ItalicIcon = (props: any) => {
  return (
    <img src={ItalicIconSvg} alt={props.alt ? props.alt : ""} {...props} />
  );
};

export const UnderlineIcon = (props: any) => {
  return (
    <img src={UnderlineIconSvg} alt={props.alt ? props.alt : ""} {...props} />
  );
};

export const CodeIcon = (props: any) => {
  return <img src={CodeIconSvg} alt={props.alt ? props.alt : ""} {...props} />;
};

export const StrikethroughIcon = (props: any) => {
  return (
    <img
      src={StrikethroughIconSvg}
      alt={props.alt ? props.alt : ""}
      {...props}
    />
  );
};

export const ListBulletedIcon = (props: any) => {
  return (
    <img
      src={ListBulletedIconSvg}
      alt={props.alt ? props.alt : ""}
      {...props}
    />
  );
};

export const ListNumberingIcon = (props: any) => {
  return (
    <img
      src={ListNumberingIconSvg}
      alt={props.alt ? props.alt : ""}
      {...props}
    />
  );
};

export const EbbinLogoIcon = (props: any) => {
  return (
    <img src={EbbinLogoIconSvg} alt={props.alt ? props.alt : ""} {...props} />
  );
};

export const DailyReviewIcon = (props: any) => {
  return (
    <img src={DailyReviewIconSvg} alt={props.alt ? props.alt : ""} {...props} />
  );
};

export const StudyViewIcon = (props: any) => {
  return (
    <img src={StudyViewIconSvg} alt={props.alt ? props.alt : ""} {...props} />
  );
};

export const ExamIcon = (props: any) => {
  return <img src={ExamIconSvg} alt={props.alt ? props.alt : ""} {...props} />;
};

export const CustomReviewIcon = (props: any) => {
  return (
    <img
      src={CustomReviewIconSvg}
      alt={props.alt ? props.alt : ""}
      {...props}
    />
  );
};

export const PlusIcon = (props: any) => {
  return <img src={PlusIconSvg} alt={props.alt ? props.alt : ""} {...props} />;
};

export const NotebookIcon = (props: any) => {
  return (
    <img src={NotebookIconSvg} alt={props.alt ? props.alt : ""} {...props} />
  );
};

export const HighligherIcon = (props: any) => {
  return (
    <img src={HighligherIconSvg} alt={props.alt ? props.alt : ""} {...props} />
  );
};

export const ColorIcon = (props: any) => {
  return <img src={ColorIconSvg} alt={props.alt ? props.alt : ""} {...props} />;
};





