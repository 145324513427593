import { Node, Editor, Transforms, Range, Text, Point, Path } from "slate";
import isHotkey from "is-hotkey";
import { ListType, Listkeys, MAX_INDENT, HOTKEYS_LIST } from ".";
import { ReactEditor } from "slate-react";

/**
 * Check if a node have the a list prop with the correct value
 */
export const isList = (n: Node) =>
  [ListType.OL_LIST, ListType.UL_LIST, ListType.DIV_LIST].includes(
    n.list as ListType
  );

/**
 * Check the selection contain a node with the list attribute
 */
export const isBlockList = (editor: Editor, format: string) => {
  const [match] = Editor.nodes(editor, {
    match: (n) => n.list === format,
    mode: "all",
  });

  return !!match;
};

/**
 * Toggle the list, manage the behaviour with collapse and no collapse
 * selection
 */
export const toggleList = (editor: UpdateListOLEditor, listType: string) => {
  if (editor.selection) {
    let [isListTypeInSelection] = Editor.nodes(editor, {
      at: editor.selection as Range,
      match: (n) => n.list === listType,
    });

    if (isListTypeInSelection) {
      Transforms.unsetNodes(editor, ["list", "indexOL"], {
        at: editor.selection as Range,
        match: (n) => {
          if (n.list) {
            return true;
          }
          return false;
        },
      });

      Transforms.unsetNodes(editor, ["indent"], {
        at: editor.selection as Range,
        match: (n) => {
          return n.list && n.indent === 0;
        },
      });
    } else {
      Transforms.setNodes(
        editor,
        { list: listType },
        {
          at: editor.selection as Range,
          match: (n) => {
            return (
              !Text.isText(n) &&
              !Editor.isEditor(n) &&
              !Editor.isInline(editor, n)
            );
          },
        }
      );

      Transforms.setNodes(
        editor,
        { indent: 0 },
        {
          at: editor.selection as Range,
          match: (n) => {
            return n.list && n.indent === undefined;
          },
        }
      );
    }
    editor.updateOL();
  }
};

/**
 * Manage the indent of the text using tab, shift and backspace
 */
const handleKeyDownList = (
  e: React.KeyboardEvent<HTMLDivElement>,
  editor: Editor,
  path?: Path
) => {
  path = path || Editor.start(editor, editor.selection as Range).path;

  /**
   * Increase the indent until MAX_INDENT
   * Also set the initial value of indent at 1
   */
  if (!e.shiftKey && e.key === Listkeys.TAB) {
    let above = Editor.above(editor, { at: path });

    if (above) {
      let [node, pathNode] = above;
      if ((node.indent as number) >= MAX_INDENT) {
        return;
      }

      node.indent
        ? Transforms.setNodes(
            editor,
            { indent: (node.indent as number) + 1 },
            { at: pathNode }
          )
        : Transforms.setNodes(editor, { indent: 1 }, { at: pathNode });
    }
  }

  if (e.shiftKey && e.key === Listkeys.TAB) {
    let above = Editor.above(editor, { at: path });

    if (above) {
      let [node, pathNode] = above;
      // If Indent is undefined the just return no effect
      if (node.indent === undefined) {
        return;
      }
      if (node.indent === 1) {
        // if the indent is on a list we can have indent 0
        if (isList(node)) {
          Transforms.setNodes(editor, { indent: 0 }, { at: pathNode });
        } else {
          Transforms.unsetNodes(editor, ["indent"], { at: pathNode });
        }
      }
      if ((node.indent as number) > 1) {
        Transforms.setNodes(
          editor,
          { indent: (node.indent as number) - 1 },
          { at: pathNode }
        );
      }
    }
  }

  /**
   * Decrease indent useing backspace
   * if the path is a list then remove the list and indexOL props
   */
  if (
    e.key === Listkeys.BACKSPACE &&
    editor.selection &&
    Point.equals(editor.selection.anchor as Point, {
      path: [editor.selection.anchor.path[0], 0],
      offset: 0,
    })
  ) {
    let above = Editor.above(editor, { at: path });

    if (above) {
      let [node, pathNode] = above;
      if (node.list !== undefined) {
        e.preventDefault();
        Transforms.unsetNodes(editor, ["list", "indexOL"], { at: pathNode });
        if (node.indent === 0) {
          e.preventDefault();
          Transforms.unsetNodes(editor, ["indent"], { at: pathNode });
        }
      } else {
        if (node.indent === undefined) {
          // Remove empty line between images,(void elements)
          let isEmpty = Editor.isEmpty(editor, node);
          if (isEmpty) {
            e.preventDefault();
            Transforms.removeNodes(editor, { at: editor.selection });
          }
          return;
        }
        if (node.indent === 1) {
          e.preventDefault();
          Transforms.unsetNodes(editor, ["indent"], { at: pathNode });
        }
        if ((node.indent as number) > 1) {
          e.preventDefault();
          Transforms.setNodes(
            editor,
            { indent: (node.indent as number) - 1 },
            { at: pathNode }
          );
        }
      }
    }
  }
};

export const onKeyDownList = (
  e: React.KeyboardEvent<HTMLDivElement>,
  editor: UpdateListOLEditor
) => {
  for (const hotkey in HOTKEYS_LIST) {
    if (e && isHotkey(hotkey, e as any)) {
      e.preventDefault();
      const mark = HOTKEYS_LIST[hotkey];
      toggleList(editor, mark);
    }
  }

  if (Object.values(Listkeys).includes(e.key)) {
    if (e.key === Listkeys.TAB) {
      e.preventDefault();
    }

    if (editor.selection && Range.isCollapsed(editor.selection as Range)) {
      handleKeyDownList(e, editor);
      editor.updateOL();
    }

    if (editor.selection && !Range.isCollapsed(editor.selection as Range)) {
      // let { selection } = editor;

      if (e.key === Listkeys.BACKSPACE) {
        return;
      }

      let nodeEntry = Editor.nodes(editor, {
        at: editor.selection as Range,
        match: (n) => {
          return (
            !Text.isText(n) &&
            !Editor.isEditor(n) &&
            !Editor.isInline(editor, n)
          );
        },
      });

      for (let nodeItem of nodeEntry) {
        let [, path] = nodeItem;

        handleKeyDownList(e, editor, [...path, 0]);
      }

      editor.updateOL();
    }
  }
};

export interface UpdateListOLEditor extends ReactEditor {
  updateOL: () => void;
}

/**
 * Contains updateOL, update the numbers on ordered lists
 */
export const withList = <T extends Editor>(editor: T) => {
  const e = editor as T & UpdateListOLEditor;
  const { insertBreak } = e;

  e.insertBreak = () => {
    insertBreak();
    e.updateOL();
  };

  e.updateOL = () => {
    let indexOL = new Array(MAX_INDENT).fill(1);

    e.children.forEach((child, index) => {
      if (child.list === ListType.OL_LIST) {
        let indent = child.indent as number;

        Transforms.setNodes(
          e as Editor,
          { indexOL: indexOL[indent] },
          { at: [index] }
        );

        indexOL[indent] = indexOL[indent] + 1;
      }

      if (child.indent !== undefined) {
        indexOL = indexOL.map((value, _index) => {
          return (child.indent as number) < _index ? 1 : value;
        });
      } else {
        indexOL = indexOL.map((value, _index) => {
          return 0 < _index ? 1 : value;
        });
      }
    });
  };

  return e;
};
