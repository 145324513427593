import { useSlateState } from "../SlateProvider";
import { Transforms } from "slate";
import { HistoryEditor } from "slate-history";
import { readPage } from "../actions";

import { useAssetManagement } from "./useAssetManagement";
/**
 *  Load a Page on the editor, take into account history, conflicts, and asset management.
 */
export const useLoadPage = () => {
  const {
    setValue,
    editor,
    slateHistorydRef,
    pageRevsRef,
    setIsConflict,
  } = useSlateState();

  const cleanAssets = useAssetManagement();

  /**
   *  Load a Page on the editor, take into account history, conflicts, and asset management.
   */
  const loadPage = async (
    pageId: string,
    option?: { clearHistory: boolean }
  ) => {
    let clearHistory;
    let isConflict = false;
    if (option) {
      clearHistory = option.clearHistory;
    } else {
      clearHistory = false;
    }

    let pages = await readPage(pageId);

    if (!pages) {
      return;
    }

    pageRevsRef.current = pages!;
    if (pages && pages.length > 1) {
      setIsConflict(true);
      isConflict = true;
    } else {
      setIsConflict(false);
    }

    let page = pages[0];
    HistoryEditor.withoutSaving(editor, () => {
      if (page.children.length === 0) {
        // it was added to fix bug #52
        page.children = [
          {
            children: [{ text: "" }],
          },
        ];
      }
      Transforms.select(editor, {
        anchor: { path: [0, 0], offset: 0 },
        focus: { path: [0, 0], offset: 0 },
      });
      setValue(page.children);
      if (page.selection) {
        Transforms.select(editor, page.selection);
      }
    });

    if (!clearHistory) {
      if (slateHistorydRef.current && slateHistorydRef.current[pageId]) {
        editor.history = slateHistorydRef.current[pageId];
      } else {
        editor.history = { undos: [], redos: [] };
      }
    } else {
      if (slateHistorydRef.current && slateHistorydRef.current[pageId]) {
        slateHistorydRef.current[pageId] = { undos: [], redos: [] };
      }
      editor.history = { undos: [], redos: [] };
    }

    // if there is no conflict we clean the s3 assets
    if (!isConflict) {
      cleanAssets(editor);
    }
  };

  return loadPage;
};
