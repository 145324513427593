import React from "react";

import { useDispatch } from "react-redux";
import {deleteNotebook} from "../actions"

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Delete from "@material-ui/icons/Delete";
import Typography from "@material-ui/core/Typography";
import { ToggleButtonStyled } from "@ebbin/components";

export const NotebookDeleteButton = ({ notebook }: any) => {
  const dispath = useDispatch()
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleRenameNotebook = () => {
    dispath(deleteNotebook(notebook))
  };

  return (
    <>
      <ToggleButtonStyled
        disabled={false}
        value="delete"
        onClick={handleClickOpen}
      >
        <Delete />
      </ToggleButtonStyled>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Delete Notebook</DialogTitle>
        <DialogContent>
          <Typography variant="body1" gutterBottom>
            Are you sure you want to delete the notebook "{notebook.name}" ?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleRenameNotebook} color="primary">
            Delete
          </Button>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
