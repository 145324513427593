import { NotebookDB, NotebookRow } from "@ebbin/common";

import { localDb } from "..";

/** Get all the notebooks on the Database */
export const getNotebooks = async () => {
  // let rowNotebooks;

  try {
    let query = await localDb.allDocs<NotebookDB>({
      startkey: "n_",
      endkey: "n_\uffff",
      include_docs: true,
      conflicts: true,
    });
    let rowNotebooks: NotebookRow[] = query.rows;

    let conflictFounded = false;
    for (let rowNotebook of rowNotebooks) {
      if (rowNotebook.doc?._conflicts) {
        console.log("getNotebooks - conflict - lastWriteWins");
        await lastWriteWins(rowNotebook);
        conflictFounded = true;
      }
    }

    if (conflictFounded) {
      try {
        let query = await localDb.allDocs<NotebookDB>({
          startkey: "n_",
          endkey: "n_\uffff",
          include_docs: true,
          conflicts: true,
        });
        rowNotebooks = query.rows;
      } catch (error) {
        console.log("getNotebooks - error02", error);
      }
    }

    let notebooks = rowNotebooks.map((n: NotebookRow) => {
      let doc = n.doc;
      return doc;
    });

    const compare = (
      a:
        | PouchDB.Core.ExistingDocument<NotebookDB & PouchDB.Core.AllDocsMeta>
        | undefined,
      b:
        | PouchDB.Core.ExistingDocument<NotebookDB & PouchDB.Core.AllDocsMeta>
        | undefined
    ) => {
      if (a!.name.toLowerCase() < b!.name.toLowerCase()) {
        return -1;
      }
      if (a!.name.toLowerCase() > b!.name.toLowerCase()) {
        return 1;
      }
      return 0;
    };
    notebooks.sort(compare);

    return notebooks;
  } catch (error) {
    console.log("getNotebooks - error01", error);
  }
};

//lastModify
const lastWriteWins = async (row: NotebookRow) => {
  let docs = await localDb.get<NotebookDB>(row.id, { open_revs: "all" });

  const compare = (
    a: PouchDB.Core.Revision<NotebookDB>,
    b: PouchDB.Core.Revision<NotebookDB>
  ) => {
    if (a.ok.md.toLowerCase() > b.ok.md.toLowerCase()) {
      return -1;
    }
    if (a.ok.md.toLowerCase() < b.ok.md.toLowerCase()) {
      return 1;
    }
    return 0;
  };
  docs.sort(compare);

  docs.map(async (doc, index) => {
    if (index === 0) {
      await localDb.put(doc.ok);
    } else {
      localDb.remove(doc.ok);
    }
  });
};
