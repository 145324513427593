import React, { useEffect, useRef } from "react";

import { useDispatch } from "react-redux";

import { useSideMenuState } from "./SideMenuState";

import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import TextField from "@material-ui/core/TextField";

import { addNotebook } from "../actions";
import { PlusIcon } from "@ebbin/components";

const useStyles = makeStyles(() => {
  return {
    icons: {
      width: "25px",
      height: "25px",
    },
  };
});

export const NotebookAddButton = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { setNotebookMenuOpen } = useSideMenuState();
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState("");

  const timerRef = useRef<NodeJS.Timeout>(null!);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleAddNotebook = () => {
    if (value) {
      dispatch(addNotebook(value));
    }
    timerRef.current = setTimeout(() => {
      setOpen(false);
      setNotebookMenuOpen(false);
    }, 200);
  };

  const handleChange = (event: any) => {
    setValue(event.target.value);
  };
  useEffect(() => {
    return () => {
      clearTimeout(timerRef.current);
    };
  }, []);
  return (
    <>
      <ListItem button onClick={handleClickOpen}>
        <ListItemIcon>
          <PlusIcon alt="" className={classes.icons} />
        </ListItemIcon>
        <ListItemText
          primary={"Add notebook"}
          primaryTypographyProps={{ noWrap: true }}
        />
      </ListItem>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">New Notebook</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            placeholder="Notebook Name"
            type="text"
            fullWidth
            onChange={handleChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAddNotebook} color="primary">
            Add NoteBook
          </Button>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
