import React, { useState, useRef } from "react";

import { useSelector } from "react-redux";
import { StateType } from "../reducer";
import { useDispatch } from "react-redux";

import { deletePage, disableQuestions, enableQuestions } from "../actions";

import IconButton from "@material-ui/core/IconButton";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => {
  return {
    menuButton: {
      height: "40px",
      width: "40px",
      borderRadius: 0,
      marginBottom: "4px",
      "&:focus": {
        background: `${theme.palette.action.selected}`,
        transition: `background-color 150ms ${theme.transitions.easing.easeInOut} 0ms`,
      },
    },
  };
});

type Props = { id: string };

export const PageTreeDotsButton = ({ id }: Props) => {
  const classes = useStyles();
  const pageId = useSelector((state: StateType) => state.noteTaking.pageId);
  const dispatch = useDispatch();

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const anchorRef = useRef<HTMLButtonElement>(null!);

  const handleOnClickAway = (e: React.MouseEvent<any>) => {
    if (anchorRef?.current?.contains(e.target as Node)) {
      return;
    }

    setIsMenuOpen(false);
  };

  const handleDisableQuestions = () => {
    disableQuestions(id);
    setIsMenuOpen(false);
  };

  const handleEnableQuestions = () => {
    enableQuestions(id);
    setIsMenuOpen(false);
  };

  const handleDelete = (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    dispatch(deletePage(id));
    setIsMenuOpen(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === "Tab" || event.key === "Escape") {
      event.preventDefault();
      setIsMenuOpen(false);
    }
  }
  return (
    <>
      <IconButton
        ref={anchorRef}
        color="inherit"
        aria-label="open more menu"
        disableRipple={true}
        className={classes.menuButton}
        onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
          e.preventDefault();
          setIsMenuOpen(!isMenuOpen);
        }}
      >
        <MoreHorizIcon />
      </IconButton>
      <Popper
        open={isMenuOpen}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        style={{ zIndex: 1310 }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleOnClickAway}>
                {pageId === id ? (
                  <MenuList
                    autoFocusItem={isMenuOpen}
                    id="menu-list-grow"
                    onKeyDown={handleListKeyDown}
                  >
                    <MenuItem onClick={handleDelete}>Delete Page</MenuItem>
                  </MenuList>
                ) : (
                  <MenuList
                    autoFocusItem={isMenuOpen}
                    id="menu-list-grow"
                    onKeyDown={handleListKeyDown}
                  >
                    <MenuItem onClick={handleDelete}>Delete Page</MenuItem>
                  </MenuList>
                )}
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};
