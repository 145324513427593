import { AppThunk } from "../../../index";

import { ADD_NOTEBOOK, getNotebooks, openNotebook, localDb } from "..";

import { getId } from "@ebbin/common";

export const addNotebook = (name: string): AppThunk => async (dispatch) => {
  //addNotebook
  let notebookGuid = getId();
  let notebookId = `n_${notebookGuid}`;

  let pageGuid = getId();
  let pageId = `p_${notebookId}_${pageGuid}`;

  let notebookResponse: PouchDB.Core.Response | null;
  notebookResponse = null; // makes ts happy

  let treeSnapshoResponse: PouchDB.Core.Response | null;
  treeSnapshoResponse = null;

  let treeActionResponse: PouchDB.Core.Response | null;
  treeActionResponse = null;

  let pageResponse: PouchDB.Core.Response | null;
  pageResponse = null;

  try {
    let notebook = {
      _id: notebookId,
      name: name,
      cd: new Date().toJSON(),
      md: new Date().toJSON(),
    };
    notebookResponse = await localDb.put(notebook);

    let treeSnapshot = {
      _id: `ts_${notebookId}`,
      cd: new Date().toJSON(),
      snapTime: new Date().toJSON(),
      treeData: {
        rootId: "root",
        items: {
          root: {
            id: "root",
            children: [],
            hasChildren: false,
            isExpanded: false,
            data: {},
          },
        },
      },
    };
    treeSnapshoResponse = await localDb.put(treeSnapshot);

    let treeAction = {
      _id: `ta_${notebookId}_${getId()}`,
      action: "AddTreeNode",
      data: { parentId: "root", pageId: pageId },
      cd: new Date().toJSON(),
    };
    treeActionResponse = await localDb.put(treeAction);

    let page = {
      _id: pageId,
      children: [
        {
          children: [{ text: "Untitled Page:", color: "#1e4e79" }],
          type: "h1",
        },
        { children: [{ text: "" }] },
      ],
      selection: {
        anchor: { path: [0, 0], offset: 0 },
        focus: { path: [0, 0], offset: 0 },
      },
      cd: new Date().toJSON(),
      md: new Date().toJSON(),
    };
    pageResponse = await localDb.put(page);
  } catch (error) {
    if (notebookResponse) {
      localDb.remove(notebookResponse.id, notebookResponse.rev);
    }

    if (treeSnapshoResponse) {
      localDb.remove(treeSnapshoResponse.id, treeSnapshoResponse.rev);
    }

    if (treeActionResponse) {
      localDb.remove(treeActionResponse.id, treeActionResponse.rev);
    }

    if (pageResponse) {
      localDb.remove(pageResponse.id, pageResponse.rev);
    }

    console.log("addNotebook - error", error);
    return;
  }

  let notebooks = await getNotebooks();
  if (notebooks) {
    dispatch({
      type: ADD_NOTEBOOK,
      data: { notebooks: notebooks },
    });
  }

  // Open the new notebook
  dispatch(openNotebook(notebookId));
};
