import React, { useState, useRef } from "react";

import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import IconButton from "@material-ui/core/IconButton";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import { EbbinLogoIcon } from "@ebbin/components";
import { DailyReviewIcon } from "@ebbin/components";
import { StudyViewIcon } from "@ebbin/components";
import { ExamIcon } from "@ebbin/components";
import { CustomReviewIcon } from "@ebbin/components";

const useStyles = makeStyles((theme) => {
  return {
    menuButton: {
      height: "45px",
      width: "90px",
      borderRadius: 0,
      padding: 0,
      "&:focus": {
        background: `${theme.palette.action.selected}`,
        transition: `background-color 150ms ${theme.transitions.easing.easeInOut} 0ms`,
      },
    },
    logoIcon: {
      width: "60px",
      height: "45px",
    },
    iconMenu: {
      minWidth: "40px",
    },
    icon: {
      width: "32px",
      height: "32px",
    },
  };
});

type EbbinButtonProps = {
  children: React.ReactNode;
  isMenuOpen: boolean;
  setIsMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export const EbbinButton = ({
  children,
  isMenuOpen,
  setIsMenuOpen,
}: EbbinButtonProps) => {
  const classes = useStyles();

  const anchorRef = useRef<HTMLButtonElement>(null!);

  const handleClose = (e: React.MouseEvent<any>) => {
    if (anchorRef?.current?.contains(e.target as Node)) {
      return;
    }

    setIsMenuOpen(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === "Tab" || event.key === "Escape") {
      event.preventDefault();
      setIsMenuOpen(false);
    }
  }
  return (
    <>
      <IconButton
        // disableFocusRipple={true}
        disableRipple={true}
        ref={anchorRef}
        color="inherit"
        aria-label="open more menu"
        className={classes.menuButton}
        onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
          e.preventDefault();
          setIsMenuOpen(!isMenuOpen);
        }}
      >
        <EbbinLogoIcon className={classes.logoIcon} alt="ebbin-button" />
      </IconButton>
      <Popper
        open={isMenuOpen}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        style={{ zIndex: 1200 }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={isMenuOpen}
                  id="menu-list-grow"
                  onKeyDown={handleListKeyDown}
                >
                  {children}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};
// CustomReviewIcon
