import { Page } from "@ebbin/common";

import { localDb } from "..";

/** Save page to database */
export const updatePageMd = async (pageId: string) => {
  try {
    let pageDoc = await localDb.get<Page>(pageId);
    await localDb.put({ ...pageDoc, md: new Date().toJSON() }, { force: true });
  } catch (error) {
    console.log("updatePageMd Error: - ERROR", error);
  }
};
