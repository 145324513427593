import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";

import {
  MARK_BOLD,
  MARK_CODE,
  MARK_ITALIC,
  MARK_STRIKETHROUGH,
  MARK_UNDERLINE,
  ListType,
} from "@ebbin/editor";

import { ToggleButtonMark } from "./ToggleButtonMark";
import { ToggleButtonList } from "./ToggleButtonList";
import { UndoButton } from "./UndoButton";
import { RedoButton } from "./RedoButton";
import { PasteButton } from "./PasteButton";
import { SelectFormatBlock } from "./SelectFormatBlock";
import { ColorPicker } from "./ColorPicker";
import { HighlighterPicker } from "./HighlighterPicker";

import { PasteIcon } from "@ebbin/components";
import { UndoIcon } from "@ebbin/components";
import { RedoIcon } from "@ebbin/components";
import { BoldIcon } from "@ebbin/components";
import { ItalicIcon } from "@ebbin/components";
import { UnderlineIcon } from "@ebbin/components";
import { StrikethroughIcon } from "@ebbin/components";
import { CodeIcon } from "@ebbin/components";
import { ListBulletedIcon } from "@ebbin/components";
import { ListNumberingIcon } from "@ebbin/components";

const useStyles = makeStyles(() => ({
  boxSpaceBetween: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
  },
  boxEditorTools: {
    display: "flex",
    alignItems: "center",
  },
  formatIcons: {
    width: "20px",
    height: "20px",
    marginLeft: "2px",
    marginRight: "2px",
  },
  icons: {
    width: "25px",
    height: "25px",
  },
}));

export const Toolbar = () => {
  const classes = useStyles();

  return (
    <>
      <Box className={classes.boxSpaceBetween}>
        <Box className={classes.boxEditorTools}>
          <Box pl={2}></Box>
          <UndoButton value="test">
            <UndoIcon alt="" className={classes.icons} />
          </UndoButton>
          <RedoButton value="test">
            <RedoIcon alt="" className={classes.icons} />
          </RedoButton>
          <Divider orientation="vertical" />
          <ToggleButtonMark value={MARK_BOLD}>
            <BoldIcon alt="" className={classes.formatIcons} />
          </ToggleButtonMark>
          <ToggleButtonMark value={MARK_ITALIC}>
            <ItalicIcon alt="" className={classes.formatIcons} />
          </ToggleButtonMark>
          <ToggleButtonMark value={MARK_UNDERLINE}>
            <UnderlineIcon alt="" className={classes.formatIcons} />
          </ToggleButtonMark>
          <ToggleButtonMark value={MARK_STRIKETHROUGH}>
            <StrikethroughIcon alt="" className={classes.formatIcons} />
          </ToggleButtonMark>
          <ToggleButtonMark value={MARK_CODE}>
            <CodeIcon alt="" className={classes.icons} />
          </ToggleButtonMark>
          <ColorPicker />
          <HighlighterPicker />
          <Divider orientation="vertical" />
          <ToggleButtonList value={ListType.UL_LIST}>
            <ListBulletedIcon alt="" className={classes.icons} />
          </ToggleButtonList>
          <ToggleButtonList value={ListType.OL_LIST}>
            <ListNumberingIcon alt="" className={classes.icons} />
          </ToggleButtonList>
          <Divider orientation="vertical" />
          <SelectFormatBlock />
        </Box>
      </Box>
    </>
  );
};
