import { Range, Editor, Transforms, Path, Point, Text } from "slate";
import { QUESTION_TAG, PIN_TAG, WithPageIdEditor, isList } from ".";
import { localDb } from "@ebbin/note-taking-app";
import { addDays, getId, Question } from "@ebbin/common";

export const withEbbin = (editor: Editor) => {
  const { isInline, isVoid } = editor;

  editor.isInline = (element) => {
    switch (element.type) {
      case QUESTION_TAG:
        return true;
      case PIN_TAG:
        return true;
      default:
        return isInline(element);
    }
  };

  editor.isVoid = (element) => {
    switch (element.type) {
      case QUESTION_TAG:
        return true;
      case PIN_TAG:
        return true;
      default:
        return isVoid(element);
    }
  };
  return editor;
};

export const onKeyDownEbbin = (
  e: React.KeyboardEvent<HTMLDivElement>,
  editor: WithPageIdEditor
) => {
  if (e.key === "?") {
    if (editor.selection && Range.isCollapsed(editor.selection as Range)) {
      const previousCharater = getPreviousCharacter(editor);
      if (previousCharater === "?") {
        if (hasQuesitonTag(editor)) {
          return;
        }
        e.preventDefault();
        Editor.deleteBackward(editor, {
          unit: "character",
        });
        insertQuestionTag(editor);
      }
      if (previousCharater === "/") {
        e.preventDefault();
        Editor.deleteBackward(editor, {
          unit: "character",
        });
        insertEndQuestionTag(editor);
      }
    }
  }
};

const insertQuestionTag = (editor: WithPageIdEditor) => {
  let id = `q_${editor.pageId}_${getId()}`;
  const tag = {
    type: QUESTION_TAG,
    id: id,
    children: [{ text: "" }],
  };
  Transforms.insertNodes(editor, tag);
  Transforms.move(editor);
  addQuestionDb(id);
};

/** Add question to database */
export const addQuestionDb = async (id: string) => {
  let date = new Date().toJSON();

  try {
    let question = {
      _id: id,
      cd: date,
      md: date,
      rd: addDays(date, 1).toJSON().split("T")[0],
      rn: 1,
      score: [],
      enabled: true,
      difficulty: 0,
    };

    await localDb.put(question);
  } catch (error) {
    console.log("addQuestion - error :>> ", error);
  }
};

/** Create a copy of a question of database */
export const copyQuestionTag = async (sourceId: string, targetId: string) => {
  try {
    let { _rev, ...question } = await localDb.get<Question>(sourceId);

    let newQuestion = { ...question, _id: targetId };

    await localDb.put(newQuestion);
  } catch (error) {
    console.log("copyQuestionTag - error :>> ", error);
  }
};

const insertEndQuestionTag = (editor: Editor) => {
  const tag = {
    type: PIN_TAG,
    icon: "📌",
    children: [{ text: "" }],
  };
  Transforms.insertNodes(editor, tag);
  Transforms.move(editor);
};

const hasQuesitonTag = (editor: Editor) => {
  if (editor.selection && Range.isCollapsed(editor.selection as Range)) {
    let nodeIndex0 = editor.selection.anchor.path[0];
    let [, first] = Editor.first(editor, [nodeIndex0]);
    let [, last] = Editor.last(editor, [nodeIndex0]);
    let nodeRange = Editor.range(editor, first, last);

    const [match] = Editor.nodes(editor, {
      at: nodeRange,
      match: (n) => n.type === QUESTION_TAG,
    });

    if (match) {
      return true;
    }
  }
  return false;
};

const getPreviousCharacter = (editor: Editor) => {
  if (editor.selection && Range.isCollapsed(editor.selection as Range)) {
    const [anchor] = Range.edges(editor.selection);
    const before = Editor.before(editor, anchor, {
      distance: 1,
      unit: "offset",
    });

    if (undefined === before) {
      return;
    }
    const range = Editor.range(editor, before, anchor);

    return Editor.string(editor, range);
  }
};

export const getQuestionRange = (editor: Editor, path: Path) => {
  let parent = Editor.parent(editor, path);
  if (isList(parent[0])) {
    let range = Editor.range(editor, path, Editor.start(editor, []));

    let indent = parent[0].indent as number;

    let nodeEntry = Editor.nodes(editor, {
      at: range,
      match: (n) => {
        if (indent < 0) {
          return false;
        }
        // Stop the iteration if there is a empty line
        if (
          Text.isText(n) === false &&
          Editor.isVoid(editor, n) === false &&
          ((n.children as any)[0].text as string) === ""
        ) {
          indent = -1;
          return false;
        }
        if (isList(n) && n.indent === indent - 1) {
          indent = indent - 1;
          return true;
        }
        return false;
      },
      reverse: true,
    });
    let ranges: Range[] = [];
    for (const n of nodeEntry) {
      let [, path] = n;

      let range = Editor.range(
        editor,
        Editor.start(editor, path),
        Editor.end(editor, path)
      );
      ranges.push(range);
    }

    let start = Editor.start(editor, [path[0]]);
    ranges.push(Editor.range(editor, start, path));
    return ranges;
  } else {
    let start = Editor.start(editor, [path[0]]);
    return [Editor.range(editor, start, path)];
  }
};

export const getAnswerRange = (editor: Editor, path: Path) => {
  let parent = Editor.parent(editor, path);
  let isParentList = isList(parent[0]);

  let after = Editor.after(editor, path, { unit: "character" });

  if (!after) return;

  let documentEnd = Editor.end(editor, []);

  let searchRange = Editor.range(editor, after, documentEnd);

  const nodes = Editor.nodes(editor, {
    at: searchRange,
    match: (n) => true,
  });

  let answerEnd: Point | undefined;
  for (let n of nodes) {
    let [node, path] = n;

    if (
      isParentList &&
      node.indent !== undefined &&
      parent[1][0] !== path[0] &&
      (node.indent as number) <= (parent[0].indent as number)
    ) {
      // console.log("Indent < Question indent Rule");
      answerEnd = Editor.end(editor, [path[0] - 1]);
      break;
    }

    if (node.type === QUESTION_TAG) {
      // console.log("Question Rule");

      answerEnd = Editor.end(editor, [path[0] - 1]);
      break;
    }

    if (node.type === PIN_TAG) {
      // console.log("Pin Rule");
      answerEnd = Editor.start(editor, path);
      break;
    }

    if (
      Text.isText(node) === false &&
      Editor.isVoid(editor, node) === false &&
      Editor.string(editor, path).trim() === ""
    ) {
      // console.log("Empty line");
      answerEnd = Editor.before(editor, path);
      break;
    }
  }

  if (!answerEnd) {
    // console.log("End document");
    answerEnd = documentEnd;
  }

  return Editor.range(editor, after, answerEnd);
};
