import { Page } from "@ebbin/common";

import { localDb } from "..";

/** Read a page from the database  */
export const readPage = async (pageId: string) => {
  try {
    let pages: Page[] = [];
    let page = await localDb.get<Page>(pageId, { conflicts: true });
    pages.push(page);

    if (page._conflicts) {
      for (const pageConflictRev of page._conflicts) {
        let pageConflict = await localDb.get<Page>(pageId, {
          rev: pageConflictRev,
        });
        pages.push(pageConflict);
      }
    }
    return pages;
  } catch (error) {
    console.log("readPage - ERROR:", error);
  }
};
