import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";

import { LayoutProvider } from "./LayoutState";

const useStyles = makeStyles((theme) => {
  return {
    root: {
      display: "flex",
      flexDirection: "column",
      height: "100%",
      overflowY: "hidden"
    },
  };
});

type Props = {
  children: React.ReactNode;
};

export const LayoutRoot = ({ children }: Props) => {
  const classes = useStyles();
  return (
    <LayoutProvider>
        <div className={classes.root}>
          <CssBaseline />
          {children}
        </div>
    </LayoutProvider>
  );
};
