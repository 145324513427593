interface OnLoadAble {
  onload: any;
  onerror: any;
}
export const onload2promise = <T extends OnLoadAble>(obj: T): Promise<T> => {
  return new Promise((resolve, reject) => {
    obj.onload = () => resolve(obj);
    obj.onerror = reject;
  });
};

/**
 * Allow to add or subtract days
 *
 * @param date a date string format 2020-07-06T13:30:53.379Z
 * @param days number of days to add, this can be negative
 */
export const addDays = (date: string, days: number) => {
  var result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
};

export const getId = () => {
  const generate = require("nanoid/generate");
  const alphabet =
    "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz-";
  return generate(alphabet, 9);
};
