import { jsx } from "slate-hyperscript";
import { ELEMENT_TAGS } from "..";
import { genericNodeGenerator } from "./util";

var Color = require("color");
/** VS Code deserialization is really simple, because of way the vscode create text using only div and span*/
export const deserializeVSCode = (
  el: any,
  hierarchy = 0,
  textProps = {}
): any => {
  if (el.nodeType === 3) {
    if (Object.keys(textProps).length === 0) {
      return el.textContent;
    } else {
      // Add two spaces for every space.
      let text = el.textContent.replace(/\u00a0/g, "  ");
      return { text: text, ...textProps };
    }
  } else if (el.nodeType !== 1) {
    return null;
  } else if (el.nodeName === "BR") {
    return "";
  }

  const { nodeName } = el;
  let parent = el;

  if (el.nodeName === "SPAN") {
    if (
      el?.style?.color &&
      el?.style?.color !== "rgb(0, 0, 0)" &&
      el?.style?.color !== "black"
    ) {
      let color = Color(el.style.color);
      // Made the color more darker
      textProps = { ...textProps, color: color.darken(0.2).hex() };
    }
  }

  hierarchy = hierarchy + 1;

  let children = Array.from(parent.childNodes)
    .map((n) => deserializeVSCode(n, hierarchy, textProps))
    .flat();

  if (el.nodeName === "BODY") {
    // Remove the first 2 and the last 2 elements
    children.pop();
    children.pop();
    children.shift();
    children.shift();

    let nodes = jsx("fragment", {}, children);

    return nodes;
  }

  if (el.nodeName === "DIV" && el.childNodes[0]) {
    if (el.childNodes[0].nodeName === "DIV") {
      return children;
    }

    return jsx("element", {}, children);
  }

  if (ELEMENT_TAGS[nodeName]) {
    return genericNodeGenerator(el, children);
  }

  return children;
};
