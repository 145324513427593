import React from "react";

import { useSlate } from "slate-react";

import {  toggleList, isBlockList, UpdateListOLEditor } from "@ebbin/editor";

import { ToggleButtonStyled } from "@ebbin/components";

type Props = {
  value: string;
  children: React.ReactNode;
};

export const ToggleButtonList = ({ value, children }: Props) => {
  const editor = useSlate();
  return (
    <ToggleButtonStyled
      value={value}
      onMouseDown={(event) => {
        event.preventDefault();
        toggleList(editor as UpdateListOLEditor, value);
      }}
      selected={isBlockList(editor, value)}
    >
      {children}
    </ToggleButtonStyled>
  );
};
