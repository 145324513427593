export let storage: typeof import("./s3/s3") | null;

export const initializeStogare = async (stogare: StorageTypes) => {
  switch (stogare) {
    default:
      storage = await import("./s3/s3");
      break;
  }
};

export enum StorageTypes {
  S3 = "s3",
}
