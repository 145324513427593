export const SET_IS_LOADING = "SET_IS_LOADING";
export const SET_DB_STATE = "SET_DB_STATE";

export type AppStateType = {
  isLoading: boolean;
  dbState: "initial" | "ready";
};

const initialState: AppStateType = {
  isLoading: true,
  dbState: "initial",
};

type AppActionType = {
  type: typeof SET_IS_LOADING | typeof SET_DB_STATE;
  data: Partial<AppStateType>;
};

export const app = (
  state: AppStateType = initialState,
  action: AppActionType
) => {
  switch (action.type) {
    case SET_IS_LOADING:
      return { ...state, isLoading: action.data.isLoading };
    case SET_DB_STATE:
      return { ...state, dbState: action.data.dbState };
    default:
      return state;
  }
};
