import React, { useState, useEffect } from "react";

import { useSelector } from "react-redux";
import { StateType } from "../reducer";

import { makeStyles } from "@material-ui/core/styles";

import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

import { useSideMenuState } from "./SideMenuState";
import { PageSideMenu } from "./PageSideMenu";
import { NotebookSideMenu } from "./NotebookSideMenu";

import { NotebookIcon } from "@ebbin/components";

const useStyles = makeStyles((theme) => {
  return {
    trackX: {
      background: "#fafafa !important",
    },
    scrollbar: {
      width: "100%",
    },
    contentBox: {
      [theme.breakpoints.up("sm")]: {},
      [theme.breakpoints.only("xs")]: {},
    },
    Notebookicon: {
      width: "30px",
      height: "30px",
    },
    icons: {
      width: "25px",
      height: "25px",
    },
  };
});

export const SideMenu = () => {
  const notebooks = useSelector(
    (state: StateType) => state.noteTaking.notebooks
  );
  const notebookId = useSelector(
    (state: StateType) => state.noteTaking.notebookId
  );
  const [textButton, setTextButton] = useState("Notebook");

  const classes = useStyles();
  const { notebookMenuOpen, setNotebookMenuOpen } = useSideMenuState();

  useEffect(() => {
    let currentNotebook = notebooks.filter((n) => n._id === notebookId) as any;

    if (currentNotebook.length === 1 && currentNotebook[0].name) {
      setTextButton(currentNotebook[0].name);
    }
    return () => {};
  }, [notebookId, notebooks]);

  useEffect(() => {
    if (notebookId === "") {
      setNotebookMenuOpen(true);
      setTextButton("Notebooks");
    } else {
      setNotebookMenuOpen(false);
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notebookId]);

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        style={{ overflowY: "auto", height: "100%" }}
      >
        <ListItem
          button
          onClick={(event) => {
            event.preventDefault();
            if (notebookId !== "") {
              setNotebookMenuOpen((prev) => !prev);
            }
          }}
        >
          <ListItemIcon>
            {notebookMenuOpen ? (
              <NotebookIcon
                alt=""
                className={classes.Notebookicon}
                style={{ filter: "grayscale(100%)" }}
              />
            ) : (
              <NotebookIcon alt="" className={classes.Notebookicon} />
            )}
          </ListItemIcon>
          <ListItemText primary={notebookMenuOpen ? "Notebooks" : textButton} />
        </ListItem>
        <Divider />
        {notebooks.length === 0 ? (
          <NotebookSideMenu />
        ) : notebookMenuOpen ? (
          <NotebookSideMenu />
        ) : (
          <PageSideMenu />
        )}
      </Box>
    </>
  );
};
