import React, { useEffect, useState } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import clsx from "clsx";
import Typography from "@material-ui/core/Typography";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import ArrowLeftIcon from "@material-ui/icons/ArrowLeft";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import Box from "@material-ui/core/Box";
import { ToggleButtonStyled } from "@ebbin/components";
import { useSlateState } from "./SlateProvider";
import { useConflictResolution } from "./hooks/useConflictResolution";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      position: "fixed",
      bottom: theme.spacing(8),
      right: theme.spacing(2),

      backgroundColor: "rgba(250, 250, 250, 0.9)",
      "& > *": {
        margin: theme.spacing(1),
      },
    },
    textLabel: {
      textAlign: "center",
      marginTop: "7px",
      fontWeight: "bold",
      userSelect: "none",
    },
    hide: {
      display: "none",
    },
    icons: {
      width: "25px",
      height: "25px",
    },
    iconsDelete: {
      color: `${theme.palette.error.main}`,
      width: "25px",
      height: "25px",
    },
  })
);
/** Small toolbar on the right bottom part of the screen, allow to compare revision and resolve conflict.
 */
export function ConflictToolbar() {
  const classes = useStyles();

  const deleteRev = useConflictResolution();

  const {
    pageRevIndex,
    setPageRevIndex,
    isConflict,
    pageRevsRef,
  } = useSlateState();

  const [hide, setHide] = useState(true);

  useEffect(() => {
    if (isConflict) {
      setHide(false);
    } else {
      setHide(true);
    }
    return () => {};
  }, [isConflict]);

  return (
    <div className={clsx(classes.root, hide && classes.hide)}>
      <div
        style={{ display: "flex", flexDirection: "row", marginBottom: "0px" }}
      >
        <Box pl={1} width="85px">
          {pageRevIndex === 0 ? (
            <Typography
              className={classes.textLabel}
              style={{ color: "#4caf50" }}
            >
              WINNER
            </Typography>
          ) : (
            <Typography color="error" className={classes.textLabel}>
              CONFLICT
            </Typography>
          )}
        </Box>
        <ToggleButtonStyled
          disabled={pageRevIndex === 0 ? true : false}
          value="left-arrow"
          onMouseDown={(event) => {
            event.preventDefault();
            setPageRevIndex(pageRevIndex - 1);
          }}
        >
          <ArrowLeftIcon
            className={classes.icons}
            style={{
              color: pageRevIndex === 0 ? "rgba(0, 0, 0, 0.26)" : "black",
            }}
          />
        </ToggleButtonStyled>
        <ToggleButtonStyled
          value="rigth-arrow"
          disabled={
            pageRevIndex === pageRevsRef.current.length - 1 ? true : false
          }
          onMouseDown={(event) => {
            event.preventDefault();
            setPageRevIndex((pageRevIndex) => pageRevIndex + 1);
          }}
        >
          <ArrowRightIcon
            className={classes.icons}
            style={{
              color:
                pageRevIndex === pageRevsRef.current.length - 1
                  ? "rgba(0, 0, 0, 0.26)"
                  : "black",
            }}
          />
        </ToggleButtonStyled>
        <ToggleButtonStyled
          value="remove-conflict"
          disabled={pageRevIndex === 0 ? true : false}
        >
          <DeleteForeverIcon
            className={classes.iconsDelete}
            onMouseDown={(event) => {
              event.preventDefault();
              deleteRev();
            }}
            style={{
              color: pageRevIndex === 0 ? "rgba(0, 0, 0, 0.26)" : "#f44336",
            }}
          ></DeleteForeverIcon>
        </ToggleButtonStyled>
      </div>

      <div style={{ marginBottom: "0px", marginTop: "0px" }}>
        <Typography
          style={{ textAlign: "center", fontSize: "12px", userSelect: "none" }}
        >
          Conflict Resolution
        </Typography>
      </div>
    </div>
  );
}
