import React, { useState } from "react";
import { DialogPage } from "./types";
import { createCtx } from "../hooks";

type AuthenticationContext = {
  dialogPage: DialogPage;
  setDialogPage: React.Dispatch<React.SetStateAction<DialogPage>>;
  email: string;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
};

export const [useAuthenticationState, AuthenticationCtxProvider] = createCtx<
  AuthenticationContext
>();

type AuthenticationProviderProps = {
  children: React.ReactNode;
};

export const AuthenticationProvider = ({
  children,
}: AuthenticationProviderProps) => {
  const [dialogPage, setDialogPage] = useState(DialogPage.LOG_IN);
  const [email, setEmail] = useState("");

  return (
    <AuthenticationCtxProvider
      value={{
        dialogPage: dialogPage,
        setDialogPage: setDialogPage,
        email: email,
        setEmail: setEmail,
      }}
    >
      {children}
    </AuthenticationCtxProvider>
  );
};
