import React, { useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import { DialogPage, signUp, useAuthenticationState } from ".";

const useStyles = makeStyles((theme) => {
  return {
    button: {
      textTransform: "none",
      padding: theme.spacing(1),
    },
  };
});

export const CreateAnAccountPage = () => {
  const classes = useStyles();
  const { setDialogPage, email, setEmail } = useAuthenticationState();
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");

  const handleCreateAccount = async () => {
    let user = await signUp(name, password, email);
    if (user) {
      setDialogPage(DialogPage.CONFIRM_CODE);
    }
  };

  return (
    <>
      <DialogTitle id="form-dialog-title" style={{ userSelect: "none" }}>
        Create an account
      </DialogTitle>
      <DialogContent style={{ maxWidth: "350px" }}>
        <form
          id="my-form"
          onSubmit={(event) => {
            event.preventDefault();
            handleCreateAccount();
          }}
        >
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Name"
            type="text"
            fullWidth
            required
            value={name}
            onChange={(event) => {
              setName(event.target.value);
            }}
          />
          <TextField
            margin="dense"
            id="email"
            label="Email Address"
            type="email"
            fullWidth
            required
            value={email}
            onChange={(event) => {
              setEmail(event.target.value);
            }}
          />

          <TextField
            margin="dense"
            id="password"
            label="Password"
            type="password"
            fullWidth
            required
            value={password}
            onChange={(event) => {
              setPassword(event.target.value);
            }}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          form="my-form"
          type="submit"
          variant="contained"
          color="primary"
        >
          Create Account
        </Button>
      </DialogActions>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Button
          className={classes.button}
          size="small"
          disableFocusRipple
          disableRipple
          onClick={() => {
            setDialogPage(DialogPage.LOG_IN);
          }}
          color="primary"
        >
          Log in
        </Button>
      </div>
    </>
  );
};
