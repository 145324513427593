import { LocalCollapseId } from "@ebbin/common";
import { TreeData } from "@atlaskit/tree";
import { localDb } from "..";

/** Merge the TreeData with the  Local collapsed Ids store in _local/${notebookId}/collapsedIds. This is done to avoid conflicts between devices when collapsing nodes. Collapsing is local to every app instance.
 */
export const mergeTreeDatawithLocalCollapsedIds = async (
  collapsedIds: string[],
  treeData: TreeData
) => {
  if (treeData && treeData.items) {
    for (let key in treeData.items) {
      if (treeData.items[key].hasChildren === true && key !== "root") {
        if (collapsedIds.includes(key)) {
          treeData.items[key].isExpanded = false;
        } else {
          treeData.items[key].isExpanded = true;
        }
      }
    }
  }

  return treeData;
};

export const readCollapsedIds = async (notebookId: string) => {
  let collapsedIds;

  try {
    let doc = await localDb.get<LocalCollapseId>(
      `_local/${notebookId}/collapsedIds`
    );
    collapsedIds = doc.collapsedIds;
  } catch (error) {
    if (error.name === "not_found") {
      await localDb.put({
        _id: `_local/${notebookId}/collapsedIds`,
        collapsedIds: [],
      });
      collapsedIds = [];
    } else {
      console.log("readCollapsedId - error:", error);
    }
  }
  return collapsedIds as string[];
};

export const updateCollapsedIdsLocalDb = async (
  notebookId: string,
  collapsedIds: string[]
) => {
  try {
    let doc = await localDb.get(`_local/${notebookId}/collapsedIds`);
    await localDb.put({
      ...doc,
      collapsedIds: collapsedIds,
    });
  } catch (error) {
    console.log("updateCollapsedIdsLocalDb - error:", error);
  }
};

export const deleteCollapsedIds = async (notebookId: string) => {
  try {
    let doc = await localDb.get<LocalCollapseId>(
      `_local/${notebookId}/collapsedIds`
    );
    localDb.remove(doc);
  } catch (error) {
    console.log("deleteCollapsedIds - error:", error);
  }
};
