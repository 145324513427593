import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Box from "@material-ui/core/Box";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import { LayoutMoreMenu } from "./LayoutMoreMenu";
import { useLayoutState } from "./LayoutState";
// import { EbbinButton } from "./EbbinButton";

const useStyles = makeStyles((theme) => {
  return {
    menuButton: {
      marginRight: theme.spacing(2),
    },
    toolbar: {
      justifyContent: "space-between",
    },
  };
});

type LayoutHeaderType = {
  mobileSidebarButton?: boolean;
  ebbinMenu?: JSX.Element;
  moreMenu?: JSX.Element;
};

export const LayoutHeader = ({
  ebbinMenu,
  mobileSidebarButton = true,
  moreMenu,
}: LayoutHeaderType) => {
  const classes = useStyles();
  const { setIsSidebarOpen } = useLayoutState();

  return (
    <>
      <AppBar position="static">
        <Toolbar className={classes.toolbar}>
          <Hidden smUp>
            {/* Mobile */}
            {mobileSidebarButton ? (
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={() => setIsSidebarOpen(true)}
                edge="start"
                className={clsx(classes.menuButton)}
              >
                <MenuIcon />
              </IconButton>
            ) : <div></div>}

            {ebbinMenu}
            {moreMenu}
          </Hidden>
          <Hidden xsDown>
            <Box display="flex" flex="auto">
              <Typography variant="h6" noWrap style={{ userSelect: "none" }}>
                ebbin
              </Typography>
            </Box>
            {ebbinMenu}
            {moreMenu}
          </Hidden>
        </Toolbar>
      </AppBar>
    </>
  );
};
