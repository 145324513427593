import React from "react";
import { RenderElementProps } from "slate-react";
import { useLayoutState } from "@ebbin/components";
/**
 * Create a Ordered List that is indentable
 */
export const ListOrdered = ({
  attributes,
  children,
  element,
}: RenderElementProps) => {
  const { mobileView } = useLayoutState();
  let marginLeftPx = mobileView ? 20 : 40;
  let marginLeft = element.indent ? element.indent as number * marginLeftPx : 0;

  return (
    <ol
      start={element.indexOL ? element.indexOL as number : 1}
      style={{
        padding: 0,
        marginLeft: `${marginLeft}px`,
        marginTop: 0,
        marginBottom: 0,
      }}
      {...attributes}
      data-ebbin-type="list-ol"
      data-ebbin-indent={element.indent}
    >
      <li>{children}</li>
    </ol>
  );
};

// https://css-tricks.com/custom-list-number-styling/
