import React, { useEffect } from "react";

import { useDispatch } from "react-redux";
import { initializeNoteTakingState } from "./actions";

import Hidden from "@material-ui/core/Hidden";
// import Box from "@material-ui/core/Box";
// import Typography from "@material-ui/core/Typography";

import { EditorArea } from "./EditorArea";
import { LayoutSidebar, LayoutToolbar } from "@ebbin/components";

import { SideMenuRoot } from "./sideMenu/SideMenuRoot";
import { Toolbar } from "./toolbar/Toolbar";
import { SlateRoot } from "./SlateRoot";
import { TestToolbar } from "./testToolbar/TestToolbar";
// import { TestSyncToolbar} from "./testToolbar/TestSyncToolbar"

import { SlateProvider } from "./SlateProvider";
import { ConflictToolbar } from "./ConflictToolbar";

type Props = {
  localDb: PouchDB.Database<{}>;
};

export const NoteTakingApp = ({ localDb }: Props) => {
  (window as any)["__react-beautiful-dnd-disable-dev-warnings"] = true;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(initializeNoteTakingState(localDb));
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <SlateProvider>
        <SlateRoot>
          <Hidden xsDown>
            <LayoutToolbar>
              <Toolbar />
            </LayoutToolbar>
          </Hidden>
          <LayoutSidebar>
            <SideMenuRoot />
          </LayoutSidebar>
          <EditorArea />
          <Hidden smUp>
            <LayoutToolbar>
              <Toolbar />
            </LayoutToolbar>
            {/* <Box style={{ width: "100%", height: "350px" }}>
            <Typography
              variant="h4"
              noWrap
              align="center"
              style={{ paddingTop: 50 }}
            >
              KEYBOARD
            </Typography>
          </Box> */}
          </Hidden>
          <ConflictToolbar />
          {/* <TestSyncToolbar/> */}
          {process.env.NODE_ENV === "development" ? <TestToolbar /> : null}
        </SlateRoot>
      </SlateProvider>
    </>
  );
};
