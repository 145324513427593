export const SET_IS_LOG_IN = "SET_IS_LOG_IN";

export type AuthenticationStateType = {
  isLogIn: boolean | null;
};

const initialState: AuthenticationStateType = {
  isLogIn: null,
};

type AuthenticationActionType = {
  type: typeof SET_IS_LOG_IN;
  data: Partial<AuthenticationStateType>;
};

export const authentication = (
  state: AuthenticationStateType = initialState,
  action: AuthenticationActionType
) => {
  switch (action.type) {
    case SET_IS_LOG_IN:
      return { ...state, isLogIn: action.data.isLogIn };
    default:
      return state;
  }
};
