import { Transforms, Text, Editor, Descendant, Point, Node } from "slate";
import { ReactEditor } from "slate-react";

import {
  deserializeWeb,
  deserializeVSCode,
  deserializeOneNote,
  isOneNoteTextFragment,
  deserializeSlate,
} from "./deserializers";

import { UpdateListOLEditor, CustomEditor } from ".";

export interface PasteEditor extends Editor {
  insertData: (data: DataTransfer) => void;
}

export const withPaste = (editor: PasteEditor & UpdateListOLEditor) => {
  const { insertData } = editor;

  editor.insertData = (data: DataTransfer) => {
    const html = data.getData("text/html");

    // We use any because of error "Variable 'ref' is used before being assigned"
    let ref: any;
    if (editor.selection) {
      if (
        Point.equals(
          Editor.end(editor, [editor.selection.anchor.path[0]]),
          editor.selection.anchor
        )
      ) {
        let after = Editor.after(editor, editor.selection);
        if (after) {
          ref = Editor.pointRef(editor, after);
        }
      } else {
        let start = Editor.start(editor, editor.selection);
        ref = Editor.pointRef(editor, start);
      }
    }

    //Slate
    if (data.types.includes("application/x-slate-fragment")) {
      const parsed = new DOMParser().parseFromString(html, "text/html");

      let nodes = deserializeSlate(
        parsed.body,
        editor as CustomEditor
      ) as Descendant[];

      insertPastedNodes(editor, nodes, ref);
      return;
    }

    // VSCode
    if (data.types.includes("vscode-editor-data")) {
      const parsed = new DOMParser().parseFromString(html, "text/html");
      let nodes = deserializeVSCode(parsed.body) as Descendant[];

      if (nodes.length === 1) {
        Transforms.insertFragment(editor, nodes, { hanging: false });
      } else {
        let [node] = Editor.parent(editor, editor.selection!);
        let isEmpty = Editor.isEmpty(editor, node);
        let isVoid = Editor.isVoid(editor, node);

        if (isEmpty && !isVoid) {
          Transforms.removeNodes(editor, { at: editor.selection! });
        }
        nodes = NormalizeTextInline(editor, nodes);

        let { indent } = node;

        if (indent) {
          nodes.map((node: Descendant) => (node.indent = indent));
        }

        Transforms.insertNodes(editor, nodes);
        if (ref && ref.current) {
          Transforms.select(editor, ref.current);
        } else {
          Transforms.select(editor, Editor.end(editor, []));
        }
      }

      return;
    }

    if (html) {
      const parsed = new DOMParser().parseFromString(html, "text/html");
      let generator = parsed.querySelector('meta[name="Generator"]')
        ?.attributes[1].nodeValue;

      // OneNote
      if (generator === "Microsoft OneNote 15") {
        let isTextFragement = isOneNoteTextFragment(parsed.body);

        let nodes = deserializeOneNote(parsed.body) as Descendant[];

        if (isTextFragement) {
          Transforms.insertFragment(editor, nodes[0].children as Text[], {
            hanging: false,
          });
        } else {
          Editor.deleteBackward(editor);
          nodes = NormalizeTextInline(editor, nodes);
          nodes = NormalizeInline(editor, nodes);

          let [node] = Editor.parent(editor, editor.selection!);
          let { indent } = node;

          if (indent) {
            nodes.map((node: Descendant) => (node.indent = indent));
          }

          Transforms.insertNodes(editor, nodes);

          editor.updateOL();
          if (ref && ref.current) {
            Transforms.select(editor, ref.current);
          } else {
            Transforms.select(editor, Editor.end(editor, []));
          }
        }
        return;
      }

      // Web
      let nodes = deserializeWeb(parsed.body) as Descendant[];

      insertPastedNodes(editor, nodes, ref);

      return;
    }

    insertData(data);
  };

  return editor;
};

const insertPastedNodes = (
  editor: ReactEditor & UpdateListOLEditor,
  nodes: Descendant[],
  ref: any
) => {
  if (isTextListOrInline(nodes, editor)) {
    // This filter was added to remove bug #36,

    let filterNodes = nodes.filter((node) => {
      if (node.text === "") {
        return false;
      }
      return true;
    });

    let wrapperNode = [{ children: filterNodes }];

    Transforms.insertFragment(editor, wrapperNode);
  } else {

    let indent: any;
    if (editor.selection) {
      let [node] = Editor.parent(editor, editor.selection.anchor);
      let isEmpty = Editor.isEmpty(editor, node);
      let isVoid = Editor.isVoid(editor, node);
      indent = node.indent;
      if (isEmpty && !isVoid) {
        Transforms.removeNodes(editor, { at: editor.selection });
      }
    }

    nodes = NormalizeTextInline(editor, nodes);
    nodes = NormalizeInline(editor, nodes);

    if (indent) {
      nodes.map((node: Descendant) => (node.indent = indent));
    }

    Transforms.insertNodes(editor, nodes);
    //* Note:
    /**Paste without normalization increase speed but we risk creating invalid nodes */

    // Editor.withoutNormalizing(editor, () =>
    //   Transforms.insertNodes(editor, nodes)
    // );

    editor.updateOL();
    if (ref && ref.current) {
      Transforms.select(editor, ref.current);
    } else {
      Transforms.select(editor, Editor.end(editor, []));
    }
  }
};

const isTextList = (textList: Descendant[]) => {
  for (const Element of textList) {
    if (!Text.isText(Element)) {
      return false;
    }
  }
  return true;
};

const isTextListOrInline = (textList: Descendant[], editor: Editor) => {
  for (const Element of textList) {
    if (!Text.isText(Element)) {
      if (!editor.isInline(Element)) return false;
    }
  }
  return true;
};

const NormalizeTextInline = (editor: Editor, nodes: Descendant[]) => {
  let textNodes: Descendant[] = [];
  let newNodes: Descendant[] = [];
  nodes.forEach((node, index) => {
    if (Text.isText(node) || Editor.isInline(editor, node)) {
      if (node.text === "") {
        return;
      }
      textNodes.push(node);
      if (
        nodes[index + 1] &&
        (Text.isText(nodes[index + 1]) ||
          Editor.isInline(editor, nodes[index + 1]))
      ) {
        return;
      } else {
        newNodes.push({ children: textNodes });
        textNodes = [];
      }
    } else {
      newNodes.push(node);
    }
  });
  return newNodes;
};

const NormalizeInline = (editor: Editor, nodes: Descendant[]) => {
  let newNodes = nodes.map((node) => {
    if (node.children) {
      node.children = NormalizeInLineNode(editor, node.children);
    }
    return node;
  });
  return newNodes;
};

const NormalizeInLineNode = (editor: Editor, nodes: any) => {
  nodes = nodes
    .map((node: any, index: number) => {
      let isInline = Editor.isInline(editor, node);

      if (index === 0 && isInline) {
        // if the inline element is the only children
        if (nodes.length === 1) {
          return [{ text: "" }, node, { text: "" }];
        }
        return [{ text: "" }, node];
      }

      if (index === nodes.length - 1 && isInline) {
        return [node, { text: "" }];
      }

      if (isInline) {
        if (Editor.isInline(editor, nodes[index + 1])) {
          return [node, { text: "" }];
        }
      }
      return node;
    })
    .flat();

  if (nodes.children) {
    NormalizeInline(editor, nodes.children);
  }
  return nodes;
};
