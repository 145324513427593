import React from "react";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import { makeStyles } from "@material-ui/core/styles";

import { useLayoutState } from "./LayoutState";

let marginTop = 142;

const useStyles = makeStyles((theme) => {
  return {
    drawer: {
      width: theme.sidebar.width,
      flexShrink: 0,
    },
    drawerPaper: {
      [theme.breakpoints.up("sm")]: {
        width: theme.sidebar.width,
        height: `calc(100% - ${marginTop}px)`,
        marginTop: marginTop,
      },
      [theme.breakpoints.only("xs")]: {
        width: "100%",
      },
    },
    drawerHeader: {
      ...theme.drawerHeader,
    },
  };
});

type Props = {
  children: React.ReactNode;
};
export const LayoutSidebar = ({ children }: Props) => {
  const { isSidebarOpen, setIsSidebarOpen } = useLayoutState();
  const classes = useStyles();

  return (
    <>
      <Hidden smUp>
        {/* Mobile */}
        <Drawer
          className={classes.drawer}
          variant="temporary"
          anchor="left"
          open={isSidebarOpen}
          onClose={()=>setIsSidebarOpen(false)}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          <div className={classes.drawerHeader}>
            <IconButton onClick={()=>setIsSidebarOpen(false)}>
              <ChevronLeftIcon />
            </IconButton>
          </div>
          <Divider />
          {children}
        </Drawer>
      </Hidden>
      <Hidden xsDown>
        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="left"
          open={isSidebarOpen}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <Divider />
          {children}
        </Drawer>
      </Hidden>
    </>
  );
};
