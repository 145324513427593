import React from "react";
import { RenderElementProps } from "slate-react";
import { useLayoutState } from "@ebbin/components";
/**
 * Create a Div that is indentable
 */
export const ListDiv = ({
  attributes,
  children,
  element,
}: RenderElementProps) => {
  const { mobileView } = useLayoutState();
  let marginLeftPx = mobileView ? 20 : 40;
  let marginLeft = element.indent
    ? (element.indent as number) * marginLeftPx
    : 0;

  return (
    <div
      style={{ marginLeft: `${marginLeft}px` }}
      {...attributes}
      data-ebbin-type="list-div"
      data-ebbin-indent={element.indent}
    >
      {children}
    </div>
  );
};
