import React from "react";
import clsx from "clsx";

import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Hidden from "@material-ui/core/Hidden";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import Scrollbar from "react-scrollbars-custom"; // draw in top of the bar

import { useLayoutState } from "./LayoutState";

const useStyles = makeStyles((theme) => {
  return {
    appBar: {
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      [theme.breakpoints.up("sm")]: {
        paddingTop: "10px",

        top: 64,
      },
      [theme.breakpoints.only("xs")]: {
        top: 56,
      },
    },
    menuButton: {
      height: "45px",
      width: "45px",
      borderRadius: 0,
      marginLeft: theme.spacing(1),
      marginBottom: theme.spacing(2.5),
    },
    toolbar: {
      flexWrap: "nowrap",
      WebkitOverflowScrolling: "touch",
      padding: "0",
    },
    trackX: {
      background: "#fafafa !important",
    },
    scrollbar: {
      width: "100%",

      [theme.breakpoints.up("sm")]: {
        minHeight: "68px",
      },
      [theme.breakpoints.only("xs")]: {
        marginTop: "5px",
        minHeight: "56px",
      },
    },
  };
});

type Props = {
  children: React.ReactNode;
};
export const LayoutToolbar = ({ children }: Props) => {
  const classes = useStyles();

  const { isSidebarOpen, setIsSidebarOpen } = useLayoutState();
  return (
    <>
      <AppBar
        color="inherit"
        position="static"
        className={clsx(classes.appBar)}
      >
        <Toolbar className={classes.toolbar}>
          <Hidden xsDown>
            <IconButton
              disableFocusRipple={true}
              disableRipple={true}
              color="inherit"
              aria-label="open drawer"
              onClick={() =>
                setIsSidebarOpen((isSidebarOpen) => !isSidebarOpen)
              }
              edge="start"
              className={clsx(classes.menuButton)}
            >
              {isSidebarOpen ? <ChevronLeftIcon /> : <MenuIcon />}
            </IconButton>
          </Hidden>
          <Scrollbar
            mobileNative={true}
            noScrollY={true}
            removeTracksWhenNotUsed={true}
            trackXProps={{ className: `${classes.trackX}` }}
            className={classes.scrollbar}
          >
            {children}
          </Scrollbar>
        </Toolbar>
      </AppBar>
    </>
  );
};
