import React from "react";

import { useSelector, useDispatch } from "react-redux";
import { StateType } from "../reducer";
import { openNotebook } from "../actions";

import { makeStyles } from "@material-ui/core/styles";

import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Scrollbar from "react-scrollbars-custom";

import { useSideMenuState } from "./SideMenuState";
import { NotebookAddButton } from "./NotebookAddButton";

import { NotebookIcon } from "@ebbin/components";
import { NotebookRenameButton } from "./NotebookRenameButton";
import { NotebookDeleteButton } from "./NotebookDeleteButton";

const useStyles = makeStyles((theme) => {
  return {
    trackX: {
      background: "#fafafa !important",
    },
    scrollbar: {
      width: "100%",
    },
    contentBox: {
      [theme.breakpoints.up("sm")]: {},
      [theme.breakpoints.only("xs")]: {},
    },
    listItemGutters: {
      "&.MuiListItem-gutters": {
        paddingLeft: "8px",
      },
    },
    Notebookicon: {
      width: "30px",
      height: "30px",
    },
    listItemIcon: {
      "&.MuiListItemIcon-root": {
        minWidth: "45px",
      },
    },
    icons: {
      width: "25px",
      height: "25px",
    },
    listItemRoot: {
      "&.MuiListItem-root": {
        width: "100%",
        wordBreak: "break-all",
      },
      "&.MuiListItem-gutters": {
        paddingLeft: "8px",
      },
    },
    listItemText: {
      "&.MuiListItemText-root": {
        paddingRight: "22px",
      },
    },
    listItemSecondaryAction: {
      "&.MuiListItemSecondaryAction-root": {
        right: "0px",
      },
    },
  };
});

/**
 * Comment
 */
export const NotebookSideMenu = () => {
  const { setNotebookMenuOpen } = useSideMenuState();
  const dispatch = useDispatch();
  const classes = useStyles();

  const notebooks = useSelector(
    (state: StateType) => state.noteTaking.notebooks
  );

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="flex-start"
        flex="auto"
        style={{
          overflowY: "auto",
          minHeight: "0px",
        }}
      >
        {/* This scroll cause an error in react bdnd about no nested scrolls */}
        <Scrollbar
          mobileNative={true}
          noScrollX={true}
          removeTracksWhenNotUsed={true}
          trackXProps={{ className: `${classes.trackX}` }}
          className={classes.scrollbar}
        >
          <List>
            {notebooks.map((notebook) => {
              return (
                <ListItem
                  key={notebook._id}
                  button
                  onClick={() => {
                    dispatch(openNotebook(notebook._id));
                    setTimeout(() => {
                      setNotebookMenuOpen(false);
                    }, 50);
                  }}
                  classes={{ root: classes.listItemRoot }}
                >
                  <ListItemIcon classes={{ root: classes.listItemIcon }}>
                    <NotebookIcon alt="" className={classes.Notebookicon} />
                  </ListItemIcon>
                  <ListItemText
                    primary={notebook.name}
                    classes={{ root: classes.listItemText }}
                  />
                  <ListItemSecondaryAction
                    classes={{ root: classes.listItemSecondaryAction }}
                  >
                    <NotebookRenameButton notebook={notebook} />
                    <NotebookDeleteButton notebook={notebook} />
                  </ListItemSecondaryAction>
                </ListItem>
              );
            })}
          </List>
        </Scrollbar>
      </Box>

      <Divider />
      <NotebookAddButton />
    </>
  );
};
