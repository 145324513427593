import { useEffect, useRef } from "react";

import { useSelector, useDispatch } from "react-redux";
import { StateType } from "../reducer";

import { Transforms } from "slate";
import { HistoryEditor } from "slate-history";

import {
  CONFLICT_CURRENT_PAGE,
  setNodeDataAction,
  udpatePage,
  deletePageRev,
} from "../actions";

import { useSlateState } from "../SlateProvider";

import { useLoadPage } from "./useLoadPage";

/**
 * Solve conflicts on the current open page
 */
export const useConflictResolution = () => {
  const loadPage = useLoadPage();
  const {
    editor,
    pageRevsRef,
    setValue,
    pageRevIndex,
    setPageRevIndex,
    setIsConflict,
  } = useSlateState();

  const dispatch = useDispatch();
  const pageUpdated = useSelector(
    (state: StateType) => state.noteTaking.pageUpdated
  );
  const pageId = useSelector((state: StateType) => state.noteTaking.pageId);

  const previousRevIndexRef = useRef<number>(null!);

  // if the pageId is "" then the conflict toolbar should hidden.
  useEffect(() => {
    if (pageId === "") {
      setIsConflict(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageId]);

  // Control the behaviour when current page is updated in another client (pull update)
  useEffect(() => {
    if (pageUpdated) {
      if (editor.hasChange) {
        // Save changes and reload, this could create a conflict.
        editor.hasChange = false;
        (async function saveAndLoadPage() {
          let response;

          do {
            response = await udpatePage(
              pageRevsRef.current[0],
              editor.children,
              editor.selection
            );
            if (response && response.ok === false) {
              await new Promise((resolve) => setTimeout(resolve, 500));
            }
          } while (response && response.ok === false);

          await loadPage(pageId, { clearHistory: true });
        })();

        if (editor.newTitle) {
          setNodeDataAction(pageId, {
            title: editor.newTitle,
          });
          editor.newTitle = null;
        }
      } else {
        // reload the page because was updated in another client
        loadPage(pageId);
      }
    }
    dispatch({
      type: CONFLICT_CURRENT_PAGE,
      data: {
        pageUpdated: false,
      },
    });
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageUpdated]);

  // Control the changes on the editor when viewing other page revs (slate value)
  useEffect(() => {
    // Save the current editor (Winner) on pageRevsRef
    if (previousRevIndexRef.current === 0 && pageRevIndex === 1) {
      if (pageRevsRef.current && pageRevsRef.current[0]) {
        pageRevsRef.current[0].children = editor.children;
        if (editor.selection) {
          pageRevsRef.current[0].selection = editor.selection;
        }
      }
    }

    // Load page rev on editor
    if (pageRevsRef.current && pageRevsRef.current[pageRevIndex]) {
      HistoryEditor.withoutSaving(editor, () => {
        Transforms.select(editor, {
          anchor: { path: [0, 0], offset: 0 },
          focus: { path: [0, 0], offset: 0 },
        });
        setValue(pageRevsRef.current[pageRevIndex].children);
        if (pageRevsRef.current[pageRevIndex].selection) {
          Transforms.select(
            editor,
            pageRevsRef.current[pageRevIndex].selection
          );
        }
      });
    }

    previousRevIndexRef.current = pageRevIndex;
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageRevIndex]);

  // Delete Rev, and replicate change, this is called from the conflict toolbar
  const deleteRev = () => {
    if (pageRevsRef.current[pageRevIndex]) {
      deletePageRev(pageRevsRef.current[pageRevIndex]);
    }
    pageRevsRef.current = pageRevsRef.current.filter(
      (page, index) => index !== pageRevIndex
    );

    if (pageRevsRef.current && pageRevsRef.current[pageRevIndex - 1]) {
      HistoryEditor.withoutSaving(editor, () => {
        Transforms.select(editor, {
          anchor: { path: [0, 0], offset: 0 },
          focus: { path: [0, 0], offset: 0 },
        });
        setValue(pageRevsRef.current[pageRevIndex - 1].children);
        if (pageRevsRef.current[pageRevIndex - 1].selection) {
          Transforms.select(
            editor,
            pageRevsRef.current[pageRevIndex - 1].selection
          );
        }
      });
    }

    setPageRevIndex((pageRevIndex) => pageRevIndex - 1);
    if (pageRevsRef.current.length === 1) {
      setIsConflict(false);
    }
    // if the winner was modify save the page to replicate the change also loadPage to avoid creating a new conflict, after saving.
    if (editor.hasChange) {
      (async function savePage() {
        let response;
        do {
          response = await udpatePage(
            pageRevsRef.current[0],
            pageRevsRef.current[0].children,
            pageRevsRef.current[0].selection
          );
          if (response && response.ok === false) {
            await new Promise((resolve) => setTimeout(resolve, 500));
          }
        } while (response && response.ok === false);
        loadPage(pageId);
      })();
    }
  };

  return deleteRev;
};
