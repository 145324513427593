import React from "react";
import Dialog from "@material-ui/core/Dialog";
import { EbbinLogo } from "@ebbin/components";
import CircularProgress from "@material-ui/core/CircularProgress";
import Paper from "@material-ui/core/Paper";

type LoadingDialogType = {
  open: boolean;
};
export function LoadingDialog({ open }: LoadingDialogType) {
  const handleClose = () => {};

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <Paper
        style={{
          height: "450px",
          width: "320px",
          margin: "0px",
        }}
      >
        <EbbinLogo
          style={{
            height: "200px",
            width: "200px",
            display: "block",
            marginLeft: "auto",
            marginRight: "auto",
            marginTop: "40px",
          }}
        />
        <div
          style={{
            marginTop: "50px",
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <CircularProgress
            style={{
              height: "100px",
              width: "100px",
            }}
          />
        </div>
      </Paper>
    </Dialog>
  );
}
