import { Editor } from "slate";
import isHotkey from "is-hotkey";
import { HOTKEYS_MARKS } from "./contants";
export const isMarkActive = (editor: Editor, format: string) => {
  const marks = Editor.marks(editor);
  return marks ? marks[format] === true : false;
};

export const toggleMark = (editor: Editor, format: string) => {
  const isActive = isMarkActive(editor, format);

  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
};

export interface OnKeyDownMarkOptions {
  mark: string;
  hotkey: string;
}

export const OnKeyDownMark = (event: React.KeyboardEvent<HTMLDivElement>, editor: Editor) => {
  for (const hotkey in HOTKEYS_MARKS) {
    if (event && isHotkey(hotkey, event as any)) {
      event.preventDefault();
      const mark = HOTKEYS_MARKS[hotkey];
      toggleMark(editor, mark);
    }
  }
};
