import React, { useState, useRef } from "react";

import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import IconButton from "@material-ui/core/IconButton";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => {
  return {
    menuButton: {
      height: "45px",
      width: "45px",
      borderRadius: 0,
      "&:focus": {
        background: `${theme.palette.action.selected}`,
        transition: `background-color 150ms ${theme.transitions.easing.easeInOut} 0ms`,
      },
    },
  };
});



type Props = {
  style?: React.CSSProperties 
  onLogOut?: () => void;
};

export const LayoutMoreMenu = ({style, onLogOut}:Props) => {
  const classes = useStyles();

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const anchorRef = useRef<HTMLButtonElement>(null!);

  const handleClose = (e: React.MouseEvent<any>) => {
    if (anchorRef?.current?.contains(e.target as Node)) {
      return;
    }

    setIsMenuOpen(false);
  };

const handleLogout = ()=>{
  if(onLogOut){
    onLogOut()
  }
  setIsMenuOpen(false);
}

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === "Tab" || event.key === "Escape") {
      event.preventDefault();
      setIsMenuOpen(false);
    }
  }
  return (
    <>
      <IconButton
        ref={anchorRef}
        color="inherit"
        disableRipple={true}
        aria-label="open more menu"
        className={classes.menuButton}
        onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
          e.preventDefault();
          setIsMenuOpen(!isMenuOpen);
        }}
        style={style}
      >
        <MoreVertIcon />
      </IconButton>
      <Popper
        open={isMenuOpen}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        style={{ zIndex: 1200 }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={isMenuOpen}
                  id="menu-list-grow"
                  onKeyDown={handleListKeyDown}
                >
                  <MenuItem onClick={handleClose}>Profile</MenuItem>
                  <MenuItem onClick={handleClose}>My account</MenuItem>
                  <MenuItem onClick={()=> handleLogout()}>Logout</MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};
