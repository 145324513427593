import {
  TreeSourcePosition,
  TreeDestinationPosition,
  ItemId,
} from "@atlaskit/tree";

import { localDb } from "..";

import { getId } from "@ebbin/common";

export const moveNodeAction = async (
  notebookId: string,
  source: TreeSourcePosition,
  destination: TreeDestinationPosition,
  sourceid: ItemId
) => {
  let treeActionResponse: PouchDB.Core.Response | null;
  treeActionResponse = null;
  try {
    let treeAction = {
      _id: `ta_${notebookId}_${getId()}`,
      action: "MoveTreeNode",
      data: { source: source, destination: destination },
      cd: new Date().toJSON(),
    };
    treeActionResponse = await localDb.put(treeAction);

    let page = await localDb.get(sourceid as string);
    await localDb.put({ ...page, md: new Date().toJSON() });
  } catch (error) {
    if (treeActionResponse) {
      localDb.remove(treeActionResponse.id, treeActionResponse.rev);
    }
    console.log("moveNodeAction - ERROR", error);
  }
};
