import React from "react";

import { useSlate } from "slate-react";

import { isMarkActive, toggleMark } from "@ebbin/editor";

import { ToggleButtonStyled } from "@ebbin/components";


type Props = {
  value: string;
  children: React.ReactNode;
};

export const ToggleButtonMark = ({ value, children }: Props) => {

  const editor = useSlate();
  return (
    <ToggleButtonStyled
      value={value}
      onMouseDown={(event) => {
        event.preventDefault();
        toggleMark(editor, value);
      }}
      selected={isMarkActive(editor, value)}
    >
      {children}
    </ToggleButtonStyled>
  );
};
