import { Editor } from "slate";
import { HistoryEditor } from "slate-history";
import { ReactEditor } from "slate-react";
import {
  UpdateListOLEditor,
  WithHasChangeEditor,
  ToggleBlockEditor,
  WithPageTitleEditor,
  PasteEditor,
} from ".";

export type CustomEditor = Editor &
  ReactEditor &
  PasteEditor &
  HistoryEditor &
  ToggleBlockEditor &
  UpdateListOLEditor &
  WithHasChangeEditor &
  WithPageTitleEditor &
  WithPageIdEditor;

export interface WithPageIdEditor extends ReactEditor {
  pageId: string;
}

export const PARAGRAPH = "p";
export const LINK = "a";
export const CODE = "code";
export const IMAGE = "image";
export const MARK_BOLD = "bold";
export const MARK_CODE = "code";
export const MARK_ITALIC = "italic";
export const MARK_STRIKETHROUGH = "strikethrough";
export const MARK_UNDERLINE = "underline";
export const BLOCKQUOTE = "quote";
export const QUESTION_TAG = "questionTag";
export const PIN_TAG = "pinTag";

export const MAX_INDENT = 10;

type HotkeyType = { [key: string]: string };
export const HOTKEYS_MARKS: HotkeyType = {
  "mod+b": MARK_BOLD,
  "mod+i": MARK_ITALIC,
  "mod+u": MARK_UNDERLINE,
  "mod+`": MARK_CODE,
  "mod+t": MARK_STRIKETHROUGH,
};

export enum HeadingType {
  H1 = "h1",
  H2 = "h2",
  H3 = "h3",
  H4 = "h4",
  H5 = "h5",
  H6 = "h6",
}

export enum ListType {
  OL_LIST = "ol",
  UL_LIST = "ul",
  DIV_LIST = "l",
}

export const HOTKEYS_LIST: HotkeyType = {
  "mod+.": ListType.UL_LIST,
  "mod+/": ListType.OL_LIST,
};

export const Listkeys = {
  TAB: "Tab",
  BACKSPACE: "Backspace",
};

export interface RenderElementListOptions {
  UL?: any;
  OL?: any;
  LI?: any;
}

type BlockStyles = {
  [key: string]: {
    name: string;
    fontSize: string;
    fontWeight: number;
    color: string;
    type: string;
    italic: boolean;
  };
};

export const BLOCK_STYLES: BlockStyles = {
  h1: {
    name: "Heading 1",
    fontSize: "22px",
    fontWeight: 500,
    color: "#1e4e79",
    type: "h1",
    italic: false,
  },
  h2: {
    name: "Heading 2",
    fontSize: "19px",
    fontWeight: 400,
    color: "#2e75b5",
    type: "h2",
    italic: false,
  },
  h3: {
    name: "Heading 3",
    fontSize: "17px",
    fontWeight: 400,
    color: "#1f3763",
    type: "h3",
    italic: false,
  },
  h4: {
    name: "Heading 4",
    fontSize: "17px",
    fontWeight: 400,
    color: "#2f5496",
    type: "h4",
    italic: true,
  },
  h5: {
    name: "Heading 5",
    fontSize: "15px",
    fontWeight: 400,
    color: "#2e75b5",
    type: "h5",
    italic: false,
  },
  h6: {
    name: "Heading 6",
    fontSize: "15px",
    fontWeight: 500,
    color: "#2e75b5",
    type: "h6",
    italic: true,
  },
  quote: {
    name: "quote",
    fontSize: "16px",
    fontWeight: 400,
    color: "#aaa",
    type: "quote",
    italic: true,
  },
  normal: {
    name: "normal",
    fontSize: "16px",
    fontWeight: 400,
    color: "black",
    type: "normal",
    italic: false,
  },
  code: {
    name: "code",
    fontSize: "16px",
    fontWeight: 400,
    color: "black",
    type: "code",
    italic: false,
  },
};

export const COLOR_PICKER = {
  red: "#EB144C",
  orange: "#FF6900",
  ligthGreen: "#7BDCB5",
  green: "#00D084",
  lightBlue: "#8ED1FC",
  blue: "#0693E3",
  pink: "#F78DA7",
  purple: "#9900EF",
  grey: "#ABB8C3",
  black: "#000000",
};

export const HIGHLIGHTER_PICKER = {
  yellow: "#ffeb3b",
  red: "#f47373",
  orange: "#fe9200",
  blue: "#2ccce4",
  green: "#a4dd00",
  violet: "#B44BEC",
  pink: "#f8bbd0",
};

type HTMLTagsType = {
  [key: string]: any;
};
export const ELEMENT_TAGS: HTMLTagsType = {
  A: (el: any) => ({ type: "a", url: el.getAttribute("href") }),
  BLOCKQUOTE: () => ({ type: "quote" }),
  H1: () => ({ type: "h1" }),
  H2: () => ({ type: "h2" }),
  H3: () => ({ type: "h2" }),
  H4: () => ({ type: "h4" }),
  H5: () => ({ type: "h5" }),
  H6: () => ({ type: "h6" }),
  IMG: (el: any) => {
    return {
      type: "image",
      url: el.getAttribute("src"),
      width: Number(el.getAttribute("width")),
      height: Number(el.getAttribute("height")),
    };
  },
  P: () => ({ type: "paragraph" }),
  PRE: () => ({ type: "code" }),
};
