import { useEffect, useRef } from "react";

import { useSelector } from "react-redux";
import { StateType } from "../reducer";
import {
  updateCollapsedIdsLocalDb,
  setLocalLastOpenNotebookId,
  updateLastOpenPage,
  udpatePage,
  setNodeDataAction,
} from "../actions";

import { useSlateState } from "../SlateProvider";

/**
 * Save the app state and page, before page refresh
 */
export const useBeforeUnload = () => {
  const { editor, pageRevsRef } = useSlateState();

  const collapsedIds = useSelector(
    (state: StateType) => state.noteTaking.collapsedIds
  );
  const notebookIdRef = useRef("");
  const collapsedIdsRef = useRef<string[]>([]);

  useEffect(() => {
    if (collapsedIds) {
      collapsedIdsRef.current = collapsedIds;
    }
  }, [collapsedIds]);

  useEffect(() => {
    const handleBeforeUnload = async (event: any) => {
      updateCollapsedIdsLocalDb(notebookIdRef.current, collapsedIdsRef.current);
      if (editor.hasChange) {
        editor.hasChange = false;
        /**  Uncomment the returnValue to show the message the reloading the page,
         * - This allow to save when the user refresh form the address bar
         */
        event.returnValue = `Are you sure you want to leave?`;
        (async function savePageBeforeUnload() {
          let responseSavePage;
          let notebookid = pageRevsRef.current[0]._id.substr(2, 11);
          await setLocalLastOpenNotebookId(notebookid);
          await updateLastOpenPage(notebookid, pageRevsRef.current[0]._id);
          do {
            responseSavePage = await udpatePage(
              pageRevsRef.current[0],
              editor.children,
              editor.selection
            );
            if (responseSavePage && responseSavePage.ok === false) {
              await new Promise((resolve) => setTimeout(resolve, 500));
            }
          } while (responseSavePage && responseSavePage.ok === false);

          if (editor.newTitle) {
            let { newTitle } = editor;
            setNodeDataAction(pageRevsRef.current[0]._id, {
              title: newTitle,
            });
          }
        })();
      }
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => window.removeEventListener("beforeunload", handleBeforeUnload);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};
