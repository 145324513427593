import React, { useState, useRef } from "react";
import { Editor, Transforms, Text } from "slate";
import { useSlate, ReactEditor } from "slate-react";
import { makeStyles } from "@material-ui/core/styles";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { BLOCK_STYLES } from "@ebbin/editor";
import ButtonBase from "@material-ui/core/ButtonBase";

const useStyles = makeStyles((theme) => {
  return {
    arrowIcon: {
      borderRadius: 0,
      maxWidth: "34px",
      height: "44px",
      margin: 0,
    },
    arrowButton: {
      "&:focus": {
        background: `${theme.palette.action.hover}`,
        transition: `background-color 150ms ${theme.transitions.easing.easeInOut} 0ms`,
      },
    },
    formatButton: {
      "&:hover": {
        background: `${theme.palette.action.hover}`,
        transition: `background-color 150ms ${theme.transitions.easing.easeInOut} 0ms`,
      },
    },
    container: {
      marginLeft: "8px",
      "&:focus": {
        background: `${theme.palette.action.hover}`,
        transition: `background-color 150ms ${theme.transitions.easing.easeInOut} 0ms`,
      },
    },
  };
});

export const SelectFormatBlock = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [formatState, setFormatState] = useState({
    text: BLOCK_STYLES.h1.name,
    slateType: BLOCK_STYLES.h1.type,
    style: {
      fontSize: BLOCK_STYLES.h1.fontSize,
      color: BLOCK_STYLES.h1.color,
      fontWeight: BLOCK_STYLES.h1.fontWeight,
    },
  });

  const classes = useStyles();
  const editor = useSlate();

  const anchorRef = useRef<HTMLDivElement>(null!);

  const handleClose = (e: React.MouseEvent<any>) => {
    if (anchorRef?.current?.contains(e.target as Node)) {
      return;
    }
    setIsMenuOpen(false);
    ReactEditor.focus(editor);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === "Escape") {
      event.preventDefault();
      setIsMenuOpen(false);
      ReactEditor.focus(editor);
    }
  }

  const applyFormatStyle = (
    editor: ReactEditor,
    blockStyle: string,
    color: string
  ) => {
    if (editor.selection) {
      let start = Editor.start(
        editor,
        Editor.first(editor, [editor.selection!.anchor.path[0]])[1]!
      );
      let end = Editor.end(
        editor,
        Editor.last(editor, [editor.selection!.focus.path[0]])[1]!
      );
      let range = Editor.range(editor, start, end);
      if (blockStyle !== "normal") {
        Transforms.setNodes(
          editor,
          { type: blockStyle },
          {
            at: range,
            match: (n) =>
              !Text.isText(n) &&
              !Editor.isVoid(editor, n) &&
              !Editor.isEditor(n) &&
              !Editor.isInline(editor, n),
          }
        );

        Transforms.unsetNodes(
          editor,
          [
            "color",
            "bold",
            "code",
            "italic",
            "underline",
            "strikethrough",
            "mark",
          ],
          { match: (n) => Text.isText(n), at: range }
        );

        Transforms.setNodes(
          editor,
          { color: color },
          { match: (n) => Text.isText(n), at: range }
        );

        if (BLOCK_STYLES[blockStyle].italic) {
          Transforms.setNodes(
            editor,
            { italic: true },
            { match: (n) => Text.isText(n), at: range }
          );
        }
      } else {
        Transforms.unsetNodes(editor, "type", {
          at: range,
          match: (n) =>
            !Text.isText(n) &&
            !Editor.isVoid(editor, n) &&
            !Editor.isEditor(n) &&
            !Editor.isInline(editor, n),
        });
        Transforms.unsetNodes(
          editor,
          [
            "color",
            "bold",
            "code",
            "italic",
            "underline",
            "strikethrough",
            "mark",
          ],
          { match: (n) => Text.isText(n), at: range }
        );
      }
    }
  };

  const handleOnMouseDownButton = (event: React.SyntheticEvent) => {
    event.preventDefault();
    applyFormatStyle(editor, formatState.slateType, formatState.style.color);
    ReactEditor.focus(editor);
  };

  const handleOnClickkMenuItem = (
    text: string,
    fontSize: string,
    fontWeight: number,
    color: string,
    slateType: string
  ) => {
    setIsMenuOpen(false);
    setFormatState({
      text: text,
      slateType: slateType,
      style: { fontSize: fontSize, color: color, fontWeight: fontWeight },
    });
    applyFormatStyle(editor, slateType, color);
    ReactEditor.focus(editor);
  };

  return (
    <>
      <div
        ref={anchorRef}
        style={{ display: "flex", marginRight: "8px" }}
        onKeyDown={handleListKeyDown}
      >
        <ButtonBase
          onClick={(event) => handleOnMouseDownButton(event)}
          className={classes.container}
        >
          <Box
            height={46}
            width={166}
            border={1}
            borderColor="grey.300"
            className={classes.formatButton}
            display="flex"
          >
            <Typography
              style={{
                alignSelf: "center",
                paddingLeft: "10px",
                fontSize: `${formatState.style.fontSize}`,
                color: `${formatState.style.color}`,
                fontWeight: formatState.style.fontWeight,
                MozUserSelect: "none",
                WebkitUserSelect: "none",
                msUserSelect: "none",
              }}
            >
              {formatState.text}
            </Typography>
          </Box>
        </ButtonBase>
        <Box border={1} borderColor="grey.300">
          <IconButton
            classes={{ root: classes.arrowIcon }}
            color="inherit"
            aria-label="open format menu"
            onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
              e.preventDefault();
              setIsMenuOpen(!isMenuOpen);
            }}
            className={classes.arrowButton}
            disableRipple={true}
          >
            <KeyboardArrowDownIcon />
          </IconButton>
        </Box>
      </div>
      <Popper
        open={isMenuOpen}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        style={{ zIndex: 1200 }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={isMenuOpen}
                  id="menu-list-grow"
                  onKeyDown={handleListKeyDown}
                >
                  <FormatBlockMenuItem
                    pb={0}
                    OnClickMenuItem={handleOnClickkMenuItem}
                    styleBlockType="normal"
                  />
                  <FormatBlockMenuItem
                    pb={0}
                    OnClickMenuItem={handleOnClickkMenuItem}
                    styleBlockType="h1"
                  />
                  <FormatBlockMenuItem
                    pb={0}
                    OnClickMenuItem={handleOnClickkMenuItem}
                    styleBlockType="h2"
                  />
                  <FormatBlockMenuItem
                    pb={0}
                    OnClickMenuItem={handleOnClickkMenuItem}
                    styleBlockType="h3"
                  />
                  <FormatBlockMenuItem
                    pb={0}
                    OnClickMenuItem={handleOnClickkMenuItem}
                    styleBlockType="h4"
                  />
                  <FormatBlockMenuItem
                    pb={0}
                    OnClickMenuItem={handleOnClickkMenuItem}
                    styleBlockType="h5"
                  />
                  <FormatBlockMenuItem
                    pb={0}
                    OnClickMenuItem={handleOnClickkMenuItem}
                    styleBlockType="h6"
                  />
                  <FormatBlockMenuItem
                    pb={0}
                    OnClickMenuItem={handleOnClickkMenuItem}
                    styleBlockType="quote"
                  />
                  <FormatBlockMenuItem
                    pb={0}
                    OnClickMenuItem={handleOnClickkMenuItem}
                    styleBlockType="code"
                  />
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export const FormatBlockMenuItem = (props: any) => {
  let { styleBlockType, pb, blockquote, OnClickMenuItem, ...menuPros } = props;

  let { name, fontSize, fontWeight, color, type, italic } = BLOCK_STYLES[
    styleBlockType
  ];

  return (
    <MenuItem
      {...menuPros}
      onKeyDown={(e: any) => {
        if (e.key === "Enter") {
          OnClickMenuItem(name, fontSize, fontWeight, color, type);
        }
      }}
      onClick={() => OnClickMenuItem(name, fontSize, fontWeight, color, type)}
    >
      <ButtonBase disableRipple={true}>
        <Box display="flex" alignItems="flex-end" css={{ height: 44 }}>
          <Box pl={"5px"} pb={pb}>
            <Typography
              style={{
                fontSize: `${fontSize}`,
                color: `${color}`,
                fontWeight: fontWeight,
                fontStyle: italic ? "italic" : "normal",
                borderLeft: blockquote ? "2px solid #ddd" : "",
                paddingLeft: blockquote ? "10px" : "",
                fontFamily: type === "code" ? "monospace" : "",
              }}
            >
              {name}
            </Typography>
          </Box>
        </Box>
      </ButtonBase>
    </MenuItem>
  );
};
