import { Editor, Transforms } from "slate";

export interface ToggleBlockEditor extends Editor {
  toggleBlock: (format: string) => void;
  toggleCode: (format: string) => void;
}

/**
 * Add editor.toggleBlock change the node type
 */
export const withBlock = <T extends Editor>(editor: T) => {
  const e = editor as T & ToggleBlockEditor;

  e.toggleBlock = (format: string) => {
    const isActive = isBlockType(e, format);
    if (isActive) {
      Transforms.unsetNodes(e, "type");
    } else {
      Transforms.setNodes(e, {
        type: format,
      });
    }
  };

  return e;
};

/**
 * Does the selection contain a node of this type
 */
export const isBlockType = (editor: Editor, format: string) => {
  const [match] = Editor.nodes(editor, {
    match: (n) => n.type === format,
    mode: "all",
  });

  return !!match;
};
