import React from "react";
import { RenderElementProps } from "slate-react";
import dot from "./assets/dot.svg";
import { useLayoutState } from "@ebbin/components";
/**
 * Create a Unordered List that is indentable
 */
export const ListUnordered = ({
  attributes,
  children,
  element,
}: RenderElementProps) => {
  const { mobileView } = useLayoutState();

  let marginLeftPx = element.indent
    ? mobileView
      ? 25
      : 40
    : mobileView
    ? 28
    : 32;

  let marginLeft = element.indent
    ? mobileView
      ? (element.indent as number) * marginLeftPx -
        marginLeftPx -
        5 * (element.indent as number)
      : (element.indent as number) * marginLeftPx - marginLeftPx
    : mobileView
    ? -28
    : -32;

  return (
    <ul
      style={{
        padding: 0,
        marginLeft: `${marginLeft}px`,
        marginTop: 0,
        marginBottom: 0,

        listStyle: "none",
        listStylePosition: "outside",
      }}
      {...attributes}
      data-ebbin-type="list-ul"
      data-ebbin-indent={element.indent}
    >
      <li
        style={{
          // listStyleType: "none",
          backgroundImage: `url(${dot})`,
          backgroundRepeat: "no-repeat",
          backgroundPositionY: "center",
          backgroundSize: "35px 35px",
          paddingLeft: `${marginLeftPx}px`,
        }}
      >
        {children}
      </li>
    </ul>
  );
};
