import React, { useState } from "react";
import { createCtx } from "../hooks";
import useMediaQuery from "@material-ui/core/useMediaQuery";

type LayoutContext = {
  isSidebarOpen: boolean;
  setIsSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
  maxPageWidth: number;
  setMaxPageWidth: React.Dispatch<React.SetStateAction<number>>;
  mobileView: boolean;
};

export const [useLayoutState, LayoutStateCtxProvider] = createCtx<LayoutContext>();

type Props = {
  children: React.ReactNode;
};

export const LayoutProvider = ({ children }: Props) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [maxPageWidth, setMaxPageWidth] = useState(0);
  const mobileView = useMediaQuery("(max-width:600px)");

  return (
    <LayoutStateCtxProvider
      value={{
        isSidebarOpen: isSidebarOpen,
        setIsSidebarOpen: setIsSidebarOpen,
        maxPageWidth: maxPageWidth,
        setMaxPageWidth: setMaxPageWidth,
        mobileView: mobileView,
      }}
    >
      {children}
    </LayoutStateCtxProvider>
  );
};
