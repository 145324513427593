import React from "react";

import { useDispatch } from "react-redux";
import { renameNotebook } from "../actions";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import Edit from "@material-ui/icons/Edit";
import { ToggleButtonStyled } from "@ebbin/components";


export const NotebookRenameButton = ({ notebook }: any) => {
  const dispatch = useDispatch()
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState(notebook.name);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleRenameNotebook = () => {
    if (value) {
     dispatch(renameNotebook(notebook._id, value))
     setOpen(false);
    }
  };

  const handleChange = (event: any) => {
    setValue(event.target.value);
  };

  return (
    <>
      <ToggleButtonStyled
        disabled={false}
        value="test"
        onClick={handleClickOpen}
      >
        <Edit />
      </ToggleButtonStyled>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Rename Notebook</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            defaultValue={notebook.name}
            type="text"
            fullWidth
            onChange={handleChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleRenameNotebook} color="primary">
            Rename
          </Button>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
