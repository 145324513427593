import { Auth } from "aws-amplify";

export async function signUp(name: string, password: string, email: string) {
  try {
    let username = email;
    const user = await Auth.signUp({
      username,
      password,
      attributes: {
        name,
      },
    });
    console.log({ user });
    return user;
  } catch (error) {
    console.log("error signing up:", error);
  }
}

export async function confirmSignUp(email: string, code: string) {
  let username = email;
  try {
    let response = await Auth.confirmSignUp(username, code);
    return response;
  } catch (error) {
    console.log("error confirming sign up", error);
    return error;
  }
}

export async function resendConfirmationCode(username: string) {
  try {
    let response = await Auth.resendSignUp(username);
    return response;
  } catch (err) {
    console.log("error resending code: ", err);
  }
}

export async function signOut() {
  try {
    await Auth.signOut();
  } catch (error) {
    console.log("error signing out: ", error);
  }
}

export async function signIn(username: string, password: string) {
  try {
    const user = await Auth.signIn(username, password);
    return user;
  } catch (error) {
    console.log("error signing in", error);
    return error;
  }
}
