import React, { useState, useRef } from "react";
import { Editor, Transforms, Text, Range } from "slate";
import { useSlate, ReactEditor } from "slate-react";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";

import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import ButtonBase from "@material-ui/core/ButtonBase";
import Box from "@material-ui/core/Box";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";

import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { HIGHLIGHTER_PICKER } from "@ebbin/editor";
import { HighligherIcon } from "@ebbin/components";

const useStyles = makeStyles((theme) => {
  return {
    arrowIcon: {
      borderRadius: 0,
      maxWidth: "15px",
      height: "40px",
      margin: 0,
      padding: 0,
    },
    arrowButton: {
      color: "#969696",
      "&:focus": {
        background: `${theme.palette.action.selected}`,
        transition: `background-color 150ms ${theme.transitions.easing.easeInOut} 0ms`,
      },
    },
    colorButton: {
      borderRadius: 0,
      height: "40px",

      padding: 0,
      paddingLeft: "8px",

      "&:focus": {
        background: `${theme.palette.action.selected}`,
        transition: `background-color 150ms ${theme.transitions.easing.easeInOut} 0ms`,
      },
    },
    icons: {
      width: "25px",
      height: "25px",
    },
  };
});

export const HighlighterPicker = () => {
  const editor = useSlate();
  const classes = useStyles();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [colorIcon, setColorIcon] = useState(HIGHLIGHTER_PICKER.yellow);

  const anchorRef = useRef<HTMLDivElement>(null!);
  const popperRef = useRef<any>(null!);

  const handleClose = (e: React.MouseEvent<any>) => {
    if (anchorRef?.current?.contains(e.target as Node)) {
      return;
    }
    setIsMenuOpen(false);
  };

  const applyColor = (color: string) => {
    if (editor.selection && Range.isCollapsed(editor.selection as Range)) {
      Editor.addMark(editor, "mark", color);
    }
    if (editor.selection && !Range.isCollapsed(editor.selection as Range)) {
      Transforms.setNodes(
        editor,
        { mark: color },
        {
          match: (n) => Text.isText(n),
          split: true,
        }
      );
    }
  };

  const removeColor = () => {
    if (editor.selection) {
      Transforms.unsetNodes(editor, ["mark"], {
        match: (n) => Text.isText(n),
      });
    }
    setIsMenuOpen(false);
  };

  const handleOnClickColor = (event: React.SyntheticEvent, color: string) => {
    setIsMenuOpen(false);
    ReactEditor.focus(editor);
    setColorIcon(color);
    applyColor(color);
  };

  const handleOnClickButtonColor = (color: string) => {
    ReactEditor.focus(editor);
    applyColor(color);
    setIsMenuOpen(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === "Enter") {
      event.preventDefault();
      setIsMenuOpen(true);

      setTimeout(() => {
        popperRef.current.focusVisible();
      }, 100);
    }
  }

  return (
    <>
      <div ref={anchorRef} style={{ display: "flex", alignItems: "center" }}>
        <IconButton
          classes={{ root: classes.colorButton }}
          color="inherit"
          aria-label="highlighter button"
          onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            e.preventDefault();
            handleOnClickButtonColor(colorIcon);
          }}
          disableFocusRipple={true}
        >
          <Box style={{ fontSize: "10px", color: "#969696", marginTop: "4px" }}>
            <HighligherIcon alt="" className={classes.icons} />

            <Box height="5px" width="24px" bgcolor={colorIcon}></Box>
          </Box>
        </IconButton>
        <Box height="40px" pr={1}>
          <IconButton
            classes={{ root: classes.arrowIcon }}
            color="inherit"
            aria-label="open highlighter menu"
            onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
              e.preventDefault();
              setIsMenuOpen(!isMenuOpen);
            }}
            onKeyDown={(event) => handleListKeyDown(event)}
            className={classes.arrowButton}
            disableRipple={true}
            size="small"
          >
            <KeyboardArrowDownIcon />
          </IconButton>
        </Box>
      </div>

      <Popper
        open={isMenuOpen}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        style={{ zIndex: 1200 }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <Paper>
                  <Box>
                    <ColorButton
                      action={popperRef}
                      bgcolor={HIGHLIGHTER_PICKER.yellow}
                      onClick={(
                        event: React.MouseEvent<HTMLButtonElement, MouseEvent>
                      ) => {
                        handleOnClickColor(event, HIGHLIGHTER_PICKER.yellow);
                      }}
                      className={classes.arrowButton}
                    />

                    <ColorButton
                      bgcolor={HIGHLIGHTER_PICKER.red}
                      onClick={(
                        event: React.MouseEvent<HTMLButtonElement, MouseEvent>
                      ) => {
                        handleOnClickColor(event, HIGHLIGHTER_PICKER.red);
                      }}
                      className={classes.arrowButton}
                    />
                    <ColorButton
                      bgcolor={HIGHLIGHTER_PICKER.orange}
                      onClick={(
                        event: React.MouseEvent<HTMLButtonElement, MouseEvent>
                      ) => {
                        handleOnClickColor(event, HIGHLIGHTER_PICKER.orange);
                      }}
                      className={classes.arrowButton}
                    />
                    <ColorButton
                      bgcolor={HIGHLIGHTER_PICKER.blue}
                      onClick={(
                        event: React.MouseEvent<HTMLButtonElement, MouseEvent>
                      ) => {
                        handleOnClickColor(event, HIGHLIGHTER_PICKER.blue);
                      }}
                      className={classes.arrowButton}
                    />
                  </Box>
                  <Box>
                    <ColorButton
                      bgcolor={HIGHLIGHTER_PICKER.green}
                      onClick={(
                        event: React.MouseEvent<HTMLButtonElement, MouseEvent>
                      ) => {
                        handleOnClickColor(event, HIGHLIGHTER_PICKER.green);
                      }}
                      className={classes.arrowButton}
                    />
                    <ColorButton
                      bgcolor={HIGHLIGHTER_PICKER.violet}
                      onClick={(
                        event: React.MouseEvent<HTMLButtonElement, MouseEvent>
                      ) => {
                        handleOnClickColor(event, HIGHLIGHTER_PICKER.violet);
                      }}
                      className={classes.arrowButton}
                    />
                    <ColorButton
                      bgcolor={HIGHLIGHTER_PICKER.pink}
                      onClick={(
                        event: React.MouseEvent<HTMLButtonElement, MouseEvent>
                      ) => {
                        handleOnClickColor(event, HIGHLIGHTER_PICKER.pink);
                      }}
                      className={classes.arrowButton}
                      onKeyDown={(event: React.KeyboardEvent<any>) => {
                        event.preventDefault();
                        if (event.key === "Tab") {
                          popperRef.current.focusVisible();
                        }
                      }}
                    />
                    <ButtonBase
                      onClick={(
                        event: React.MouseEvent<HTMLButtonElement, MouseEvent>
                      ) => {
                        removeColor();
                      }}
                      className={classes.arrowButton}
                    >
                      <Box
                        height="24px"
                        width="24px"
                        m={1}
                        style={{ color: "#969696" }}
                      >
                        <HighlightOffIcon />
                      </Box>
                    </ButtonBase>
                  </Box>
                </Paper>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

// Todo: this whole component can be improve if we can extend the ButtonBase props using TypeScript, I have to use any for now.
const ColorButton = (props: any) => {
  let { bgcolor } = props;
  return (
    <ButtonBase {...props}>
      <Box height="24px" width="24px" bgcolor={bgcolor} m={1}></Box>
    </ButtonBase>
  );
};
