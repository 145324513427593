import {
  SET_INITIAL_STATE,
  ADD_NOTEBOOK,
  OPEN_NOTEBOOK,
  DELETE_NOTEBOOK,
  RENAME_NOTEBOOK,
  ADD_PAGE,
  OPEN_PAGE,
  DELETE_PAGE,
  UPDATE_TREEDATA,
  ADD_COLLAPSED_ID,
  REMOVE_COLLAPSED_ID,
  CONFLICT_CURRENT_PAGE,
  PULL_PAGES,
} from "./actions";

import { TreeData } from "./sideMenu/types";

export type NoteTakingStateType = {
  notebooks: any[];
  notebookId: string;
  pageId: string;
  pageUpdated: boolean;
  treeData: TreeData | null;
  collapsedIds: string[];
  pullPages: string[];
};

export type StateType = {
  noteTaking: NoteTakingStateType;
};

const initialState: NoteTakingStateType = {
  notebookId: "",
  pageId: "",
  pageUpdated: false,
  notebooks: [],
  treeData: null,
  collapsedIds: [],
  pullPages: [],
};
type NoteTakingActionType = {
  type:
    | typeof SET_INITIAL_STATE
    | typeof ADD_NOTEBOOK
    | typeof OPEN_NOTEBOOK
    | typeof DELETE_NOTEBOOK
    | typeof RENAME_NOTEBOOK
    | typeof ADD_PAGE
    | typeof OPEN_PAGE
    | typeof DELETE_PAGE
    | typeof UPDATE_TREEDATA
    | typeof ADD_COLLAPSED_ID
    | typeof REMOVE_COLLAPSED_ID
    | typeof CONFLICT_CURRENT_PAGE
    | typeof PULL_PAGES;
  data: Partial<NoteTakingStateType>;
};

export const noteTaking = (
  state: NoteTakingStateType = initialState,
  action: NoteTakingActionType
) => {
  switch (action.type) {
    case SET_INITIAL_STATE:
      return { ...state, notebooks: action.data.notebooks };
    case ADD_NOTEBOOK:
      return {
        ...state,
        notebooks: action.data.notebooks,
      };
    case OPEN_NOTEBOOK:
      return {
        ...state,
        notebookId: action.data.notebookId,
        pageId: action.data.pageId,
        treeData: action.data.treeData,
      };
    case DELETE_NOTEBOOK:
      if (action.data.notebookId === "") {
        return {
          ...state,
          notebooks: action.data.notebooks,
          notebookId: action.data.notebookId,
          pageId: action.data.pageId,
          treeData: action.data.treeData,
        };
      } else {
        return { ...state, notebooks: action.data.notebooks };
      }
    case RENAME_NOTEBOOK:
      return { ...state, notebooks: action.data.notebooks };
    case OPEN_PAGE:
      return { ...state, pageId: action.data.pageId };
    case DELETE_PAGE:
      return { ...state, pageId: action.data.pageId };
    case UPDATE_TREEDATA:
      return { ...state, treeData: action.data.treeData };
    case ADD_COLLAPSED_ID:
      let collapsedIds_Add = [...state.collapsedIds, action.data.pageId];
      return { ...state, collapsedIds: [...collapsedIds_Add] };
    case REMOVE_COLLAPSED_ID:
      let collapsedIds_Remove = state.collapsedIds.filter(
        (id) => id !== action.data.pageId
      );
      return { ...state, collapsedIds: [...collapsedIds_Remove] };
    case CONFLICT_CURRENT_PAGE:
      return {
        ...state,
        pageUpdated: action.data.pageUpdated,
      };
    case PULL_PAGES:
      return {
        ...state,
        pullPages: action.data.pullPages,
      };
    default:
      return state;
  }
};
