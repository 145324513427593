import { AppThunk } from "../../../index";

import { storage } from "@ebbin/storage";
import { removeItem } from "../../sideMenu/treeUtil";
import { DELETE_PAGE, UPDATE_TREEDATA, localDb } from "../";
import { Question, getId } from "@ebbin/common";

/** Allow to delete one or multiple pages */
export const deletePageDb = async (deletedPageIds: string[]) => {
  try {
    for (let id of deletedPageIds) {
      let doc = await localDb.get(id);
      await localDb.remove(doc);
    }
  } catch (error) {
    console.log("deletePage - ERROR", error);
  }
};

/** If the delete page is currently open it will set pageId to "" */
export const deletePage = (pageId: string): AppThunk => async (
  dispatch,
  getState
) => {
  //deletePage
  let notebookId = getState().noteTaking.notebookId;
  let treeData = getState().noteTaking.treeData;

  let [newTreeData, deletedPageIds] = removeItem(treeData!, pageId);

  let currentPageId = getState().noteTaking.pageId;

  let isOpenPageDeleted = deletedPageIds.includes(currentPageId);

  let treeActionResponse: PouchDB.Core.Response | null;
  treeActionResponse = null;

  try {
    for (let id of deletedPageIds) {
      let doc = await localDb.get(id);
      await localDb.remove(doc);
    }
    let treeAction = {
      _id: `ta_${notebookId}_${getId()}`,
      action: "RemoveTreeNode",
      data: { pageId: pageId },
      cd: new Date().toJSON(),
    };
    treeActionResponse = await localDb.put(treeAction);
  } catch (error) {
    // if (treeActionResponse) {
    //   localDb.remove(treeActionResponse.id, treeActionResponse.rev);
    // }
    console.log("deletePage - ERROR01", error);
  }

  if (isOpenPageDeleted) {
    dispatch({
      type: DELETE_PAGE,
      data: { pageId: "" },
    });
  }

  dispatch({
    type: UPDATE_TREEDATA,
    data: {
      treeData: newTreeData,
    },
  });

  storage?.removeFolder(`${notebookId}/${pageId}/`);
  deletePageAllQuestions(pageId);
  return;
};

const deletePageAllQuestions = async (pageId: string) => {
  let questionsPrefix = `q_${pageId}`;
  try {
    let dbQuestions = await localDb.allDocs<Question>({
      startkey: `${questionsPrefix}`,
      endkey: `${questionsPrefix}\uffff`,
    });
    dbQuestions.rows.forEach(async (question) => {
      await localDb.remove(question.id, question.value.rev);
    });
  } catch (error) {
    console.log("deletePageAllQuestions - error", error);
  }
};
