import React, { useEffect } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import { useLayoutState } from "./LayoutState";
import Scrollbar from "react-scrollbars-custom";
import { useMeasure } from "./useMeasure";
let marginTop = 142;
const useStyles = makeStyles((theme) => {
  return {
    content: {
      flexGrow: 3,
      height: "100%",
      overflowY: "auto",

      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    contentShift: {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      [theme.breakpoints.up("sm")]: {
        marginLeft: theme.sidebar.width,
      },
    },
    trackX: {
      background: "#fafafa !important",
    },
    scrollbar: {
      width: "100%",
    },
    contentBox: {
      background: "white",
      minHeight: `calc(96vh - ${marginTop}px)`,
      flex: "auto",
      maxWidth: "900px",
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        padding: "40px",
        marginTop: "15px",
        marginBottom: "15px",
      },
      [theme.breakpoints.only("xs")]: {
        padding: theme.spacing(3),
        // width:"100%"
      },
    },
    contentMargin: {
      [theme.breakpoints.up("sm")]: {
        padding: "40px",
        marginTop: "15px",
        marginBottom: "15px",
      },
    },
    contentMarginSmall: {
      [theme.breakpoints.between("sm", 1200)]: {
        padding: "40px 15px 40px 15px",
        marginTop: "15px",
        marginBottom: "15px",
      },
    },
    editorBox: {
      [theme.breakpoints.up("sm")]: {
        maxWidth: "800px",
        height: "100%",
      },
    },
  };
});

type Props = {
  children: React.ReactNode;
};

export const LayoutContent = ({ children }: Props) => {
  const { isSidebarOpen, setMaxPageWidth } = useLayoutState();
  const classes = useStyles();
  const [{ ref }, containerBounds] = useMeasure();
  useEffect(() => {
    if (containerBounds.width) {
      setMaxPageWidth(containerBounds.width);
    }
    return () => {};
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [containerBounds]);
  return (
    <div
      className={clsx(classes.content, {
        [classes.contentShift]: isSidebarOpen,
      })}
    >
      <Scrollbar
        mobileNative={true}
        noScrollX={true}
        removeTracksWhenNotUsed={true}
        trackXProps={{ className: `${classes.trackX}` }}
        className={classes.scrollbar}
      >
        <Box display="flex" justifyContent="center">
          <Box
            className={clsx(
              classes.contentBox,
              isSidebarOpen ? classes.contentMarginSmall : classes.contentMargin
            )}
            boxShadow={3}
          >
            <div
              ref={ref as React.MutableRefObject<HTMLDivElement | null>}
              className={classes.editorBox}
            >
              {children}
            </div>
          </Box>
        </Box>
      </Scrollbar>
    </div>
  );
};
