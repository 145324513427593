import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { Transforms, Element } from "slate";
import { useFocused, useSelected, useEditor, ReactEditor } from "slate-react";
import { makeStyles } from "@material-ui/core/styles";
import { Resizable } from "re-resizable";

import { useLayoutState } from "@ebbin/components";

import Skeleton from "@material-ui/lab/Skeleton";

import imageExtensions from "image-extensions";
import isUrl from "is-url";
import { useSlateState } from "../../noteTaking/SlateProvider";
import { CustomEditor, IMAGE } from "@ebbin/editor";

const useStyles = makeStyles((theme) => {
  return {
    image: {
      width: "100%",
      height: "100%",
    },
    hide: {
      display: "none",
    },
    imageSelected: {
      outline: `${theme.palette.action.active} dashed 1px`,
    },
    imageHighlight: {
      boxShadow: "5px 5px #d6f0ff",
    },
  };
});

export interface ElementImage extends Element {
  width: number;
  height: number;
  s3?: string;
  url?: string;
  indent?: number;
}

type Props = {
  children: React.ReactNode;
  element: ElementImage;
};

export const isImageUrl = (url: string) => {
  url = url.toLowerCase();
  if (!url) return false;
  if (!isUrl(url)) return false;
  const ext = new URL(url).pathname.split(".").pop();
  if (!ext) return false;

  return imageExtensions.includes(ext);
};

export const ImageElement = ({ children, element }: Props) => {
  const classes = useStyles();
  const selected = useSelected();
  const focused = useFocused();
  const editor = useEditor() as CustomEditor;
  const { mobileView, maxPageWidth } = useLayoutState();
  const { questionHighlight } = useSlateState();

  const [highlight, setHighlight] = useState(false);
  const [url, setUrl] = useState(element.url);
  const [hideImage, setHideImage] = useState(true);
  const [width, setWidth] = React.useState(element.width);
  const [height, setHeight] = React.useState(element.height);
  const [maxContainerwidth, setMaxContainerwidth] = useState(
    element.indent
      ? maxPageWidth - element.indent * (mobileView ? 20 : 40)
      : maxPageWidth
  );

  const saveElementBounds = (width: number, height: number) => {
    if (width !== 0 && height !== 0) {
      let path = ReactEditor.findPath(editor, element);
      Transforms.setNodes(
        editor,
        {
          width: width,
          height: height,
        },
        { at: path }
      );
    }
  };

  // Calculate the maxContainerwidth
  useEffect(() => {
    setMaxContainerwidth(
      element.indent
        ? maxPageWidth - element.indent * (mobileView ? 20 : 40)
        : maxPageWidth
    );
    return () => {};
  }, [maxPageWidth, element.indent, mobileView]);

  // Base on changes on element or maxPageWidth, set the image width and height
  useEffect(() => {
    if (maxContainerwidth) {
      if (element.width < maxContainerwidth) {
        setWidth(element.width);
        setHeight(element.height);
      } else {
        let newHeight = (element.height * maxContainerwidth) / element.width;
        setWidth(maxContainerwidth);
        setHeight(newHeight);
      }
    }
    return () => {};
  }, [element, maxContainerwidth]);

  return (
    <>
      {maxPageWidth ? (
        <div
          style={{ marginTop: "2px", marginBottom: "2px" }}
          data-slate-type={IMAGE}
          onCopy={(e) => {
            e.preventDefault();
            e.stopPropagation();
            e.clipboardData.setData(
              "text/html",
              `<img src=${url} width=${width} height=${height} />`
            );
          }}
          onCut={(e) => {
            e.preventDefault();
            e.stopPropagation();
            e.clipboardData.setData(
              "text/html",
              `<img src=${url} width=${width} height=${height} />`
            );
            let path = ReactEditor.findPath(editor, element);
            Transforms.removeNodes(editor, { at: path });
          }}
        >
          <div contentEditable={false}>
            <Resizable
              lockAspectRatio={true}
              maxWidth={maxContainerwidth}
              size={{ width, height }}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                border: "2px solid white",
              }}
              onResizeStop={(e, direction, ref, d) => {
                setWidth(width + d.width);
                setHeight(height + d.height);
                saveElementBounds(width + d.width, height + d.height);
              }}
              enable={
                mobileView
                  ? ResizableDisabled
                  : selected && focused
                  ? ResizableEnabled
                  : ResizableDisabled
              }
              className={clsx(selected && focused && classes.imageSelected)}
            >
              {hideImage ? (
                <Skeleton variant="rect" width={width} height={height} />
              ) : null}
              <img
                src={url}
                alt=""
                className={clsx(
                  classes.image,
                  highlight && classes.imageHighlight,
                  hideImage && classes.hide
                )}
                onLoad={() => setHideImage(false)}
                width={width}
                height={height}
              />
            </Resizable>
          </div>
          {children}
        </div>
      ) : null}
    </>
  );
};

const ResizableEnabled = {
  top: false,
  right: false,
  bottom: false,
  left: false,
  topRight: true,
  bottomRight: true,
  bottomLeft: true,
  topLeft: true,
};

const ResizableDisabled = {
  top: false,
  right: false,
  bottom: false,
  left: false,
  topRight: false,
  bottomRight: false,
  bottomLeft: false,
  topLeft: false,
};
