import { AppThunk } from "../../../index";
import { TreeData } from "@atlaskit/tree";
import {
  UPDATE_TREEDATA,
  mergeTreeDatawithLocalCollapsedIds,
} from "..";


/** Update TreeData on the state, after merging the treeData with Colapsed Ids in _local */
export const updateTreeData = (treeData: TreeData): AppThunk => async (
  dispatch,
  getState
) => {
  let collapsedIds = getState().noteTaking.collapsedIds
  
  let newTreeData = await mergeTreeDatawithLocalCollapsedIds(
    collapsedIds,
    treeData
  );

  dispatch({
    type: UPDATE_TREEDATA,
    data: {
      treeData: newTreeData,
    },
  });
};
