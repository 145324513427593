import React from "react";
import { RenderElementProps } from "slate-react";
import { makeStyles } from "@material-ui/core/styles";

import { ImageElement, ElementImage, } from "./components";

import {
  IMAGE,
  BLOCK_STYLES,
  LINK,


  List,

} from "@ebbin/editor";

const useStyles = makeStyles((theme) => {
  return {
    blockquote: {
      borderLeft: "2px solid #ddd",
      padding: "10px 20px 10px 16px",
      color: "#aaa",
      margin: "8px 0",
    },
    code: {
      marginBlockStart: 0,
      marginBlockEnd: 0,
    },
    ul: {
      paddingInlineStart: "24px",
      marginBlockStart: 0,
      marginBlockEnd: 0,
    },
    ol: {
      paddingInlineStart: "24px",
      marginBlockStart: 0,
      marginBlockEnd: 0,
    },
    h1: {
      margin: "2px 0px 2px 0px",
      fontSize: BLOCK_STYLES.h1.fontSize,
      fontWeight: BLOCK_STYLES.h1.fontWeight,
    },
    h2: {
      margin: "2px 0px 2px 0px",
      fontSize: BLOCK_STYLES.h2.fontSize,
      fontWeight: BLOCK_STYLES.h2.fontWeight,
    },
    h3: {
      margin: 0,
      fontSize: BLOCK_STYLES.h3.fontSize,
      fontWeight: BLOCK_STYLES.h3.fontWeight,
    },
    h4: {
      margin: 0,
      fontSize: BLOCK_STYLES.h4.fontSize,
      fontWeight: BLOCK_STYLES.h4.fontWeight,
    },
    h5: {
      margin: 0,
      fontSize: BLOCK_STYLES.h5.fontSize,
      fontWeight: BLOCK_STYLES.h5.fontWeight,
    },
    h6: {
      margin: 0,
      fontSize: BLOCK_STYLES.h6.fontSize,
      fontWeight: BLOCK_STYLES.h6.fontWeight,
    },
    a: {
      color: "blue",
      "&:hover": {
        cursor: "pointer",
      },
    },
  };
});

export interface LinkElement extends Element {
  url: string;
}

export const Element = ({
  attributes,
  children,
  element,
}: RenderElementProps) => {
  const classes = useStyles();
  switch (element.type) {
    case BLOCK_STYLES.quote.type:
      return (
        <List attributes={attributes} element={element}>
          <blockquote className={classes.blockquote}>{children}</blockquote>
        </List>
      );
    case BLOCK_STYLES.code.type:
      return (
        <List attributes={attributes} element={element}>
          <pre className={classes.code} style={{ whiteSpace: "pre-wrap" }}>
            <code>{children}</code>
          </pre>
        </List>
      );
    case BLOCK_STYLES.h1.type:
      return (
        <List attributes={attributes} element={element}>
          <h1 className={classes.h1}>{children}</h1>
        </List>
      );
    case BLOCK_STYLES.h2.type:
      return (
        <List attributes={attributes} element={element}>
          <h2 className={classes.h2}>{children}</h2>
        </List>
      );
    case BLOCK_STYLES.h3.type:
      return (
        <List attributes={attributes} element={element}>
          <h3 className={classes.h3}>{children}</h3>
        </List>
      );
    case BLOCK_STYLES.h4.type:
      return (
        <List attributes={attributes} element={element}>
          <h4 className={classes.h4}>{children}</h4>
        </List>
      );
    case BLOCK_STYLES.h5.type:
      return (
        <List attributes={attributes} element={element}>
          <h5 className={classes.h5}>{children}</h5>
        </List>
      );
    case BLOCK_STYLES.h6.type:
      return (
        <List attributes={attributes} element={element}>
          <h6 className={classes.h6}>{children}</h6>
        </List>
      );
    case IMAGE:
      return (
        <List attributes={attributes} element={element}>
          <ImageElement element={element as ElementImage}>
            {children}
          </ImageElement>
        </List>
      );
    case LINK:
      return (
        <a
          {...attributes}
          href={element.url as string}
          className={classes.a}
          onMouseDown={() => window.open(element.url as string, "_blank")}
        >
          {children}
        </a>
      );
    default:
      return (
        <List attributes={attributes} element={element}>
          {children}
        </List>
      );
  }
};
