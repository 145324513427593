import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { Element } from "slate";
import { IMAGE } from "../contants";
import { makeStyles } from "@material-ui/core/styles";

import { useLayoutState } from "@ebbin/components";

import Skeleton from "@material-ui/lab/Skeleton";

import { storage } from "@ebbin/storage";

const useStyles = makeStyles((theme) => {
  return {
    hide: {
      display: "none",
    },
    imageSelected: {
      outline: `${theme.palette.action.active} dashed 1px`,
    },
  };
});

export interface ElementImage extends Element {
  width: number;
  height: number;
  s3?: string;
  url?: string;
  indent?: number;
}

type Props = {
  children: React.ReactNode;
  element: ElementImage;
};

export const ImageElement = ({ children, element }: Props) => {
  const classes = useStyles();
  const { mobileView, maxPageWidth } = useLayoutState();

  const [url, setUrl] = useState("");
  const [hideImage, setHideImage] = useState(true);
  const [width, setWidth] = React.useState(element.width);
  const [height, setHeight] = React.useState(element.height);
  const [maxContainerwidth, setMaxContainerwidth] = useState(
    element.indent
      ? maxPageWidth - element.indent * (mobileView ? 20 : 40)
      : maxPageWidth
  );

  // Calculate the maxContainerwidth
  useEffect(() => {
    setMaxContainerwidth(
      element.indent
        ? maxPageWidth - element.indent * (mobileView ? 20 : 40)
        : maxPageWidth
    );
    return () => {};
  }, [maxPageWidth, element.indent, mobileView]);

  // Base on changes on element or maxPageWidth, set the image width and height
  useEffect(() => {
    if (maxContainerwidth) {
      if (element.width < maxContainerwidth) {
        setWidth(element.width);
        setHeight(element.height);
      } else {
        let newHeight = (element.height * maxContainerwidth) / element.width;
        setWidth(maxContainerwidth);
        setHeight(newHeight);
      }
    }
    return () => {};
  }, [element, maxContainerwidth]);

  // Set the image to a private s3 url
  const loadImageS3 = async (s3: string) => {
    try {
      let dataUrl = (await storage?.readFilefromStorage(s3)) as string;
      setUrl(dataUrl);
    } catch (error) {
      console.log("loadImageS3 - error", error);
    }
  };

  // run [],
  useEffect(() => {
    loadImageS3(element.s3!);

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {maxPageWidth ? (
        <div
          data-slate-type={IMAGE}
          style={{ marginTop: "2px", marginBottom: "2px" }}
        >
          <div contentEditable={false}>
            {hideImage ? (
              <Skeleton variant="rect" width={width} height={height} />
            ) : null}
            <img
              src={url}
              alt=""
              className={clsx(hideImage && classes.hide)}
              style={{ maxWidth: "100%" }}
              onLoad={() => setHideImage(false)}
              width={width}
              height={height}
            />
          </div>
          {children}
        </div>
      ) : null}
    </>
  );
};
