import { NotebookDB } from "@ebbin/common";

import { storage } from "@ebbin/storage";

import { AppThunk } from "../../../index";

import { Question } from "@ebbin/common";

import {
  DELETE_NOTEBOOK,
  getNotebooks,
  setLocalLastOpenNotebookId,
  deleteCollapsedIds,
  localDb,
} from "..";

/** Delete notebooks from the DB and update redux
 *  - Update Redux if the current notebook is deleted
 *  - Update Redux if you delete the last notebook
 *  - Delete all the pages part of the notebook
 */
export const deleteNotebook = (notebook: NotebookDB): AppThunk => async (
  dispatch,
  getState
) => {
  // deleteNotebook
  let notebookId = getState().noteTaking.notebookId;

  try {
    await localDb.remove(notebook);
  } catch (error) {
    console.log("deleteNotebook - ERROR01", error);
    return;
  }

  deleteCollapsedIds(notebook._id);

  let notebooks = await getNotebooks();
  if (notebooks && (notebook._id === notebookId || notebooks.length === 0)) {
    setLocalLastOpenNotebookId("");
    dispatch({
      type: DELETE_NOTEBOOK,
      data: {
        notebooks: notebooks,
        notebookId: "",
        pageId: "",
        treeData: null,
      },
    });
  } else {
    dispatch({
      type: DELETE_NOTEBOOK,
      data: { notebooks: notebooks },
    });
  }

  try {
    let treeSnapshot = await localDb.get(`ts_${notebookId}`);
    await localDb.remove(treeSnapshot);
  } catch (error) {
    console.log("deleteNotebook - treeSnapshot - ERROR03", error);
  }

  try {
    let treeActions = await localDb.allDocs({
      startkey: `ta_${notebook._id}`,
      endkey: `ta_${notebook._id}\ufff0`,
    });

    let treeActionRows = treeActions.rows;
    for (const treeActionRow of treeActionRows) {
      await localDb.remove(treeActionRow.id, treeActionRow.value.rev);
    }
  } catch (error) {
    console.log("deleteNotebook - treeActions - ERROR04", error);
  }

  try {
    let pages = await localDb.allDocs({
      startkey: `p_${notebook._id}`,
      endkey: `p_${notebook._id}\ufff0`,
    });
    let pageRows = pages.rows;
    for (const pageRow of pageRows) {
      await localDb.remove(pageRow.id, pageRow.value.rev);
    }
  } catch (error) {
    console.log("deleteNotebook - pages - ERROR05", error);
  }

  storage?.removeFolder(`${notebookId}/`)
  deleteNotebookAllQuestions(notebookId)
  return;
};

const deleteNotebookAllQuestions = async (notebookId: string) => {
  let questionsPrefix = `q_p_${notebookId}`;
  try {
    let dbQuestions = await localDb.allDocs<Question>({
      startkey: `${questionsPrefix}`,
      endkey: `${questionsPrefix}\uffff`,
    });
    dbQuestions.rows.forEach(async (question) => {
      await localDb.remove(question.id, question.value.rev);
    });
  } catch (error) {
    console.log("deleteNotebookAllQuestions - error", error);
  }
};

