import { Page } from "@ebbin/common";

import { localDb } from "..";

/** Delete page rev on database  */
export const deletePageRev = async (page: Page) => {
  try {
    await localDb.remove(page);
  } catch (error) {
    console.log("deletePageRev - ERROR:", error);
  }
};
