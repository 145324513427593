import React, { useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

import {
  DialogPage,
  confirmSignUp,
  useAuthenticationState,
  resendConfirmationCode,
} from ".";

import { EbbinLogo } from "@ebbin/components";

const useStyles = makeStyles((theme) => {
  return {
    button: {
      textTransform: "none",
      padding: theme.spacing(1),
    },
  };
});

export const ConfirmCodePage = () => {
  const classes = useStyles();
  const { setDialogPage, email } = useAuthenticationState();

  const [error, setError] = useState({ showError: false, message: "" });
  const [message, setMessage] = useState({ showMessage: false, message: "" });
  const [code, setCode] = useState("");

  const handleConfirmCode = async () => {
    let response = await confirmSignUp(email, code);
    console.log("response :>> ", response);
    if (response === "SUCCESS") {
      setDialogPage(DialogPage.LOG_IN);
    }
    if (response.code === "CodeMismatchException") {
      setError({ showError: true, message: response.message });
    }
  };

  const handleErrorClose = () => {
    setError({ showError: false, message: "" });
  };
  const handleMessageClose = () => {
    setMessage({ showMessage: false, message: "" });
  };

  const handleNewCode = async () => {
    let response = (await resendConfirmationCode(email)) as any;
    if (response && response.CodeDeliveryDetails) {
      setMessage({
        showMessage: true,
        message: `New Code sent to ${response.CodeDeliveryDetails.Destination}`,
      });
    }
  };

  return (
    <>
      <EbbinLogo
        style={{
          height: "100px",
          width: "100px",
          display: "block",
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: "40px",
        }}
      />
      <DialogTitle
        id="form-dialog-title"
        style={{ userSelect: "none", textAlign: "center" }}
      >
        Confirm Code
      </DialogTitle>
      <DialogContent style={{ width: "350px" }}>
        <form
          id="confirm-code-form"
          onSubmit={(event) => {
            event.preventDefault();
            handleConfirmCode();
          }}
        >
          <TextField
            autoFocus
            margin="dense"
            id="code"
            label="Code"
            type="text"
            fullWidth
            value={code}
            onChange={(event) => {
              setCode(event.target.value);
            }}
          />
        </form>
      </DialogContent>
      <DialogActions style={{ marginBottom: "50px", justifyContent: "center" }}>
        <Button
          style={{ width: "45%" }}
          variant="contained"
          onClick={() => {
            handleNewCode();
          }}
          color="primary"
        >
          New Code
        </Button>
        <Button
          form="confirm-code-form"
          type="submit"
          style={{ width: "45%" }}
          variant="contained"
          color="primary"
        >
          Confirm
        </Button>
      </DialogActions>
      <div>
        <Button
          className={classes.button}
          size="small"
          disableFocusRipple
          disableRipple
          onClick={() => {
            setDialogPage(DialogPage.LOG_IN);
          }}
          color="primary"
        >
          Log in
        </Button>
      </div>
      <Snackbar
        open={error.showError}
        autoHideDuration={6000}
        onClose={handleErrorClose}
      >
        <MuiAlert severity="error" elevation={6} variant="filled">
          {error.message}
        </MuiAlert>
      </Snackbar>
      <Snackbar
        open={message.showMessage}
        autoHideDuration={6000}
        onClose={handleMessageClose}
      >
        <MuiAlert severity="success" elevation={6} variant="filled">
          {message.message}
        </MuiAlert>
      </Snackbar>
    </>
  );
};
