import { useState, useRef, useEffect } from "react";
import { ResizeObserver } from "@juggle/resize-observer";

type UseMeasueType = [
  {
    ref: React.MutableRefObject<HTMLElement | null>;
  },
  {
    left: number;
    top: number;
    width: number;
    height: number;
  }
];

export const useMeasure = (): UseMeasueType => {
  const ref = useRef<HTMLElement | null>(null);
  const [bounds, setBounds] = useState({
    left: 0,
    top: 0,
    width: 0,
    height: 0,
  });

  const [resizeO] = useState(() => {
    return new ResizeObserver(([entry]) => setBounds(entry.contentRect));
  });

  useEffect(() => {
    if (ref.current) {
      resizeO.observe(ref.current);
    }
    return () => {
      return resizeO.disconnect();
    };
  }, [resizeO]);

  return [{ ref }, bounds];
};
