import { LocalLastOpenPageid } from "@ebbin/common";

import { localDb } from "..";

export const updateLastOpenPage = async (
  notebookId: string,
  pageId: string
) => {
  try {
    let doc = await localDb.get(`_local/${notebookId}/lastOpenPage`);
    await localDb.put({
      ...doc,
      pageId: pageId,
    });
  } catch (error) {
    console.log("updateLastOpenPage - error:", error);
  }
};

export const readLastOpenPage = async (notebookId: string) => {
  let pageId = "";
  try {
    let doc = await localDb.get<LocalLastOpenPageid>(
      `_local/${notebookId}/lastOpenPage`
    );
    pageId = doc.pageId;
  } catch (error) {
    if (error.name === "not_found") {
      await localDb.put({
        _id: `_local/${notebookId}/lastOpenPage`,
        pageId: "",
      });
    } else {
      console.log("readLastOpenPage - error:", error);
    }
  }
  return pageId;
};
