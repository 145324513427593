import PouchDB from "pouchdb";
import { Action } from "redux";
import { ThunkAction } from "redux-thunk";

import {
  NoteTakingStateType,
} from "@ebbin/note-taking-app";
import { SET_IS_LOADING, SET_DB_STATE } from "@ebbin/common";
export let syncDB: PouchDB.Replication.Sync<any>;

type StateType = {
  noteTaking: NoteTakingStateType;
};
type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  StateType,
  unknown,
  Action<string>
>;

let syncTimeout: NodeJS.Timeout | null;
let syncUpdateNotebook = false;
let syncUpdateTreeData = false;
let pageUpdated = false;
let pullpage: string[] = [];

/** Initalize DB and Synchronization
 *  - Inital full pull replication, after live sync (two-way, continuous, retriable sync)
 */
export const initializeSyncDB = (
  localDb: PouchDB.Database<{}>,
): AppThunk => async (dispatch, getState) => {
    dispatch({ type: SET_IS_LOADING, data: { isLoading: false } });
    dispatch({ type: SET_DB_STATE, data: { dbState: "ready" } });

};
