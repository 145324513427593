import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";

import { SET_IS_LOG_IN } from "@ebbin/components";

import { StateType } from "./rootReducer";

import { Ebbin } from "./Ebbin";

import { LoadingDialog } from "@ebbin/components";

import {
  pouchdb,
  initializePouchDb,
  EbbinApps,
  initializeSyncDB,
} from "@ebbin/pouchdb";
import { initializeStogare, StorageTypes } from "@ebbin/storage";

let localDb: PouchDB.Database<{}>;

export const AppSetup = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state: StateType) => state.app.isLoading);
  const dbState = useSelector((state: StateType) => state.app.dbState);
  const isLogIn = useSelector(
    (state: StateType) => state.authentication.isLogIn
  );

  useEffect(() => {
    if (isLogIn === true) {
      initializeStogare(StorageTypes.S3);
      (async function initializeDB() {
        await initializePouchDb(EbbinApps.WEB);
        try {
          let _localDb = await pouchdb?.newPouchDB();
          if (_localDb) localDb = _localDb;

          dispatch(initializeSyncDB(localDb));
        } catch (error) {
          console.log("initializeDB - error :>> ", error);
        }
      })();
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLogIn]);

  useEffect(() => {
      dispatch({ type: SET_IS_LOG_IN, data: { isLogIn: true } });

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <LoadingDialog open={isLoading} />
      {dbState === "ready" ? (
        <>
          <Ebbin localDb={localDb} />
        </>
      ) : null}
    </>
  );
};
