import { AppThunk } from "../../../index";

import { addItem } from "../../sideMenu/treeUtil";
import { UPDATE_TREEDATA, REMOVE_COLLAPSED_ID, localDb } from "../";

import { getId } from "@ebbin/common";


/** Add a new page and action to DB, update tree on state */
export const addPage = (parentId: string): AppThunk => async (
  dispatch,
  getState
) => {
  // addPage
  let notebookId = getState().noteTaking.notebookId;
  let treeData = getState().noteTaking.treeData;
  dispatch({ type: REMOVE_COLLAPSED_ID, data: { pageId: parentId } });

  let pageId = `p_${notebookId}_${getId()}`;
  let newTreeData = addItem(treeData!, parentId, pageId);

  let pageResponse: PouchDB.Core.Response | null;
  pageResponse = null;

  let treeActionResponse: PouchDB.Core.Response | null;
  treeActionResponse = null;
  try {
    let page = {
      _id: pageId,
      children: [
        {
          children: [{ text: "Untitled Page:", color: "#1e4e79" }],
          type: "h1",
        },
        { children: [{ text: "" }] },
      ],
      selection: {
        anchor: { path: [0, 0], offset: 0 },
        focus: { path: [0, 0], offset: 0 },
      },
      cd: new Date().toJSON(),
      md: new Date().toJSON(),
    };
    pageResponse = await localDb.put(page);

    let treeAction = {
      _id: `ta_${notebookId}_${getId()}`,
      action: "AddTreeNode",
      data: { parentId: parentId, pageId: pageId },
      cd: new Date().toJSON(),
    };
    treeActionResponse = await localDb.put(treeAction);

    return dispatch({
      type: UPDATE_TREEDATA,
      data: {
        treeData: newTreeData,
      },
    });
  } catch (error) {
    if (pageResponse) {
      localDb.remove(pageResponse.id, pageResponse.rev);
    }

    if (treeActionResponse) {
      localDb.remove(treeActionResponse.id, treeActionResponse.rev);
    }
    console.log("addPage - ERROR", error);
  }
};
