// Many of these functions were copied from library @atlaskit/tree
import {
  FlattenedItem,
  Path,
  TreeData,
  TreeItemData,
  ItemId,
  TreeItem,
  TreeSourcePosition,
} from "./types";

export const createTree = (): TreeData => {
  const rootItem = createItem("root");
  let root = {
    rootId: rootItem.id,
    items: {
      [rootItem.id]: rootItem,
    },
  };
  return root;
};

export const createItem = (id: ItemId): TreeItem => {
  return {
    id: `${id}`,
    children: [],
    hasChildren: false,
    isExpanded: false,
    isChildrenLoading: false,
    data: {
      title: `Untitled Page:`,
    },
  };
};

export const addItem = (
  tree: TreeData,
  parentId: ItemId,
  childId: ItemId
): TreeData => {
  let cloneTree: TreeData = JSON.parse(JSON.stringify(tree));
  cloneTree.items[`${parentId}`].children.push(childId);
  cloneTree.items[`${parentId}`].isExpanded = true;
  cloneTree.items[`${parentId}`].hasChildren = true;
  let item = createItem(childId);
  cloneTree.items[`${childId}`] = item;
  return cloneTree;
};

export const removeItem = (
  tree: TreeData,
  id: ItemId
): [TreeData, string[]] => {
  let flatTree = flattenTree(tree);
  let flatItem = flatTree.filter((FItem) => FItem.item.id === id);

  let position = getTreePosition(tree, flatItem[0].path);
  let newTree = removeItemFromTree(tree, position);
  let deletedItemIds = [];
  if (flatItem[0].item.children.length === 0) {
    delete newTree.tree.items[flatItem[0].item.id];
    deletedItemIds.push(flatItem[0].item.id);
  } else {
    let isChildren = (childPath: number[], parentPath: number[]) =>
      parentPath.every((n, index: number) => childPath[index] === n);

    let flatChildren = flatTree.filter((FItem) =>
      isChildren(FItem.path, flatItem[0].path)
    );
    for (let flatChild of flatChildren) {
      delete newTree.tree.items[flatChild.item.id];
      deletedItemIds.push(flatChild.item.id);
    }
  }
  return [newTree.tree, deletedItemIds as string[]];
};

export const getChildrenTreeItem = (tree: TreeData, id: ItemId): string[] => {
  let flatTree = flattenTree(tree);
  let flatItem = flatTree.filter((FItem) => FItem.item.id === id);

  let childrenItems = [];

  let isChildren = (childPath: number[], parentPath: number[]) =>
    parentPath.every((n, index: number) => childPath[index] === n);

  let flatChildren = flatTree.filter((FItem) =>
    isChildren(FItem.path, flatItem[0].path)
  );
  for (let flatChild of flatChildren) {
    childrenItems.push(flatChild.item.id);
  }
  return childrenItems as string[];
};


//#region - Path functions
/*
  Calculates the parent path for a path
*/
const getParentPath = (child: Path): Path => child.slice(0, child.length - 1);

const getIndexAmongSiblings = (path: Path): number => {
  const lastIndex = path[path.length - 1];
  return lastIndex;
};
//#endregion

//#region - Tree functions
type TreeItemMutation = {
  id?: ItemId;
  children?: ItemId[];
  hasChildren?: boolean;
  isExpanded?: boolean;
  isChildrenLoading?: boolean;
  data?: TreeItemData;
};

/*
  Transforms tree structure into flat list of items for rendering purposes.
  We recursively go through all the elements and its children first on each level
 */
const flattenTree = (tree: TreeData, path: Path = []): FlattenedItem[] =>
  tree.items[tree.rootId]
    ? tree.items[tree.rootId].children.reduce<FlattenedItem[]>(
        (accum, itemId, index) => {
          // iterating through all the children on the given level
          const item = tree.items[itemId];
          const currentPath = [...path, index];
          // we create a flattened item for the current item
          const currentItem = createFlattenedItem(item, currentPath);
          // we flatten its children
          const children = flattenChildren(tree, item, currentPath);
          // append to the accumulator
          return [...accum, currentItem, ...children];
        },
        []
      )
    : [];

/*
  Constructs a new FlattenedItem
 */
const createFlattenedItem = (
  item: TreeItem,
  currentPath: Path
): FlattenedItem => {
  return {
    item,
    path: currentPath,
  };
};

/*
  Flatten the children of the given subtree
*/
const flattenChildren = (tree: TreeData, item: TreeItem, currentPath: Path) => {
  return item.isExpanded
    ? flattenTree({ rootId: item.id, items: tree.items }, currentPath)
    : [];
};

/*
  Changes the tree data structure with minimal reference changes.
 */
export const mutateTree = (
  tree: TreeData,
  itemId: ItemId,
  mutation: TreeItemMutation
): TreeData => {
  const itemToChange = tree.items[itemId];
  if (!itemToChange) {
    // Item not found
    return tree;
  }
  // Returning a clone of the tree structure and overwriting the field coming in mutation
  return {
    // rootId should not change
    rootId: tree.rootId,
    items: {
      // copy all old items
      ...tree.items,
      // overwriting only the item being changed
      [itemId]: {
        ...itemToChange,
        ...mutation,
      },
    },
  };
};

const getItem = (tree: TreeData, path: Path): TreeItem => {
  let cursor: TreeItem = tree.items[tree.rootId];

  for (const i of path) {
    cursor = tree.items[cursor.children[i]];
  }

  return cursor;
};

const getParent = (tree: TreeData, path: Path): TreeItem => {
  const parentPath: Path = getParentPath(path);
  return getItem(tree, parentPath);
};

const getTreePosition = (tree: TreeData, path: Path): TreeSourcePosition => {
  const parent: TreeItem = getParent(tree, path);
  const index: number = getIndexAmongSiblings(path);
  return {
    parentId: parent.id,
    index,
  };
};

const removeItemFromTree = (
  tree: TreeData,
  position: TreeSourcePosition
): { tree: TreeData; itemRemoved: ItemId } => {
  const sourceParent = tree.items[position.parentId];
  const newSourceChildren = [...sourceParent.children];
  const itemRemoved = newSourceChildren.splice(position.index, 1)[0];
  const newTree = mutateTree(tree, position.parentId, {
    children: newSourceChildren,
    hasChildren: newSourceChildren.length > 0,
    isExpanded: newSourceChildren.length > 0 && sourceParent.isExpanded,
  });

  return {
    tree: newTree,
    itemRemoved,
  };
};

//#endregion
