import React, { useState } from "react";

import { useDispatch } from "react-redux";
import { SET_IS_LOADING } from "@ebbin/common";

import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

import { EbbinLogo } from "@ebbin/components";
import { useAuthenticationState, DialogPage, signIn, SET_IS_LOG_IN } from ".";

const useStyles = makeStyles((theme) => {
  return {
    button: {
      textTransform: "none",
      padding: theme.spacing(1),
    },
  };
});

export const LoginPage = () => {
  const { setDialogPage, email, setEmail } = useAuthenticationState();
  const dispatch = useDispatch();

  const classes = useStyles();

  const [error, setError] = useState({ showError: false, message: "" });
  const [password, setPassword] = useState("");

  const handleErrorClose = () => {
    setError({ showError: false, message: "" });
  };

  const handleLogIn = async () => {
    let response = await signIn(email, password);
    if (response.code === "UserNotConfirmedException") {
      setDialogPage(DialogPage.CONFIRM_CODE);
    }
    if (response.code === "NotAuthorizedException") {
      setError({ showError: true, message: response.message });
    }
    if (response.code === "UserNotFoundException") {
      setError({ showError: true, message: "Incorrect username or password." });
    }
    if (response && response.username) {
      dispatch({ type: SET_IS_LOG_IN, data: { isLogIn: true } });
      dispatch({ type: SET_IS_LOADING, data: { isLoading: true } });
    }
  };

  return (
    <>
      <EbbinLogo
        style={{
          height: "100px",
          width: "100px",
          display: "block",
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: "40px",
        }}
      />
      <DialogTitle
        id="form-dialog-title"
        style={{ userSelect: "none", textAlign: "center" }}
      >
        Welcome to ebbin
      </DialogTitle>
      <DialogContent style={{ maxWidth: "350px" }}>
        <form
          id="log-in-form"
          onSubmit={(event) => {
            event.preventDefault();
            handleLogIn();
          }}
        >
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Email Address"
            type="email"
            required
            fullWidth
            value={email}
            onChange={(event) => {
              setEmail(event.target.value);
            }}
          />
          <TextField
            margin="dense"
            id="name"
            label="Password"
            type="password"
            fullWidth
            required
            value={password}
            onChange={(event) => {
              setPassword(event.target.value);
            }}
          />
        </form>
      </DialogContent>

      <DialogActions>
        <Button
          form="log-in-form"
          type="submit"
          style={{ width: "100%" }}
          variant="contained"
          color="primary"
        >
          Log in
        </Button>
      </DialogActions>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "20px",
        }}
      >
        <div>
          <Button
            className={classes.button}
            size="small"
            disableFocusRipple
            disableRipple
            onClick={() => {
              console.log("create account");
              setDialogPage(DialogPage.CREATE_AN_ACCOUNT);
            }}
            color="primary"
          >
            Create an account
          </Button>
        </div>
      </div>
      <Snackbar
        open={error.showError}
        autoHideDuration={6000}
        onClose={handleErrorClose}
      >
        <MuiAlert severity="error" elevation={6} variant="filled">
          {error.message}
        </MuiAlert>
      </Snackbar>
    </>
  );
};
