import React from "react";

import { useDispatch } from "react-redux";
import { addPage } from "../actions";

import { makeStyles } from "@material-ui/core/styles";

import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

import Scrollbar from "react-scrollbars-custom";

import { PageTree } from "./PageTree";

import { PlusIcon } from "@ebbin/components";

const useStyles = makeStyles((theme) => {
  return {
    trackX: {
      background: "#fafafa !important",
    },
    scrollbar: {
      width: "100%",
    },
    contentBox: {
      [theme.breakpoints.up("sm")]: {},
      [theme.breakpoints.only("xs")]: {},
    },
    Notebookicon: {
      width: "30px",
      height: "30px",
    },
    icons: {
      width: "25px",
      height: "25px",
    },
  };
});

/**
 * Comment
 */
export const PageSideMenu = () => {
  const classes = useStyles();

  const dispatch = useDispatch();

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="flex-start"
        flex="auto"
        style={{
          overflowY: "auto",
          minHeight: "0px",
        }}
      >
        {/* This scroll cause an error in react bdnd about no nested scrolls */}
        <Scrollbar
          mobileNative={true}
          noScrollX={true}
          removeTracksWhenNotUsed={true}
          trackXProps={{ className: `${classes.trackX}` }}
          className={classes.scrollbar}
        >
          <PageTree />
        </Scrollbar>
      </Box>

      <Divider />
      <ListItem
        button
        onClick={(e) => {
          e.preventDefault();
          dispatch(addPage("root"));
        }}
      >
        <ListItemIcon>
          <PlusIcon alt="" className={classes.icons} />
        </ListItemIcon>
        <ListItemText primary="Add Page" />
      </ListItem>
    </>
  );
};
