import { jsx } from "slate-hyperscript";
import {
  MARK_ITALIC,
  MARK_BOLD,
  MARK_UNDERLINE,
  ListType,
  ELEMENT_TAGS,
  IMAGE,
} from "..";

/** Calculate the value of the parent, this can be use to filter some
 * nodes but allow to process their children, like PRE-> CODE tags
 */
export const parentCalculator = (el: any) => {
  let parent = el;
  if (
    el.nodeName === "PRE" &&
    el.childNodes[0] &&
    el.childNodes[0].nodeName === "CODE"
  ) {
    parent = el.childNodes[0];
  }
  return parent;
};

/** Calculate the value of the list parameters */
export const listCalculator = (
  el: any,
  indent: number,
  listType?: string
): [string | undefined, number] => {
  let ebbinType = el.getAttribute("data-ebbin-type");

  if (ebbinType === "list-ul") {
    indent = Number(el.getAttribute("data-ebbin-indent"));
    listType = ListType.UL_LIST;
    return [listType, indent];
  }

  if (ebbinType === "list-ol") {
    indent = Number(el.getAttribute("data-ebbin-indent"));
    listType = ListType.OL_LIST;
    return [listType, indent];
  }

  if (ebbinType === "list-div") {
    indent = Number(el.getAttribute("data-ebbin-indent")) || 0;
    listType = ListType.OL_LIST;
    return [listType, indent];
  }

  if (el.nodeName === "UL") {
    indent = indent + 1;
    listType = ListType.UL_LIST;
  }

  if (el.nodeName === "OL") {
    indent = indent + 1;
    listType = ListType.OL_LIST;
  }
  return [listType, indent];
};

/** Check that a Inline Node is valid
 *  - Check A tag has content.
 */
export const isInvalidInlineNode = (el: any) => {
  if (el.nodeName === "A" && el.childNodes.length === 0) {
    return true;
  }
  return false;
};

export const textPropsGenerator = (el: any, textProps = {}) => {
  if (el.nodeName === "SPAN") {
    if (
      el?.style?.color &&
      el?.style?.color !== "rgb(0, 0, 0)" &&
      el?.style?.color !== "black" &&
      el?.style?.color !== "rgb(34, 34, 34)"
    ) {
      textProps = { ...textProps, color: el.style.color };
    }
    if (el?.style.fontStyle === MARK_ITALIC) {
      textProps = { ...textProps, italic: true };
    }
    if (el?.style.fontWeight === MARK_BOLD) {
      textProps = { ...textProps, bold: true };
    }
    if (
      el?.style.backgroundColor &&
      el?.style.backgroundColor !== "rgb(255, 255, 255)"
    ) {
      textProps = { ...textProps, mark: el?.style.backgroundColor };
    }
    if (el?.style.textDecoration === MARK_UNDERLINE) {
      textProps = { ...textProps, underline: true };
    }
  }
  if (el.nodeName === "I") {
    textProps = { ...textProps, italic: true };
  }
  if (el.nodeName === "CODE") {
    textProps = { ...textProps, code: true };
  }
  if (el.nodeName === "DEL") {
    textProps = { ...textProps, strikethrough: true };
  }
  if (el.nodeName === "EM") {
    textProps = { ...textProps, italic: true };
  }
  if (el.nodeName === "S") {
    textProps = { ...textProps, strikethrough: true };
  }
  if (el.nodeName === "STRONG") {
    textProps = { ...textProps, bold: true };
  }
  if (el.nodeName === "U") {
    textProps = { ...textProps, underline: true };
  }
  return textProps;
};

/**
 * Create Editor Link Node from "A" tag, if the children have block and inline
 * elements keep the valid inline elements in the Link and split the block elements, in new nodes.
 * @param children
 * @param listType
 * @param indent
 */
export const linkNodeGenerator = (el: any, children: any[]) => {
  let inlineNodes = [];
  let blockNodes = [];
  for (const child of children) {
    if (typeof child === "string" || child.text) {
      inlineNodes.push(child);
    } else {
      blockNodes.push(child);
    }
  }
  const attrs = ELEMENT_TAGS[el.nodeName](el);
  if (inlineNodes.length !== 0) {
    return [jsx("element", attrs, inlineNodes), ...blockNodes];
  } else {
    return blockNodes;
  }
};

/**
 * Create Editor List Node from "LI" tag, if the children have block and inline
 * elements keep the valid inline elements in the list and split the block elements, in new nodes.
 * @param children
 * @param listType
 * @param indent
 */
export const listNodeGenerator = (
  children: any,
  listType: string,
  indent: number
) => {
  let inlineNodes = [];
  let blockNodes = [];
  for (const child of children) {
    if (typeof child === "string" || child.text || child.type === "a") {
      inlineNodes.push(child);
    } else {
      blockNodes.push(child);
    }
  }
  const attrs = { list: listType, indent: indent };

  if (inlineNodes.length !== 0) {
    return [jsx("element", attrs, inlineNodes), ...blockNodes];
  } else {
    // if the only element is a image keep it on the list Node
    if (blockNodes.length === 1 && blockNodes[0].type === IMAGE) {
      blockNodes[0].list = listType;
      blockNodes[0].indent = indent;
      return blockNodes;
    }
    return blockNodes;
  }
};

/** Generate Node Elements */
export const genericNodeGenerator = (el: any, children: any[]) => {
  if (children.length === 0) {
    children = [{ text: "" }];
  }
  const attrs = ELEMENT_TAGS[el.nodeName](el);
  return jsx("element", attrs, children);
};
