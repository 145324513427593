import React, { useEffect, useState } from "react";

import { useSelector, useDispatch } from "react-redux";
import { StateType } from "../reducer";
import {
  moveNodeAction,
  ADD_COLLAPSED_ID,
  REMOVE_COLLAPSED_ID,
  OPEN_PAGE,
} from "../actions";

import Tree, {
  mutateTree,
  moveItemOnTree,
  RenderItemParams,
  TreeData,
  ItemId,
  TreeSourcePosition,
  TreeDestinationPosition,
} from "@atlaskit/tree";

import { useSideMenuState } from "./SideMenuState";
import { PageTreeItem } from "./PageTreeItem";
import { getChildrenTreeItem } from "./treeUtil";

export const PageTree = () => {
  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();
  const notebookId = useSelector(
    (state: StateType) => state.noteTaking.notebookId
  );
  const treeData = useSelector((state: StateType) => state.noteTaking.treeData);

  const { tree, setTree, selected, setSelected } = useSideMenuState();

  useEffect(() => {
    if (treeData) {
      setTree(treeData);
    }

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [treeData]);

  const onExpand = (itemId: ItemId) => {
    dispatch({ type: REMOVE_COLLAPSED_ID, data: { pageId: itemId } });
    setTree((tree) => mutateTree(tree, itemId, { isExpanded: true }));
  };

  const onCollapse = (itemId: ItemId) => {
    dispatch({ type: ADD_COLLAPSED_ID, data: { pageId: itemId } });
    setTree((tree) => mutateTree(tree, itemId, { isExpanded: false }));

    // if the selected item is children of the collapse item, change
    // the pageId and selected
    let children = getChildrenTreeItem(tree, itemId);
    if (children.includes(selected)) {
      dispatch({ type: OPEN_PAGE, data: { pageId: itemId } });
      setSelected(itemId as string);
    }
  };

  const onDragEnd = (
    source: TreeSourcePosition,
    destination?: TreeDestinationPosition
  ) => {
    if (!destination) {
      return;
    }
    const newTree = moveItemOnTree(tree as TreeData, source, destination);

    let sourceid = tree.items[source.parentId].children[source.index];
    
    setTree(newTree);
    moveNodeAction(notebookId, source, destination, sourceid);
  };

  useEffect(() => {
    setLoading(false);
  }, []);

  const renderItem = ({
    item,
    onExpand,
    onCollapse,
    provided,
  }: RenderItemParams) => {
    return (
      <div
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
      >
        <PageTreeItem item={item} onExpand={onExpand} onCollapse={onCollapse} />
      </div>
    );
  };

  return (
    <div style={{ visibility: loading ? "hidden" : "visible" }}>
      <Tree
        tree={tree}
        renderItem={renderItem}
        onExpand={onExpand}
        onCollapse={onCollapse}
        onDragEnd={onDragEnd}
        isDragEnabled={true}
        isNestingEnabled
        offsetPerLevel={10}
      />
    </div>
  );
};
